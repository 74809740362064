import React, { useEffect, useState } from 'react';
import { GoogleButton } from 'react-google-button';
import { UserAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';


const Signin = () => {
  const { googleSignIn, emailSignIn, emailSignUp, facebookSignIn, user, logOut } = UserAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

 /*  const handleGoogleSignIn = async () => {
    try {
      await googleSignIn();
      navigate('/meets'); 
    } catch (error) {
      ////console.log(error);
    }
  }; */

  const handleEmailSignIn = async (e) => {
    e.preventDefault();
    try {
      await emailSignIn(email, password);
      ////console.log("Signin: Trying to navigate to meets")
      // Request fullscreen when user is authenticated
       if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } 
      navigate('/meets'); // Navigate to /meets after successful sign-in
    } catch (error) {
      ////console.log("Signin, email: "+error);
    }
  };

  const handleEmailSignUp = async (e) => {
    e.preventDefault();
    try {
      await emailSignUp(email, password);
      navigate('/meets'); 
    } catch (error) {
      ////console.log(error);
    }
  };

  /* const handleFacebookSignIn = async () => {
    try {
      await facebookSignIn();
      navigate('/meets'); 
    } catch (error) {
      ////console.log(error);
    }
  }; */

  const handleSignOut = async () => {
    try {
      await logOut();
    } catch (error) {
      ////console.log(error);
    }
  };

/*    useEffect(() => {
    
    if (user != null) {
      navigate('/meets');
    }
  }, [user, navigate]); */


  return (
    


<article>
      <h1 style={{ textAlign: 'center', fontSize: '3rem', fontWeight: 'bold', padding: '2rem 0' }}>
        Sign in
      </h1>
     {/*  <div style={{ maxWidth: '240px', margin: '0 auto', padding: '1rem' }}>
        <GoogleButton onClick={handleGoogleSignIn} />
      </div> */}
      <div style={{ maxWidth: '260px', margin: '0 auto', padding: '1rem' }}>
        <form onSubmit={handleEmailSignIn}>
          <input
            type='email'
            placeholder='Email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type='password'
            placeholder='Password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button type='submit'>Sign In</button>
        </form>
      </div>
      <div style={{ maxWidth: '260px', margin: '0 auto', padding: '1rem' }}>
        <form onSubmit={handleEmailSignUp}>
          <input
            type='email'
            placeholder='Email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type='password'
            placeholder='Password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button type='submit'>Sign Up</button>
          <button onClick={handleSignOut} >Kirjaudu ulos</button>
        </form>
      </div>
     {/*  <div style={{ maxWidth: '240px', margin: '0 auto', padding: '1rem' }}>
        <button onClick={handleFacebookSignIn}>Facebook Login</button>
      </div> */}
   </article>
   
  );
};

export default Signin;

