import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconChevronLeft } from '@tabler/icons-react';
import { UserAuth } from '../context/AuthContext';
import slipperLogo from '../assets/SlipperIcon.png';
import { db } from "../config/firebase";
import {
  getDoc,
  updateDoc,
  doc,
} from "firebase/firestore";

import { requestNotificationPermission, saveNotification } from '../context/NotificationService';

const HideNotifications = () => {
  const navigate = useNavigate();
  const { user } = UserAuth();
  const [notificationsOn, setNotificationsOn] = useState(false);

  const goBack = () => {
    navigate('/account');
  };

/*   const toggleNotifications = async () => {
    try {
      const userRef = doc(db, "users", user.uid);
      await updateDoc(userRef, { notificationsOn: !notificationsOn });

      // Update local state
      setNotificationsOn(!notificationsOn);

      // Request notification permission if notifications are turned on
      if (notificationsOn) {
        await requestNotificationPermission();
      }
    } catch (err) {
      console.error('Error toggling notifications:', err);
    }
  }; */

  const toggleNotificationsOn = async () => {
    try {
      const userRef = doc(db, "users", user.uid);
  
      // Fetch the user document
      const userDocSnap = await getDoc(userRef);
  
      // Check if the document exists
      if (userDocSnap.exists()) {
        const userDoc = userDocSnap.data();
  
        // Update the document with notificationsOn: true
        await updateDoc(userRef, { notificationsOn: true });
  
        // Update local state
        setNotificationsOn(true);
  
        // Request notification permission
        const permission = requestNotificationPermission();
        if (permission === "granted") {
          saveNotification(userDoc);
        }
      } else {
        console.log("User document does not exist.");
      }
    } catch (err) {
      console.error("Error turning notifications on:", err);
    }
  };
  
  
  const toggleNotificationsOff = async () => {
    try {
      const userRef = doc(db, "users", user.uid);
      await updateDoc(userRef, { notificationsOn: false });
  
      // Update local state
      setNotificationsOn(false);

      if (Notification.permission === "granted") {
        Notification.revokePermission();
      }
    } catch (err) {
      console.error('Error turning notifications off:', err);
    }
  };
  

  useEffect(() => {
    const checkNotificationsSetting = async () => {
      if (user) {
        const userRef = doc(db, "users", user.uid);
        const userDocSnap = await getDoc(userRef);

        if (userDocSnap.exists) {
          setNotificationsOn(userDocSnap.data().notificationsOn || false); // Set default if missing
        }
      } else {
        // User is not signed in
        setNotificationsOn(false); // Reset state if user signs out
      }
    };

    // Call initially with current user
    checkNotificationsSetting();

  }, []);

  return (
    <div className="page-container">
      <nav style={{ display: 'flex', paddingRight: '10px' }}>
        <ul>
          <li>
            <a href="#" onClick={goBack}>
              <IconChevronLeft size={35} />
            </a>
          </li>
          <li>
            <strong>Sähköposti-ilmoitukset</strong>
          </li>
        </ul>
      </nav>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img src={slipperLogo} alt="Slipper Logo" style={{ height: '100px', width: 'auto' }} />
        <br />
        {notificationsOn ? (
          <div>
            <strong style={{ color: 'gold' }}>Ilmoitukset ovat päällä</strong>
            <br /> <br />
          </div>
        ) : (
          <div></div>
        )}
      <p>
  Saat antamaasi sähköpostiosoitteeseen ilmoitukset:
</p>
<ul>
  <li>1. Uusista viesteistä (vain kerran 12 tunnissa)</li>
  <li>2. Suosikkiesi uusista tapaamisista</li>
  <li>3. Kun sinut lisätään suosikiksi</li>
  <li>4. Uusista ilmoittautumisista sinun deitteihisi</li>
</ul>



        {notificationsOn ? (
          <div>
            <button onClick={toggleNotificationsOff}>Haluan ilmoitukset pois</button>
          </div>
        ) : (
          <button onClick={toggleNotificationsOn}>Haluan ilmoitukset päälle</button>
        )}
      </div>


    </div>
  );
};

export default HideNotifications;
