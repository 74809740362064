import React, { useEffect } from 'react';
import { UserAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { IconChevronLeft } from '@tabler/icons-react';
import slipperLogo from '../assets/SlipperIcon.png';

const AddToHomeScreen = () => {
  const navigate = useNavigate();
  let myInstallPromptEvent; // Store the "beforeinstallprompt" event.

  const goBack = () => {
    navigate('/account');
  };

  const handleInstallPrompt = () => {
    if (myInstallPromptEvent) {
      // Show the installation prompt to the user.
      myInstallPromptEvent.prompt();
      // Wait for the user's choice (accepted or dismissed).
      myInstallPromptEvent.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          //////console.log('User accepted the install prompt.');
        } else {
          //////console.log('User dismissed the install prompt.');
        }
        // Reset the event to null.
        myInstallPromptEvent = null;
      });
    }
  };

  useEffect(() => {
    const beforeInstallPromptHandler = (event) => {
      event.preventDefault();
      myInstallPromptEvent = event;
    };

    if ('beforeinstallprompt' in window) {
      window.addEventListener('beforeinstallprompt', beforeInstallPromptHandler);

      return () => {
        window.removeEventListener('beforeinstallprompt', beforeInstallPromptHandler);
      };
    }
  }, []);

  return (
    <div className="page-container">
      <nav style={{ display: 'flex', paddingRight: '10px' }}>
        <ul>
          <li>
            <a href="#" onClick={goBack}>
              <IconChevronLeft size={35} />
            </a>
          </li>
        </ul>
      </nav>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img src={slipperLogo} alt="Slipper Logo" style={{ height: '200px' }} />
        <br />
        <p>Pääset Slipperiin helposti ja nopeasti lisäämällä sen aloitusruudullesi</p>
        <button onClick={handleInstallPrompt}>Lisää</button>
      </div>
    </div>
  );
};

export default AddToHomeScreen;
