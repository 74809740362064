import React from 'react';
import { UserAuth } from '../context/AuthContext';
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { IconChevronLeft } from '@tabler/icons-react';
import UserPreview from '../components/UserPreview'

const ProfileCard = () => {
  //const { user } = UserAuth();
  const navigate = useNavigate();
  const { uid } = useParams();
  const location = useLocation();

   /*  Käytä tätä näyttääksesi miittikuvat tai kaikki userin kuvat, const listRef = ref(storage, `user_profile_images/${userId}/thumbs`);
      // List all the items in the user's folder
      const listResult = await listAll(listRef); 

      if (listResult.items.length > 0) {
        // Get the first image reference
        const firstImageRef = listResult.items[0];

        // Get the download URL of the first image
        const imageUrl = await getDownloadURL(firstImageRef);
        ////console.log("fetchUser image found this pic url: "+imageUrl)
        // Set the image URL in the state
        setStoragePicURL(imageUrl);
      } else {
        // Set a default image URL or handle the case when no images are available
        ////console.log("fetchUser image was not found so use placeholder instead")
        setProfilePic(null);
      }*/
      const goBack = () => {
        //navigate("/account");
   /*      const fromPage = location?.state?.fromPage || "/fallbackpage";
        navigate(fromPage); */
        //window.history.back();
        window.history.back(); 
      };

     

  return (
    <div className="page-container">
      <nav style={{ display: 'flex', paddingRight: '10px' }}>
                            <ul>
                                <li>  <a
                                    href="#"
                                    onClick={goBack}
                                >
                                    <IconChevronLeft size={35} />
                                </a></li>
                              
                            </ul>
                          
                        </nav>
           <UserPreview uid={uid} />
    </div>
  );
};

export default ProfileCard;
