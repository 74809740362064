import React, { useState, useEffect, useRef } from 'react';
import { IconBell } from '@tabler/icons-react';
import { UserAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import slipperImage from '../assets/SlipperTrans512.png';

import 'react-toastify/dist/ReactToastify.css';


  const LogoBar = () => {

  const { user } = UserAuth();
  const [unreadCount, setUnreadCount] = useState(0); // State to hold unread actions count
  const navigate = useNavigate();
  const [lastFetchTime, setLastFetchTime] = useState(0); // State to hold the last fetch timestamp

  const intervalIdRef = useRef(null);
  const lastFetchTimeRef = useRef(Date.now()); // Track the last fetch time persistently
 
  const handleNotificationClick = () => {
    navigate("/actionslog");
  };

  /* const fetchUnreadCount = async () => {
    try {
      console.log("Fetch unread actions")
      const environment = process.env.REACT_APP_ENVIRONMENT; // Get the environment variable
      const baseUrl = environment === 'demo'
        ? 'https://checkunreadactions-z7efewefha-uc.a.run.app'
        : 'https://checkunreadactions-wilwflbwma-uc.a.run.app';

      const response = await fetch(`${baseUrl}?userId=${user.uid}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      
      const data = await response.json();
      setUnreadCount(data.unreadCount);
      setLastFetchTime(Date.now()); // Update last fetch time
    } catch (error) {
      console.error('Error fetching unread count:', error);
    }
  }; */

  const fetchUnreadCount = async () => {
    const now = Date.now();
    const fifteenMinutes = 15 * 60 * 1000; // 15 minutes in milliseconds

    // Check if 15 minutes have passed since the last fetch
    if (now - lastFetchTimeRef.current < fifteenMinutes) {
      //console.log("Skipped fetchUnreadCount, too soon");
      return; // Exit if it's too soon to fetch
    }

    try {
      console.log("Fetching unread actions");
      const environment = process.env.REACT_APP_ENVIRONMENT; // Get the environment variable
      const baseUrl = environment === 'demo'
        ? 'https://checkunreadactions-z7efewefha-uc.a.run.app'
        : 'https://checkunreadactions-wilwflbwma-uc.a.run.app';

      const response = await fetch(`${baseUrl}?userId=${user.uid}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setUnreadCount(data.unreadCount);
      lastFetchTimeRef.current = now; // Update the ref after fetching
    } catch (error) {
      console.error('Error fetching unread count:', error);
    }
  };

  useEffect(() => {
    // Set interval to check every minute only once when component is first mounted
    if (!intervalIdRef.current) {
      intervalIdRef.current = setInterval(() => {
        if (user) {
          fetchUnreadCount(); // Fetch unread count if user is logged in
        }
      }, 1000 * 60); // Check every minute
    }

    // Initial fetch on component mount
    if (user) {
      fetchUnreadCount();
    }

    return () => {
      // Cleanup interval on component unmount
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
        intervalIdRef.current = null;
      }
    };
  }, [user]); // Only re-run if the `user` object changes
  


  return (
    <nav className="context-navbar">
      
      <ul>
        <li>

          <img src={slipperImage} alt="Logo" style={{ height: '40px' }} />


        </li>
      </ul>
        

      <ul>
      <li onClick={handleNotificationClick}>
          <IconBell style={{ color: unreadCount > 0 ? '#FF3131' : 'inherit', animation: unreadCount > 0 ? 'pulse-animation 1s infinite' : 'none' }} />
          {unreadCount > 0 && <span className="badge">{unreadCount}</span>} {/* Display unread count */}
        </li>
</ul>
    
    </nav>
  );
}

export default LogoBar;

