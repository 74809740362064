import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IconChevronLeft } from '@tabler/icons-react';
import slipperLogo from '../assets/SlipperIcon.png';

const Policy = () => {
  /* const navigate = useNavigate();

  const goBack = () => {
    navigate('/account');
    
  }; */

  return (
    <div className="page-container">
       {/*  <nav style={{ display: 'flex', paddingRight: '10px' }}>
        <ul>
          <li>
            <a href="#" onClick={goBack}>
              <IconChevronLeft size={35} />
            </a>
          </li>
        </ul>
      </nav> */}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
      <img src={slipperLogo} alt="Slipper Logo" style={{ height: '200px' , width: 'auto'}} />
      
      </div>
      
      <h4>Tietosuojakäytäntö</h4>

      <details>
  <summary>Keitä olemme</summary>
  <ul>
    <li>Sovellusta pyörittää toistaiseksi yksityishenkilö (Marko Savolainen) ja Slipper on henkilökohtainen harrasteprojektini. Slipper ei ole yritys eikä sitä pyöritetä liiketoiminnallisessa tarkoituksessa.</li>
    <li>Projektin päämääränä on tarjota viihtyisä deittiympäristö harrastepohjalta toistaiseksi ilman liiketoiminnallisia tavoitteita.</li>
  </ul>
</details>

<details>
  <summary>Ketä tämä tietosuojakäytäntö koskee</summary>
  <ul>
    <li>Tämä käytäntö koskee kaikkia deittisovelluksemme käyttäjiä.</li>
  </ul>
</details>

<details>
  <summary>Keräämämme tiedot</summary>
  <ul>
    <li>Keräämme käyttäjien perustiedot, kuten nimi, ikä, sijainti ja profiilikuvat.</li>
    <li>Tallennamme käyttäjien asetukset ja mieltymykset.</li>
    <li>Seuraamme vain tarvittaessa ja sovelluksen toimivuuden kannalta välttämättömissä tilanteissa käyttäjien vuorovaikutusta sovelluksessa, kuten viestit ja tykkäykset.</li>
    <li>Keräämme evästeiden avulla tietoja käyttäjien selauskäyttäytymisestä.</li>
    <li>Tarkistamme laitteen tiedot parantaaksemme sovelluksen suorituskykyä ja turvallisuutta.</li>
  </ul>
</details>

<details>
  <summary>Miten käytämme tietoja</summary>
  <ul>
    <li>Käytämme tietoja parantaaksemme sovelluksen käyttökokemusta ja tarjotaksemme räätälöityjä palveluita.</li>
    <li>Analysoidaksemme käyttäjien käyttäytymistä ja kehittääksemme palveluitamme.</li>
    <li>Turvallisuuden varmistamiseksi ja väärinkäytösten estämiseksi.</li>
    <li>Kohdennetun mainonnan tarjoamiseksi käyttäjille.</li>
    <li>Parantaaksemme sovelluksen turvallisuutta ja suorituskykyä.</li>
  </ul>
</details>

<details>
  <summary>Miten jaamme tietoja</summary>
  <ul>
    <li>Jaamme tietoja kolmansien osapuolten kanssa vain käyttäjän suostumuksella.</li>
    <li>Turvallisuussyistä voimme jakaa tietoja lainvalvontaviranomaisten kanssa.</li>
    <li>Käytämme kolmansien osapuolten palveluntarjoajia tietojen käsittelyyn ja tallentamiseen.</li>
    <li>Jaamme koottuja tilastotietoja, mutta yksittäisten käyttäjien henkilökohtaisia tietoja ei jaeta ilman suostumusta.</li>
    <li>Emme myy käyttäjien henkilötietoja.</li>
  </ul>
</details>

<details>
  <summary>Rajat ylittävät tiedonsiirrot</summary>
  <ul>
    <li>Tietojasi saatetaan siirtää ja tallentaa palvelimille, jotka sijaitsevat eri maissa.</li>
    <li>Näissä tapauksissa varmistamme asianmukaiset suojatoimet tietojesi turvaamiseksi.</li>
  </ul>
</details>

<details id="delete-user-section">
  <summary>Omat oikeudet ja valinnat</summary>
  <ul>
    <li>Käyttäjillä on oikeus pyytää tietojensa poistamista sovelluksesta.</li>
    <li>Voit itse poistaa tietosi milloin vain sovelluksen tilinäkymässä.</li>
    <li>Käyttäjillä on oikeus saada kopio omista henkilötiedoistaan.</li>
    <li>Voit peruuttaa suostumuksesi tietojesi käsittelyyn milloin tahansa.</li>
    <li>Jos haluat käyttää oikeuksiasi, ota yhteyttä: slippertuki@gmail.com</li>
  </ul>
</details>

<details>
  <summary>Miten kauan säilytämme tietojasi</summary>
  <ul>
    <li>Säilytämme tietoja niin kauan kuin ne ovat tarpeellisia sovelluksen toiminnan kannalta.</li>
    <li>Poistamme käyttäjän tiedot, kun käyttäjä poistaa tilinsä tai pyytää tietojensa poistamista.</li>
    <li>Säilytämme anonyymeja tilastotietoja sovelluksen kehittämistä varten.</li>
  </ul>
</details>


<details>
  <summary>Tietosuojakäytännön muutokset</summary>
  <ul>
    <li>Ilmoitamme käyttäjille olennaisista muutoksista tietosuojakäytäntöön.</li>
    <li>Käyttäjät voivat tarkistaa päivitetyn käytännön sovelluksen verkkosivuilta.</li>
    <li>Muutokset astuvat voimaan päivityksen jälkeen, ja käyttäjät voivat jatkaa sovelluksen käyttöä vain hyväksyttyään muutokset.</li>
  </ul>
</details>

<br/><br/>
<details>
  <summary>Yhteydenotot</summary>
  
  <ul>
    <li>slippertuki@gmail.com</li>
   
  </ul>
</details>


    </div>
  );
};

export default Policy;
