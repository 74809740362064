// Import necessary modules and components
import React, { useState, useEffect } from "react";
import { UserAuth } from "../context/AuthContext";
import MainNavbar from "../components/MainNavbar";

import { db } from "../config/firebase";
import {
  collection,
  onSnapshot,
  updateDoc,
  getDocs,
  getDoc,
  doc,
  serverTimestamp,
} from "firebase/firestore";

const Admin = () => {
  const { user } = UserAuth();
  const [emails, setEmails] = useState([]);
  const [fcmToken, setFcmToken] = useState('');

  const handleSubmit = async () => {
    try {
      const response = await fetch('https://sendpushnotification-wilwflbwma-uc.a.run.app', {
     
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ fcmToken })
      });

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.status}`);
      }

      const data = await response.json();
      console.log('Test notification sent:', data);
      alert('Test notification sent successfully!');
    } catch (error) {
      console.error('Error sending test notification:', error);
      alert('Error sending test notification: ' + error.message);
    }
  };

  const fetchEmails = async () => {
    fetch("https://getallauthenticateduseremails-wilwflbwma-uc.a.run.app")
      .then((response) => response.json())
      .then((data) => {
        console.log(data); // Log the entire response here
        setEmails(data.emails || []);
      })
      .catch((error) => {
        console.error("Error fetching emails:", error);
      });

    /*   const response = await fetch('https://getallauthenticateduseremails-wilwflbwma-uc.a.run.app'); // Replace with your function URL
  
      console.log(response)
      const data = await response.json();
      setEmails(data.emails || []);
    } catch (error) {
      console.error("Error fetching emails:", error);
    } */
  };

 /*  const generateCSV = () => {
    if (!emails.length) return; // Check if emails exist before proceeding

    const csvContent = `Email\n${emails.join("\n")}`; // Create CSV string with headers and emails joined by newlines

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
    const url = window.URL.createObjectURL(blob); // Create temporary URL

    const link = document.createElement("a");
    link.href = url;
    link.download = "emails.csv"; // Set filename
    link.click(); // Simulate a click to trigger download

    window.URL.revokeObjectURL(url); // Revoke temporary URL after download
  }; */

/*   const generateCSV = () => {
    if (!emails.length) return; // Check if emails exist before proceeding
  
    // Filter out emails containing "gmail" but not ending with ".com"
    const filteredEmails = emails.filter(email => !email.includes('gmail') || email.endsWith('.com'));
  
    // Create CSV content with headers and filtered emails joined by newlines
    const csvContent = `Email\n${filteredEmails.join('\n')}`;
  
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
    const url = window.URL.createObjectURL(blob);
  
    const link = document.createElement('a');
    link.href = url;
    link.download = 'emails.csv';
    link.click();
  
    window.URL.revokeObjectURL(url);
  }; */

  const generateCSV = () => {
    if (!emails.length) return; // Check if emails exist before proceeding
  
    // Filter for emails containing "gmail.com"
    const filteredEmails = emails.filter(email => email.endsWith('.com') && email.includes('@gmail.'));
  
    // Create CSV content without header
    const csvContent = filteredEmails.join('\n');
  
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
    const url = window.URL.createObjectURL(blob);
  
    const link = document.createElement('a');
    link.href   
   = url;
    link.download = 'emails.csv';
    link.click();
  
    window.URL.revokeObjectURL(url);
  };
  

  const generateMailgunFormat = () => {
    if (!emails.length) return; // Check if emails exist before proceeding

    // Implement your Mailgun format logic here, similar to generateCSV
    // ... transform emails to Mailgun format

    const formattedContent = "..."; // Replace with your Mailgun formatted content
    const blob = new Blob([formattedContent], {
      type: "text/plain;charset=utf-8",
    });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "emails_mailgun.txt"; // Set filename
    link.click();

    window.URL.revokeObjectURL(url);
  };

  return (
    <div className="page-container">
      <MainNavbar />
      <br />
      <br /> 
      {emails.length > 0 ? (
        <p>Sähköpostit haettu, löytyi {emails.length} kpl</p>
      ) : (
        <p>Ladataan sähköposteja...</p> // Or any other loading message
      )}
      {/* ... */}
      <button onClick={fetchEmails}>Fetch Emails</button>
      <button onClick={generateCSV} disabled={emails.length === 0}>
        Lataa pelkästään gmail-osoitteet (CSV)
      </button>
      <button onClick={generateMailgunFormat} disabled={emails.length === 0}>
        Download as Mailgun format
      </button>
      <div>
      <label htmlFor="fcmToken">FCM Token:</label>
      <input type="text" id="fcmToken" value={fcmToken} onChange={(e) => setFcmToken(e.target.value)} />
      <button onClick={handleSubmit}>Send Test Notification</button>
    </div>

    </div>
  );
};

export default Admin;
