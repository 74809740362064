import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IconChevronLeft } from '@tabler/icons-react';
import slipperLogo from '../assets/SlipperIcon.png';

const Terms = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate('/account');
    
  };

  return (
    <div className="page-container">
        <nav style={{ display: 'flex', paddingRight: '10px' }}>
        <ul>
          <li>
            <a href="#" onClick={goBack}>
              <IconChevronLeft size={35} />
            </a>
          </li>
        </ul>
      </nav>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
      <img src={slipperLogo} alt="Slipper Logo" style={{ height: '200px' , width: 'auto'}} />
      
      </div>
      
      <h4>Käyttöehtosopimus</h4>
     
      <details>
  <summary>Käyttöehtosopimuksen hyväksyntä ja kelpoisuus</summary>
  <p>Käyttämällä palveluamme hyväksyt seuraavat ehdot:</p>
  <ul>
    <li>Käyttäjän on oltava tietyssä ikäryhmässä (18+) ollakseen oikeutettu käyttämään palvelua.</li>
    <li>Sitoudut noudattamaan kaikkia Suomessa sovellettavia lakeja ja sääntöjä käyttäessäsi palveluamme.</li>
  </ul>
</details>


<details>
  <summary>Oma tilisi</summary>
  <ul>
    <li>Olet vastuussa antamiesi tietojen oikeellisuudesta ja päivittämisestä tarvittaessa.</li>
    <li>Emme ole vastuussa tilisi väärinkäytöstä tai kolmansien osapuolien saamasta pääsystä tilillesi.</li>
    <li>Mitään tilitietoja ei luovuteta ulkopuolisille ilman lupaasi.</li>
  </ul>
</details>

      <details>
  <summary>Palvelun lopettaminen</summary>
  <ul>
    <li>Pidätämme oikeuden lopettaa palvelun tarjoamisen milloin tahansa ilman erillistä ilmoitusta.</li>
  </ul>
</details>

<details>
  <summary>Turvallisuus; vuorovaikutus muiden jäsenten kanssa</summary>
  <ul>
    <li>Käyttäjät sitoutuvat käyttäytymään asiallisesti ja kunnioittamaan muita jäseniä.</li>
    <li>Ilmoita meille välittömästi epäilyttävistä toimista tai väärinkäytöksistä.</li>
    <li>Sovellus tarjoaa toiminnot käyttäjien estämiseen, sekä epäasiallisen sisällön raportointiin.</li>
    <li>Sisällön poistumisessa sovelluksesta voi kuitenkin esiintyä viiveitä.</li>
    <li>Emme ei ole vastuussa sovelluksen käytön kautta sovittujen tapaamisten seuraamuksista.</li>
  </ul>
</details>

<details>
  <summary>Oikeudet, jotka Slipper sallii sinulle</summary>
  <ul>
    <li>Palvelumme tarjoaa oikeuden käyttää alustaa sovitun tarkoituksen mukaisesti.</li>
  </ul>
</details>

<details>
  <summary>Oikeudet, jotka myönnät Slipperille</summary>
  <ul>
    <li>Sallit meille oikeuden käyttää ja analysoida tietojasi palvelumme parantamiseksi.</li>
    <li>Lähtökohtaisesti mitään tietojasi ei jaeta ulkopuolisille.</li>
  </ul>
</details>

<details>
  <summary>Yhteisön säännöt</summary>
  <ul>
    <li>Noudatat yhteisön sääntöjä ja sitoudut olemaan jakamatta asiatonta tai loukkaavaa sisältöä.</li>
  </ul>
</details>

<details>
  <summary>Muiden jäsenten sisältö</summary>
  <ul>
    <li>Emme ole vastuussa muiden jäsenten luomasta sisällöstä.</li>
    <li>Sovellus tarjoaa toiminnot käyttäjien estämiseen, sekä epäasiallisen sisällön raportointiin.</li>
    <li>Sisällön poistumisessa sovelluksesta voi kuitenkin esiintyä viiveitä.</li>
  </ul>
</details>

<details>
  <summary>Ostokset</summary>
  <ul>
    <li>Sovellus on toistaiseksi maksuton, mutta tarjoaa linkkejä yhteistyökumppaniden verkkokauppoihin.</li>
  </ul>
</details>

<details>
  <summary>Tekijänoikeuden loukkausta koskevia vaateita koskevat ilmoitukset ja menettely</summary>
  <ul>
    <li>Kunnioitamme tekijänoikeuksia ja pyydämme käyttäjiä tekemään samoin.</li>
    <li>Tekijänoikeuksia loukkaava sisältö on mahdollista raportoida yleisen epäasiallisen sisällön tapaan.</li>
  </ul>
</details>

<details>
  <summary>Vastuuvapauslausekkeet</summary>
  <ul>
    <li>Emme anna takuuta palvelumme jatkuvasta tai virheettömästä saatavuudesta.</li>
  </ul>
</details>

<details>
  <summary>Oikeuspaikka</summary>
  <ul>
    <li>Kaikki erimielisyydet käsitellään Suomen lain mukaisesti valitussa oikeuspaikassa.</li>
  </ul>
</details>

<details>
  <summary>Korvausvelvollisuutesi</summary>
  <ul>
    <li>Käyttäjä on vastuussa kaikista itselleen tai muille aiheutuneista vahingoista, mitä palvelumme käyttö saattaa aiheuttaa.</li>
  </ul>
</details>

   

    </div>
  );
};

export default Terms;
