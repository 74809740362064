import React, { useEffect, useState } from "react";
import { UserAuth } from "../context/AuthContext";
import {
  collection,
  onSnapshot,
  getDoc,
  doc,
  updateDoc,
  GeoPoint,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { db } from "../config/firebase";
import { calculateAge, calculateDistanceTo } from "../config/utils";
import MeetCard from "./MeetCard";
import { useNavigate } from "react-router-dom";
import { getToken } from "firebase/messaging";
//import { requestNotificationPermission, saveNotification } from '../context/NotificationService';

const MeetListing = () => {
  const { user } = UserAuth();
  //const [meetDocs, setMeetDocs] = useState([]);
  //const [selectedOption, setSelectedOption] = useState('Näytä kaikki tapaamiset');
  const [allMeets, setAllMeets] = useState([]);
  const [filteredMeets, setFilteredMeets] = useState([]);
  /*   const [userMeets, setUserMeets] = useState([]); */
  /*   const [distanceForMeets, setDistanceForMeets] = useState(300);
    const [partnerMinAge, setPartnerMinAge] = useState(18);
    const [partnerMaxAge, setPartnerMaxAge] = useState(99);
    const [genderInterest, setGenderInterest] = useState("Kaikkia");
    const [isSegmentedControlHidden, setIsSegmentedControlHidden] = useState(false); 
    const [currentPage, setCurrentPage] = useState(1); */
  const [meetsPerPage, setMeetsPerPage] = useState(10);
  const [showAllMeets, setShowAllMeets] = useState(false);

  //const [crystals, setCrystals] = useState(0);
  const [renderedCards, setRenderedCards] = useState([]);
  const navigate = useNavigate();
  const [favsOnlyCount, setFavsOnlyCount] = useState(0);
  const [loading, setLoading] = useState(true); // Loading state

  // Function to sort meets by meet_date
  const sortMeetsByDate = (meets) => {
    return meets.slice().sort((a, b) => {
      const dateA = new Date(a.meet_date);
      const dateB = new Date(b.meet_date);
      return dateA - dateB;
    });
  };

  const sortMeetsFromLatest = (meets) => {
    return meets.slice().sort((a, b) => {
      const dateA = new Date(b.meet_date); // Use b instead of a for descending order
      const dateB = new Date(a.meet_date); // Use a instead of b for descending order
      return dateA - dateB;
    });
  };

  useEffect(() => {
    ////console.log("Meets: useEffect called, show all meets is: ");
    ////console.log(showAllMeets);

    const fetchAffiliateCards = async () => {
      const affiliatesCollectionRef = collection(db, "affiliates");

      try {
        const userRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          const thisUserData = userDoc.data();
          ////console.log(thisUserData);
          ////console.log("Shop, User data existed, crystals: " + thisUserData.gems);
          //setCrystals(thisUserData.gems);
        }

        const querySnapshot = await getDocs(affiliatesCollectionRef);

        const affiliateCards = querySnapshot.docs.map((doc) => {
          const affiliateData = doc.data();
          return {
            type: "affiliate",
            active: affiliateData.active,
            company: affiliateData.company,
            company_logo_url: affiliateData.company_logo_url,
            image_url: affiliateData.image_url,
            price: affiliateData.price,
            product_name: affiliateData.product_name,
            tracking_link: affiliateData.tracking_link,
            onClick: () => affiliateCardClick(affiliateData.tracking_link),
          };
        });

        //const allCards = addGemCard(affiliateCards);
        //at the moment we don't display the gem card anymore

        setRenderedCards(affiliateCards); //use AllCards if you want to include the gem card
      } catch (error) {
        console.error("Error fetching affiliate cards:", error);
        return [];
      }
    };

    /* const fetchAllMeets = async (meetDocs, now, thisUserData) => {
      ////console.log("Meets: Fetching all meets: ");
      try {
        const allInitialMeets = meetDocs.filter((meet) => {
          const blocked =
            thisUserData.blocked_uids.includes(meet.owner_user) ||
            thisUserData.blocked_by_uids.includes(meet.owner_user);
          //console.log("For meet user: " + meet.owner_user + " is blocked: " + blocked);
          const isPast = new Date(meet.meet_date) < now;
          const isArchived = meet.archived;
          const isClosed = meet.closed;
          const isOnlyFavs = meet.only_favs || false; // Assume "only_favs" defaults to false if not present
          const likedByUserIds = thisUserData.liked_by_uids || [];

          return (
            !blocked &&
            !isArchived &&
            !isClosed &&
            !isPast &&
            (!isOnlyFavs || likedByUserIds.includes(meet.owner_user))
          );
        });

        const allMeetsSorted = sortMeetsByDate(allInitialMeets);
        setAllMeets(allMeetsSorted);
      } catch (error) {
        console.error("Error fetching all meets:", error);
      }
    }; */

    const fetchAllMeets = async (meetDocs, now, thisUserData) => {
      try {
        let favsOnlyCount = 0; // Initialize counter for favs only meets
    
        const allInitialMeets = meetDocs.filter((meet) => {
          const blocked =
            thisUserData.blocked_uids.includes(meet.owner_user) ||
            thisUserData.blocked_by_uids.includes(meet.owner_user);
          const isPast = new Date(meet.meet_date) < now;
          const isArchived = meet.archived;
          const isClosed = meet.closed;
          const isOnlyFavs = meet.only_favs || false; // Default to false if not present
          const likedByUserIds = thisUserData.liked_by_uids || [];
    
          const meetIsValid = !blocked && !isArchived && !isClosed && !isPast;
    
          if (meetIsValid && isOnlyFavs) {
            if (likedByUserIds.includes(meet.owner_user)) {
              favsOnlyCount++; // Increment counter if it's a fav-only meet
            }
          }
    
          // Return the meet if it meets the valid conditions, considering favs filter
          return meetIsValid && (!isOnlyFavs || likedByUserIds.includes(meet.owner_user));
        });
    
        const allMeetsSorted = sortMeetsByDate(allInitialMeets);
        setAllMeets(allMeetsSorted);
    
        // Log or set the count for favs-only meets
        //console.log(`Total favs-only meets: ${favsOnlyCount}`);
        setFavsOnlyCount(favsOnlyCount); // Optional: If you want to store it in state
      } catch (error) {
        console.error("Error fetching all meets:", error);
      }
    };
    

    const fetchPreferredMeets = async (meetDocs, now, thisUserData) => {
      try {
        const allInitialMeets = meetDocs.filter((meet) => {
          const blocked =
            thisUserData.blocked_uids.includes(meet.owner_user) ||
            thisUserData.blocked_by_uids.includes(meet.owner_user);
          const isPast = new Date(meet.meet_date) < now;
          const isArchived = meet.archived;
          const isClosed = meet.closed;
          const isOnlyFavs = meet.only_favs || false; // Assume "only_favs" defaults to false if not present

          // Fetch liked user IDs for filtering
          const likedByUserIds = thisUserData.liked_by_uids || [];

          /* return !blocked && !isArchived && !isClosed && !isPast; */
          return (
            !blocked &&
            !isArchived &&
            !isClosed &&
            !isPast &&
            (!isOnlyFavs || likedByUserIds.includes(meet.owner_user))
          );
        });

        /* const filteredInitialMeets = await Promise.all(
          allInitialMeets.map(async (meet) => {
            let distance = "0";
            try {
              distance = await calculateDistanceTo(meet.geolocation, user.uid);
            } catch (error) {
              console.error("Error calculating distance:", error);
              distance = "0";
            }

            try {
              //const distance = await calculateDistanceTo(meet.geolocation);

              //if (distance !== null) {
              const age = calculateAge(meet.owner_user_birthday);
              const meetsUserAgeRange =
                age >= thisUserData.partner_min_age &&
                age <= thisUserData.partner_max_age;
              const meetsUserGenderInterest =
                thisUserData.gender_interest === "Sukupuolella ei väliä" ||
                meet.owner_user_gender === thisUserData.gender_interest;
              const meetsDistance =
                distance <= parseInt(thisUserData.distance_for_meets, 10);

              if (
                meetsUserAgeRange &&
                meetsUserGenderInterest &&
                meetsDistance
              ) {
                return meet;
              } else {
                ////console.log("Meet does not meet user criteria:", meet);
              }
              //}
            } catch (error) {
              console.error("Error calculating distance or age:", error);
              return null; // Return a sentinel value (null) to identify errors
            }
          })
        ); */

        const filteredInitialMeets = await Promise.all(
          allInitialMeets.map(async (meet) => {
            let distance = 0;

            try {
              // Calculate distance, with fallback to "0" if there's an issue.
              distance =
                (await calculateDistanceTo(meet.geolocation, user.uid)) || 0;

              // Check if owner_user_birthday is valid before calculating the age.
              if (!meet.owner_user_birthday) {
                console.error("Missing birthday for meet owner:", meet);
                return null; // Ignore this meet if the birthday is missing.
              }

              const age = calculateAge(meet.owner_user_birthday);
              const meetsUserAgeRange =
                age >= thisUserData.partner_min_age &&
                age <= thisUserData.partner_max_age;
              const meetsUserGenderInterest =
                thisUserData.gender_interest === "Sukupuolella ei väliä" ||
                meet.owner_user_gender === thisUserData.gender_interest;
              const meetsDistance =
                distance <= parseInt(thisUserData.distance_for_meets, 10);

              // Return the meet only if all criteria are met.
              if (
                meetsUserAgeRange &&
                meetsUserGenderInterest &&
                meetsDistance
              ) {
                return meet;
              }

              // If the meet doesn't match criteria, return null.
              return null;
            } catch (error) {
              console.error("Error processing meet:", meet, error);
              return null; // In case of an error, return null to skip this meet.
            }
          })
        );

        const filteredInitialMeetsWithoutUndefined =
          filteredInitialMeets.filter(
            (meet) => meet !== undefined && meet !== null
          );
        const filteredMeetsSorted = sortMeetsByDate(
          filteredInitialMeetsWithoutUndefined
        );
        setFilteredMeets(filteredMeetsSorted);
        ////console.log("fetchPreferredMeets, filteredMeets has now " + filteredMeets.length + " items")
      } catch (error) {
        console.error("Error fetching preferred meets:", error);
      }
    };

    const fetchNoProfileMeets = async (meetDocs, now) => {
      try {
        const allInitialMeets = meetDocs.filter((meet) => {
          const isPast = new Date(meet.meet_date) < now;
          const isArchived = meet.archived;
          return !meet.closed && !isArchived && !isPast;
        });

        const allMeetsForNoProfileUserSorted = sortMeetsByDate(allInitialMeets);
        setAllMeets(allMeetsForNoProfileUserSorted);
      } catch (error) {
        console.error("Error fetching no profile meets:", error);
      }
    };
    /* 
    async function saveNotification(userDoc) {
      const existingToken = userDoc.data()?.fcmToken;

      if (typeof existingToken === "string" && existingToken.trim()) {
        //console.log('FCM token already exists:', existingToken);
        // You can optionally send the existing token to your server for management
        return; // Exit if token exists
      }

      // If no token or token is undefined/null/empty string
      try {
        const currentToken = await getToken(messaging, {
          vapidKey:
            "BCva3Kac0eat-1hAm-Nxvfp9-k0ZgSS8EgKbL_O7oVSlTMxiiHg7R2cI-o6lIAhG8UNvByQGnDBFFbs4oCTpM7k",
        });

        if (currentToken && currentToken.trim()) {
          await updateDoc(userDoc, { fcmToken: currentToken });
          //console.log("FCM token retrieved and stored in user document.");

        } else {
          console.log("Failed to retrieve a valid FCM token.");
        }
      } catch (err) {
        console.error("Error retrieving or storing token:", err);
      }
    } */

    /*   const requestNotificationPermission = async () => {
      if (Notification.permission !== "granted") {
        try {
          const permission = await Notification.requestPermission();

        } catch (err) {
          console.error("Error requesting notification permission:", err);
        }
      } else {
        console.log("Notification permission already granted");
        // Display notification or handle other logic
      }
    }; */

    const fetchMeets = async () => {
      try {
        const snapshot = await getDocs(collection(db, "meets"));
        const meetDocs = snapshot.docs.map((doc) => ({
          ...doc.data(),
          meetId: doc.id,
        }));
        ////console.log("fetchMeets, Meetdocs: " + meetDocs.length);

        const userRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          /* const permission = requestNotificationPermission();
          console.log("permission:")
          console.log(permission)
          if (permission === "granted") {
            saveNotification(userDoc);
          } else {
            console.log("Permission was not granted");
          }  */
          const thisUserData = userDoc.data();
          ////console.log(thisUserData);
          ////console.log("User data existed, crystals: " + thisUserData.gems);
          //setCrystals(thisUserData.gems);

          //setUserProfileExists(true);

          // Fetch liked user IDs for filtering
          // const likedByUserIds = thisUserData.liked_by_uids || [];

          if (showAllMeets) {
            ////console.log("fetching All meets")
            await fetchAllMeets(meetDocs, new Date(), thisUserData);
          } else {
            ////console.log("fetching just preferred meets")
            await fetchPreferredMeets(meetDocs, new Date(), thisUserData);
          }
        } else {
          //setUserProfileExists(false);

          await fetchNoProfileMeets(meetDocs, new Date());
        }
      } catch (error) {
        console.error("Error fetching meets:", error);
      }
    };

    fetchMeets();
    fetchAffiliateCards();


    const fetchData = async () => {
      setLoading(true); // Set loading to true before starting fetch
      try {
        await Promise.all([fetchAffiliateCards(), fetchMeets()]);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Set loading to false after fetching is complete
      }
    };
  
    fetchData();
    /*  if (!hasBeforeInstallPromptListener()) {
      addBeforeInstallPromptListener();
    }
    showInstallPrompt();  */
  }, [user, showAllMeets]);

  const navigateToMeetEditor = () => {
    navigate("/mymeeteditor");
  };

  const navigateToProfile = () => {
    navigate("/profile");
  };

  const handleScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;
    if (scrollTop + clientHeight >= scrollHeight) {
      setMeetsPerPage(meetsPerPage + 10);
    }
  };

  // const handleOptionChange = (option) => {
  //   setSelectedOption(option);
  // };

  /*  const toggleAccordion = (isOpen) => {
    const accordion = document.querySelector('details');
    if (accordion) {
      accordion.open = isOpen;
    }
  }; */

  /* useEffect(() => {

    const handleBeforeInstallPrompt = (event) => {
      //nothing to do here
      event.prompt();
    };
    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    // Cleanup function to remove listener on unmount
    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };


  }, []);
 */

  useEffect(() => {
    // Cleanup function to remove listener on unmount
    /*     const removeBeforeInstallPromptListener = () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  
    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt); */

    window.addEventListener("beforeinstallprompt", (e) => {
      console.log("Install prompt event triggered");
      // No e.preventDefault(), so the default mini-infobar will be displayed
    });

    // Function to update user's last location (if needed)
    const updateUserLocation = async () => {
      if (!navigator.geolocation) {
        console.warn("Geolocation not available");
        return;
      }

      try {
        const position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });

        const { latitude, longitude } = position.coords;

        const userRef = doc(db, "users", user.uid); // Assuming user ID is available
        //const userDoc = await getDoc(userRef);

        await updateDoc(userRef, {
          last_location: new GeoPoint(latitude, longitude),
        });
      } catch (error) {
        console.error("Error getting or updating user location:", error);
      }
    };

    // Check for last_location and update if needed
    updateUserLocation();

    // Call handleBeforeInstallPrompt at the end (after potential location update)
    //return removeBeforeInstallPromptListener; // Return cleanup function
  }, []);

  const getRandomAffiliateCards = (renderedCards, numCards) => {
    const shuffledCards = [...renderedCards].sort(() => 0.5 - Math.random());
    return shuffledCards.slice(0, numCards);
  };

  const affiliateCardClick = (affiliateLink) => {
    // Function to handle affiliate card click
    // ...

    ////console.log(`Clicked on affiliate card: ${affiliateLink}`);

    // Open a new tab with the affiliate link
    const newTab = window.open(affiliateLink, "_blank");

    // Focus on the new tab (if needed)
    if (newTab) {
      newTab.focus();
    }
  };

  return (
    /*  <div style={{ display: 'flex', flexWrap: 'wrap' }}> */
    <div>
              {/* {loading ? ( // Check if still loading
        <small>Loading... Please wait.</small> // Loading message or spinner
      ) : (
        allMeets.length > 0 ? ( // Check if there are active meets
          <small>
            Slipperissä on tällä hetkellä {allMeets.length} aktiivista tapaamista,
            joista {favsOnlyCount > 0 ? favsOnlyCount : 0} on vain suosikeille.
          </small>
        ) : (
          <small>
            Tällä hetkellä ei ole aktiivisia tapaamisia.
          </small>
        )
      )} */}


      <div>

        <div className="switch-container">
          <label for="switch">
            <input
              type="checkbox"
              id="switch"
              name="switch"
              role="switch"
              checked={!showAllMeets}
              onChange={() => setShowAllMeets(!showAllMeets)}
            />
          </label>
          {showAllMeets
            ? "Näytä kaikki tapaamiset"
            : "Vain toiveideni mukaiset tapaamiset"}
        </div>
        {allMeets && allMeets.length > 0 && filteredMeets && filteredMeets.length > 0? (
  <small>
    Slipperissä on tällä hetkellä {allMeets.length} aktiivista tapaamista. Näistä {favsOnlyCount > 0 ? favsOnlyCount : 0} on suunnattu vain suosikeille.
  </small>
) : (
  <div></div> // or <></>, an empty fragment
)}
        
        <div onScroll={handleScroll}>
       
          <br />
          {showAllMeets && allMeets && allMeets.length > 0 ? (


            allMeets.map((meet, index) => {
              const showAd = index % 3 === 2; // Show ad after every 3 MeetCards (0-based index)
              const randomAffiliateCards = showAd
                ? getRandomAffiliateCards(renderedCards, 1)
                : []; // Empty array if no ad should be shown

              return (
                <div key={meet.id}>
                  <MeetCard meet={meet} />
                  {randomAffiliateCards.map((card) => (
                    <div key={card.id}>
                      <div
                        className="image-container"
                        onClick={() => affiliateCardClick(card.tracking_link)}
                      >
                        <img
                          src={card.image_url}
                          alt="Card Image"
                          className="image"
                        />

                        {card.product_name && (
                          <strong className="product-name">
                            {card.product_name}
                          </strong>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              );
              
            })
          ) : showAllMeets ? (
            <div>
              Tällä hetkellä ei ole aktiivisia tapaamisia, tule tarkistamaan
              myöhemmin uudelleen.
              <br />
              <br />
              <button onClick={navigateToMeetEditor}>
                Tai luo oma tapaaminen tästä
              </button>
            </div>
          ) : filteredMeets && filteredMeets.length > 0 ? (
            filteredMeets.map((meet, index) => {
              // Added index here
              const showAd = index % 3 === 2; // Same logic for filtered meets
              const randomAffiliateCards = showAd
                ? getRandomAffiliateCards(renderedCards, 1)
                : [];

              return (
                <div key={meet.id}>
                  <MeetCard meet={meet} />
                  {randomAffiliateCards.map((card) => (
                    <div key={card.id}>
                      <div
                        className="image-container"
                        onClick={() => affiliateCardClick(card.tracking_link)}
                      >
                        <img
                          src={card.image_url}
                          alt="Card Image"
                          className="image"
                        />

                        {card.product_name && (
                          <strong className="product-name">
                            {card.product_name}
                          </strong>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              );
            })
          ) : (
            /* filteredMeets && filteredMeets.length > 0 ? (
                <div>

                  {filteredMeets.map((meet) => {
                    ////console.log('Meet object:', meet);
                    return (
                      <div key={meet.id}>
                        <MeetCard meet={meet}/>
                      </div>
                    );
                  })}
                </div>
              ) */
            <div>
              Asettamillasi ehdoilla ei ole vielä tarjolla tapaamisia, mutta
              voit muuttaa mieltymyksiäsi muokkaamalla profiiliasi tai tulla
              tarkistamaan myöhemmin uudelleen.
              <br />
              <br />
              <button onClick={navigateToProfile}>
                Muokkaa mieltymyksiä profiilissasi
              </button>
            </div>
          )}
        </div>
      </div>

     
    </div>
  );
};

export default MeetListing;
