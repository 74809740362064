import { useContext, createContext, useEffect, useState } from 'react';
import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  signInWithRedirect,
  signOut,
  deleteUser,
  onAuthStateChanged,
  sendPasswordResetEmail,
} from 'firebase/auth';
import { useNavigate } from "react-router-dom";
import { auth } from '../config/firebase';


const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(null); // Start with null as initial value
  const navigate = useNavigate();

  const isAuthenticated = Boolean(user); // Set isAuthenticated based on user existence

  

  const googleSignIn = () => {
    ////console.log("AuthContext: Google sign in")
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        const user = result.user;
        ////console.log("This user returned from Google login: ");
        ////console.log(user);
        setUser(user);
        navigate('/meets');
      })
      .catch((error) => {
        ////console.log('Error signing in with Google:', error);
      });
  };

  const resetPassword = async (email) => {
    ////console.log('resetPassword');
    try {
       
        if (email) {
            // Send a password reset email
            await sendPasswordResetEmail(auth, email);
            ////console.log('Password reset email sent successfully. Check your inbox.');
            // Optionally, you can provide feedback to the user that the email has been sent
        } else {
            ////console.log('Email address is required for password reset.');
            // Optionally, you can provide feedback to the user that the email is required
        }
    } catch (error) {
        console.error('Error sending password reset email:', error.message);
        // Optionally, you can provide feedback to the user about the error
    }
};
  

  /* const facebookSignIn = () => {
    const provider = new FacebookAuthProvider();
    const credential = FacebookAuthProvider.credentialFromResult(result);
    const accessToken = credential.accessToken;

    signInWithPopup(auth, provider)
      .then((result) => {
        const user = result.user;
        ////console.log("This user returned from facebook login: ")
        ////console.log(user)
        setUser(user);
        navigate('/meets');
      })
      .catch((error) => {
        ////console.log('Error signing in with Facebook:', error);
      });
  }; */

  const emailSignUp = async (email, password) => {
    try {
      ////console.log("AuthContext: Trying to create new user: ")
      ////console.log(email)
      ////console.log(password)
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      
      if (user) {
        ////console.log("AuthContext: SignUp successful");
        setUser(user);
      } else {
        // Handle unsuccessful login (non-existing user, wrong password, etc.)
        ////console.log("AuthContext: SignUp unsuccessful");
        throw new Error('Signup failed.');
      }
    } catch (error) {
      
      console.error('Error signing up:', error.code, error.message);
      throw error; // Re-throw the error to be caught by the calling function
    }
  };
  
/*   const emailSignIn = async (email, password) => {
    try {
      ////console.log("AuthContext: Trying to login with email and password")
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      setUser(user);
    } catch (error) {
      ////console.log('Error signing in:', error);
    }
  }; */

  const emailSignIn = async (email, password) => {
    try {
      ////console.log("AuthContext: Trying to login with email and password");
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
  
      // Check if the user is logged in successfully
      if (user) {
        ////console.log("AuthContext: Login successful");
        setUser(user);
      } else {
        // Handle unsuccessful login (non-existing user, wrong password, etc.)
        ////console.log("AuthContext: Login unsuccessful");
        throw new Error('Authentication failed.');
      }
    } catch (error) {
      ////console.log('Error signing in:', error);
      throw error; // Re-throw the error to be caught by the calling function
    }
  };
  


  const logOut = async () => {
    await signOut(auth);
    navigate('/landing');
  };

  useEffect(() => {
    ////console.log("AuthContext useEffect called")
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      ////console.log("AuthContext: authState changed, now user is "+currentUser)
      setUser(currentUser);
      
      //navigate("/meets");
      
    });
    return () => {
      ////console.log("AuthContext unsubscribe called")
      unsubscribe();
    };
  }, []);

  return (
 
    <AuthContext.Provider
    value={{ googleSignIn, emailSignUp, emailSignIn, logOut, user, isAuthenticated, resetPassword, deleteUser }}>
    {children}
  </AuthContext.Provider>
  );
};


export const UserAuth = () => {
  return useContext(AuthContext);
};
