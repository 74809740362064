import React, { useRef, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext';
import { collection, onSnapshot, updateDoc, getDocs, getDoc, doc, serverTimestamp } from 'firebase/firestore';
import { db, storage } from "../config/firebase";
import MeetAvatar from '../components/MeetAvatar';
import UserPreviewModal from '../components/UserPreview';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, listAll, deleteObject } from "firebase/storage";
import MainNavbar from '../components/MainNavbar';
import { calculateDistanceTo, formatDate, formatTime, formatWeekday } from '../config/utils';
import Modal from 'react-modal';
import LogoBar from '../components/LogoBar';
//import { setUserActive, isUserActive } from '../context/UserActivityService';




/* const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  }; */




const Discussions = () => {
    const { user } = UserAuth();
    const [groupedChats, setGroupedChats] = useState({});
    const [userPreviewData, setUserPreviewData] = useState(null);
    const [chatId, setChatId] = useState(null);
    const [isChatModalOpen, setIsChatModalOpen] = useState(false);
    const [selectedChat, setSelectedChat] = useState(null);
    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
    const [meetDocs, setMeetDocs] = useState([]);
    const [chatsUnsubscribe, setChatsUnsubscribe] = useState(null);
    const [users, setUsers] = useState([]);
    const [messages, setMessages] = useState([]);
    const [currentMessage, setCurrentMessage] = useState([]);
    const [isChatFree, setIsChatFree] = useState([]);
    const messageContainerRef = useRef(null);
    const [confirmLockVisible, setConfirmLockVisible] = useState(false);
    const [midnightApproaching, setMidnightApproaching] = useState(false);
    const navigate = useNavigate();
    const [crystals, setCrystals] = useState(0);


    const isBlocking = (user1, user2) => {
        const user1BlockedByUids = user1.blocked_by_uids || [];
        const user1BlockedUids = user1.blocked_uids || [];

        const user2BlockedByUids = user2.blocked_by_uids || [];
        const user2BlockedUids = user2.blocked_uids || [];

        // Check if user1 has blocked user2 or if user2 has blocked user1
        const user1BlockedUser2 = user1BlockedUids.includes(user2.uid) || user1BlockedByUids.includes(user2.uid);
        const user2BlockedUser1 = user2BlockedUids.includes(user1.uid) || user2BlockedByUids.includes(user1.uid);

        return user1BlockedUser2 || user2BlockedUser1;
    };

    useEffect(() => {
        ////console.log("Discussions useEffect");
        // Define a reference for the 'chats' collection
        const chatsCollectionRef = collection(db, 'chats');
        // Define a reference for the 'meets' collection
        const meetsCollectionRef = collection(db, 'meets');
        const usersCollectionRef = collection(db, 'users');


        const fetchUsers = async () => {
            try {

                  /*const userRef = doc(db, "users", user.uid);
                const userDoc = await getDoc(userRef);

               if (userDoc.exists()) {
                    const thisUserData = userDoc.data();
                    ////console.log(thisUserData);
                    ////console.log("Shop, User data existed, crystals: " + thisUserData.gems);
                    //setCrystals(thisUserData.gems)
                } */

                const querySnapshot = await getDocs(usersCollectionRef);
                const fetchedUsers = querySnapshot.docs.map((doc) => ({
                    userId: doc.id,
                    ...doc.data(),
                }));
                setUsers(fetchedUsers);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        // Define a function to fetch meet documents
        const fetchMeetDocs = async () => {
            try {
                const querySnapshot = await getDocs(meetsCollectionRef);
                const meetDocs = querySnapshot.docs.map((doc) => ({ ...doc.data(), meetId: doc.id }));
                setMeetDocs(meetDocs);
                ////console.log("Discussions, meetDocs " + meetDocs.length);
            } catch (error) {
                console.error('Error fetching meet documents:', error);
            }
        };

        // Define a function to fetch chats
        const fetchChats = async () => {
            try {
                // Retrieve chats where the current user is either the owner or the applicant
                onSnapshot(
                    chatsCollectionRef,
                    (snapshot) => {
                        const chats = snapshot.docs
                            .map((doc) => ({
                                id: doc.id,
                                chatData: doc.data(),
                            }))
                            .filter((chat) => {
                                const { owner_id, applicant_id, archived } = chat.chatData;
                                const currentUserUid = user.uid;

                                // Add an additional condition to exclude archived chats
                                return (
                                    (currentUserUid === owner_id || currentUserUid === applicant_id) && !archived
                                );
                            });

                        // Group chats by meet_id
                        const groupedChats = chats.reduce((groups, chat) => {
                            const meetId = chat.chatData.meet_id;
                            if (!groups[meetId]) {
                                groups[meetId] = [];
                            }
                            groups[meetId].push(chat);
                            return groups;
                        }, {});

                        setGroupedChats(groupedChats);
                        ////console.log("Discussions, groupedChats " + Object.keys(groupedChats).length);
                    },
                    (error) => {
                        console.error('Error fetching chats:', error);
                    }
                );
            } catch (error) {
                console.error('Error fetching chats:', error);
            }
        };




        // Call the fetchChats function when the component mounts
        if (user) {
            fetchMeetDocs();
            fetchChats();
            fetchUsers();

        }

        return () => {
            // No unsubscribe logic here
        };
    }, [user]);

    const isCurrentUserTurn = (chat) => {
        // Check if there are any messages in the chat
        if (chat.chatData.messages.length > 0) {
            const latestMessage = chat.chatData.messages[chat.chatData.messages.length - 1];
            ////console.log("Discussions: It's current users turn to reply to chat")
            // Check if the sender of the latest message is the current user
            return latestMessage.sender_id !== user.uid;
        } else {
            return true; // No messages, so it's the user's turn
        }
    };

    const isCurrentUserOwner = (chat) => {

        if (user.uid === chat.chatData.owner_id) {
            return true;
        } else {
            return false;
        }
        
    };

   

    const hasNewMessages = (chat, userId) => {
        // Check if there are new messages in the chat
        //console.log("Has new messages, users: ")
        //console.log(user.uid)
        //console.log(userId)
        if (chat.chatData.messages.length > 0) {
            //console.log("There are more than 0 messages")
            const latestMessage = chat.chatData.messages[chat.chatData.messages.length - 1];
            //console.log("Latest message is and latest check time is ")
            //console.log(latestMessage)
            // Determine which lastCheckTime to use based on the user's role
            let lastCheckTime;
            //console.log("Owner and applicant: ")
            //console.log(chat.chatData.owner_id)
            //console.log(chat.chatData.applicant_id)
            if (userId === chat.chatData.owner_id) {
                //console.log("User is owner, these match : "+userId+" and "+chat.chatData.owner_id)
                lastCheckTime = chat.chatData.lastCheckTimeOwner;
            } else if (userId === chat.chatData.applicant_id) {
                //console.log("User is applicant")
                lastCheckTime = chat.chatData.lastCheckTimeApplicant;
            } else {
                // The user is neither the owner nor the applicant, so there are new messages
                return false;
            }
            ////console.log(lastCheckTime)
            // Check if the latest message is newer than the last check time and the latest message is not from you
            if (latestMessage.timestamp > lastCheckTime) {
                //console.log("Discussions: Latest message is more than last checktime, so new message.")
                //console.log(latestMessage.timestamp+" is bigger than "+lastCheckTime)
                return true; // There are new messages
            }
        }

        return false; // No new messages
    };


    const countNewMessages = (chat, userId) => {
        let newMessageCount = 0;
        if (chat.chatData.messages.length > 0) {
          const latestMessage = chat.chatData.messages[chat.chatData.messages.length - 1];
      
          // Determine which lastCheckTime to use based on the user's role
          let lastCheckTime;
          if (userId === chat.chatData.owner_id) {
            lastCheckTime = chat.chatData.lastCheckTimeOwner;
          } else if (userId === chat.chatData.applicant_id) {
            lastCheckTime = chat.chatData.lastCheckTimeApplicant;
          } else {
            // The user is neither the owner nor the applicant, so all messages are new
            return chat.chatData.messages.length; // Return the total number of messages
          }
      
          // Count messages newer than the last check time and not from the user
          for (const message of chat.chatData.messages) {
            if (message.timestamp > lastCheckTime && message.sender_id !== userId) {
              newMessageCount++;
            }
          }
        }
      
        return newMessageCount;
      };
      

    const handleAvatarClick = (userData) => {
        setUserPreviewData(userData);
        setIsPreviewModalOpen(true);
    };


    const updateLastSeen = async (chat) => {
        if (user.uid === chat.chatData.owner_id) {
            ////console.log("User is owner");
            const chatDocRef = doc(db, 'chats', chat.id);

            try {
                // Update lastCheckTimeOwner to the current time
                await updateDoc(chatDocRef, {
                    lastCheckTimeOwner: serverTimestamp(),
                });

                ////console.log("lastCheckTimeOwner updated");
            } catch (error) {
                console.error("Error updating lastCheckTimeOwner:", error);
            }
        } else if (user.uid === chat.chatData.applicant_id) {
            ////console.log("User is applicant");
            const chatDocRef = doc(db, 'chats', chat.id);

            try {
                // Update lastCheckTimeApplicant to the current time
                await updateDoc(chatDocRef, {
                    lastCheckTimeApplicant: serverTimestamp(),
                });

                ////console.log("lastCheckTimeApplicant updated");
            } catch (error) {
                console.error("Error updating lastCheckTimeApplicant:", error);
            }
        }
    }




    const handleOpenChatClick = (chat) => {
        ////console.log("OpenChatClicked!");
        ////console.log("Chat:");
        ////console.log(chat);
        updateLastSeen(chat);

        // Navigate to the chat page with the chat ID as a parameter
        navigate(`/chat/${chat.id}`);

        // Optionally, you can still update state if needed
        setSelectedChat(chat);
        //setUserActive(currentUser.id);
        setIsChatModalOpen(true);
    };







    /* const getMeetAvatar = (chat) => {
        //tää menee jo ihan ufoksi, pitää kai hakea ton userin tiedotkin jostain kuten tehtiin Meetsissä
        //mutta eikö tän pitäis aina näyttää sen vastapuolen tiedot?
        ////console.log("Chat:")
        ////console.log(chat)
        const isCurrentUserOwner = user.uid === chat.owner_id;
        ////console.log(isCurrentUserOwner)
        ////console.log("id's:  "+chat.chatData.applicant_id+" and "+chat.chatData.owner_id)
        const thisChatsOpposingUserId = isCurrentUserOwner ? chat.chatData.applicant_id : chat.chatData.owner_id;
        ////console.log(thisChatsOpposingUserId)
        const userRef = doc(db, "users", thisChatsOpposingUserId);
        let userData;
        getDoc(userRef).then((doc) => {
          if (doc.exists()) {
            ////console.log("Doc existed")
            userData = doc.data();
            //setUserData(userData);
            //nämä vois muuttaa käyttämään suoraan userDataa
            //setDistanceForMeets(userData.distance_for_meets);
            //setPartnerMinAge(userData.partner_min_age);
            //setPartnerMaxAge(userData.partner_max_age);
            //setGenderInterest(userData.gender_interest);
          } else {
            ////console.log("Meets, user does not have a profile yet!");
          }
        });
        ////console.log(userData)
        return (
            <MeetAvatar
                imageUrl={userData.thumbUrl}
                birthday={userData.birthday}
                name={userData.name}
                onClick={handleAvatarClick}
            />
        );
    }; */

   

    

    const getMeetAvatar = (chat) => {

        const isCurrentUserOwner = user.uid === chat.chatData.owner_id;
        ////console.log("is owner? " + isCurrentUserOwner)
        const thisChatsOpposingUserId = isCurrentUserOwner
            ? chat.chatData.applicant_id
            : chat.chatData.owner_id;

        // Find the user in the 'users' array based on their user ID
        const otherUser = users.find((user) => user.userId === thisChatsOpposingUserId);

        if (otherUser) {
            const avatarStyle = {
                display: 'flex',
                alignItems: 'center',
            };

            const imgStyle = {
                width: '60px', // Set the desired width for the image
                height: '60px', // Set the desired height for the image
                borderRadius: '50%', // Make the image circular
                marginRight: '10px', // Adjust the margin as needed
            };

            const showProfilePage = (e) => {
                e.stopPropagation();
                // Assuming user.uid is available
                //const userUid = user.uid; // Replace this with the actual way you get the user's UID
              
                // Navigate to the "/profilecard" route with the uid as a parameter
                navigate(`/profilecard/${thisChatsOpposingUserId}`);
                
              };

            return (
                <div className="user-avatar" style={avatarStyle} >
                    <img
                    onClick={(e) => showProfilePage(e)}
                        src={otherUser.thumbUrl}
                        alt={otherUser.name}
                        style={imgStyle}
                    />
                    <div className="user-name">{otherUser.name}</div>


                </div>

            );
        } else {
            return (
                <div>
                    User not found or loading...
                </div>
            );
        }
    };

    const showMeetDetails = (meet) => {
        // Replace this with your logic to handle the meet details
        ////console.log('Meet details:', meet);
        // For example, you could navigate to a meet details page
        // navigate(`/meet-details/${meet.id}`);
    };


    return (
        <div className="page-container">
            <LogoBar crystals={crystals} />
            <MainNavbar />
            <strong>Keskustelut</strong>
            <br /><br />
            {/*  <div>

                <Modal
                    isOpen={isChatModalOpen}
                    onAfterOpen={afterOpenChatModal}
                    onRequestClose={handleChatModalClose}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div>
                        <nav style={{ display: 'flex', paddingRight: '10px' }}>
                            <ul>
                                <li>
                                    <a href="#" onClick={handleChatModalClose}>
                                        <IconChevronLeft size={35} />
                                    </a>
                                </li>
                                {selectedChat && (
                                    <li>{getMeetAvatar(selectedChat)}</li>
                                )}
                            </ul>
                            <ul>
                                <li>
                                    <a href="#" onClick={() => handleBlockUser(selectedChat)}>
                                        <IconHandStop size={35} />
                                    </a>
                                </li>
                            </ul>
                        </nav>


                        <div>
                            {midnightApproaching ? (
                                <div>
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <p style={{ marginBottom: '10px' }}>
                                            Muistathan, että taika katoaa puolenyön aikaan, jolloin keskustelu joutuu ikuiseen kadotukseen. Jos haluatte vaihtaa yhteystietoja tai sopia jatkosta, niin se on syytä tehdä ennen deittikumppanisi muuttumista kurpitsaksi.
                                        </p>
                                 

                                    </div>
                                </div>
                            ) : null}

                            {confirmLockVisible ? (
                                <div>
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <p style={{ marginBottom: '10px' }}>
                                            Tahdotko valita henkilön tähän tapaamiseen?
                                        </p>
                                        <div style={{ display: 'flex' }}>
                                            <button onClick={onTahdonClick(selectedChat)} style={{ marginRight: '10px' }}>Tahdon</button>
                                            <button onClick={onPeruutaClick}>Peruuta</button>
                                        </div>
                                        <p style={{ fontSize: '14px', marginTop: '10px' }}>
                                            Valinta lopettaa automaattisesti keskustelut muiden tapaamiseen ilmoittautuneiden kanssa, sekä poistaa tapaamisen näkyviltä muilta paitsi sinulta ja valitsemaltasi ihmiseltä.
                                        </p>
                                    </div>
                                </div>
                            ) : null}

                        </div>

                        <div ref={messageContainerRef} style={{ display: 'flex', flexDirection: 'column', padding: '10px', position: 'absolute', top: '90px', bottom: '120px', width: '100%', overflowY: 'auto' }}>
                            {messages.map((message, index) => {
                                const messageStyle = {
                                    backgroundColor: message.sender_id === user.uid ? '#00008b' : '#AA336A',
                                    color: message.sender_id === user.uid ? 'white' : 'black',
                                    borderRadius: '10px',
                                    alignSelf: message.sender_id === user.uid ? 'flex-end' : 'flex-start',
                                    maxWidth: '80%',
                                    padding: '10px',
                                    marginBottom: '7px'
                                };

                                return (
                                    <div key={index} style={messageStyle}>
                                        <p style={{ margin: '0', padding: '0' }}>{message.text}</p>

                                    </div>

                                );
                            })}
                        </div>
                        {isChatModalOpen && (
                            <nav style={{
                                position: 'fixed',
                                bottom: '0',
                                left: '0',
                                right: '0',
                                zIndex: '10',
                                display: 'flex',
                                justifyContent: 'space-between',
                                padding: '10px',
                                backgroundColor: 'transparent',
                            }}>
              

                                <textarea
                                    style={{
                                        flex: 1,
                                        padding: '10px',
                                        width: '100%',
                                        height: '100px', // Set the height to make it larger
                                        resize: 'vertical',
                                        wordWrap: 'break-word',
                                    }}
                                    placeholder="Kirjoita viesti..."
                                    value={currentMessage}
                                    onChange={(e) => setCurrentMessage(e.target.value)}
                                    onInput={(e) => {
                                        const maxLength = 1000; // Maximum character count
                                        if (e.target.value.length > maxLength) {
                                            e.target.value = e.target.value.slice(0, maxLength); // Truncate text
                                        }
                                    }}
                                    onKeyUp={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            sendMessage();
                                            // Call the function to handle sending the message (e.g., sendMessage) here
                                            // Ensure that you trigger the same functionality that was in the form approach.
                                        }
                                    }}
                                />

                
                                <button
                                    style={{
                                        listStyle: 'none',
                                        backgroundColor: 'transparent',
                                        color: 'white',
                                        cursor: 'pointer',
                                        paddingLeft: '5px',
                                        paddingRight: '0px',
                                        //margin: '0px',
                                        border: 'none', // Remove the border
                                        width: 'auto', // Button width will adjust to the content
                                        display: 'flex',
                                        justifyContent: 'center', // Center horizontally
                                        alignItems: 'center', // Center vertically
                                    }}
                                    onClick={sendMessage}
                             

                            </nav>



                        )}



                    </div>
                </Modal> */}

            {Object.keys(groupedChats).length === 0 && (
                <div>
                    <p>Sinulla ei ole vielä yhtään keskustelua. Ilmoittaudu tapaamisiin tai luo omasi, jotta keskusteluja voi syntyä.</p>
                </div>
            )}
            {Object.keys(groupedChats).map((meetId) => {
                // Find the corresponding meet for the current meetId
                ////console.log("MeetDocs:")
                ////console.log(meetDocs)
                const meet = meetDocs.find((meet) => meet.meetId === meetId);
                // Check if we found a valid meet object

                if (meet) {
                    return (
                        <div key={meetId} class="meet-card">
                            <div class="chatbackground">
                                <img src={meet.meet_thumb_url} alt="Meet Thumbnail" />
                                <div className="chatinfo-display-square" onClick={() => showMeetDetails(meet)}>
                                    {/*  <span>{meet.meet_type} </span> */}
                                    <span style={{ color: 'yellow', textTransform: 'capitalize', margin: '0', padding: '0' }}>
                                        {meet.meet_type}
                                        <br />
                                        {formatWeekday(meet.meet_date)} {formatDate(meet.meet_date)} {formatTime(meet.meet_date)}
                                        <br />
                                        {meet.location}
                                        <br />
                                        {meet.owner_user === user.uid && (
              <span style={{ color: '#33cc33', fontWeight: 'bold' }}>Sinun ilmoituksesi</span>
            )}
                                    </span>
                                </div>
                                {/* Render chat messages in the body */}
                                <div>
                                    {groupedChats[meetId].map((chat) => (
                                        <div class="chat-info" onClick={() => handleOpenChatClick(chat)}>
                                            <div className="user-avatar" >
                                                {getMeetAvatar(chat)}
                                            </div>
                                            <div  >
                                               
                                                {/* {hasNewMessages(chat, user.uid) && (
                                                    <div className="chat-data-bubble">Uusia viestejä</div>
                                                )} */}
                                                {hasNewMessages(chat, user.uid) && (
  <div className="chat-data-bubble">
    {countNewMessages(chat, user.uid)}
  </div>
)}
 {isCurrentUserTurn(chat) && (
                                                    <div className="chat-yourturn-bubble">Sinun vuorosi</div>
                                                )}
                                                {/* {chat.chatData.locked && (
                                                    <div className="chat-data-bubble">Seuralainen valittu</div>
                                                )} */}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    );
                } else {
                    return null; // Handle cases where a valid meet object isn't found
                }
            })}



        </div>
    );
};

export default Discussions;
