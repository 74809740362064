// Import necessary modules and components
import React, { useState, useEffect } from 'react';
import { UserAuth } from '../context/AuthContext';
import MainNavbar from '../components/MainNavbar';
import LogoBar from '../components/LogoBar';
import { db } from "../config/firebase";
import { collection, onSnapshot, updateDoc, getDocs, getDoc, doc, serverTimestamp } from 'firebase/firestore';
//import gemImage from '../assets/GemCard.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Shop = () => {
  const { user } = UserAuth();

  //const [gemCardCounter, setGemCardCounter] = useState(0);
  const [showMaxGemsDialog, setShowMaxGemsDialog] = useState(false);
  const [crystals, setCrystals] = useState(0);


  // State to track whether a gem card has been inserted
  const [renderedCards, setRenderedCards] = useState([]);

  // Function to handle gem card click

  const collectGemClick = async () => {
    // Check if the user can find more gem cards today

    try {
      // Retrieve user document using user.uid
      const userDocRef = doc(db, 'users', user.uid);
      const userDocSnapshot = await getDoc(userDocRef);

      if (userDocSnapshot.exists()) {
        const userData = userDocSnapshot.data();


        // Check if the user has less than max_gems
        if (userData.gems < userData.max_gems) {
          // Update gems field (increment by 1)
          await updateDoc(userDocRef, {
            gems: userData.gems + 1,
          });

          setCrystals(userData.gems + 1)  //update UI in the logobar hopefully
          // Remove the gem card from the UI (assuming you have reference to it in the state)
          // Update the state to trigger re-rendering without the collected gem card
          const updatedRenderedCards = renderedCards.filter((card) => card.type !== 'gem');
          setRenderedCards(updatedRenderedCards);
          toast.success("1 x kristalli lisättiin saldoosi.", {

            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",

          });

        } else {
          // Inform the user that the maximum number of gem cards has been reached for the day
          toast.warn("Sinulla on jo maksimimäärä kristalleja. Käytä niitä ensin pois.", {

            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",

          });
          //setShowMaxGemsDialog(true);
        }
      }
    } catch (error) {
      console.error('Error updating user document:', error);
    }

  };


  const affiliateCardClick = (affiliateLink) => {
    // Function to handle affiliate card click
    // ...

    ////console.log(`Clicked on affiliate card: ${affiliateLink}`);

    // Open a new tab with the affiliate link
    const newTab = window.open(affiliateLink, '_blank');

    // Focus on the new tab (if needed)
    if (newTab) {
      newTab.focus();
    }
  };

  /* const addGemCard = (affiliateCards) => {
    const randomIndex = Math.floor(Math.random() * (affiliateCards.length + 1));
    ////console.log("Adding gem card, random index is " + randomIndex)
    // Insert the gem card at the random index
    const newAllCards = [...affiliateCards];
    newAllCards.splice(randomIndex, 0, {
      type: 'gem',
      gemCardUrl: '../assets/GemCard.jpg',
      onClick: () => collectGemClick(),
    });

    return newAllCards;
  }; */




  /*   const generateAffiliateCards = async () => {
      const affiliatesCollectionRef = collection(db, 'affiliates');
    
      try {
        const querySnapshot = await getDocs(affiliatesCollectionRef);
        const affiliateCards = querySnapshot.docs.map((doc) => {
          const affiliateData = doc.data();
          return {
            type: "affiliate",
            active: affiliateData.active,
            company: affiliateData.company,
            company_logo_url: affiliateData.company_logo_url,
            image_url: affiliateData.image_url,
            price: affiliateData.price,
            product_name: affiliateData.product_name,
            tracking_link: affiliateData.tracking_link,
          };
        });
    
        return affiliateCards;
      } catch (error) {
        console.error('Error fetching affiliate cards:', error);
        return [];
      }
    }; */


  /*  useEffect(() => {
     const fetchData = async () => {
       const affiliatesCollectionRef = collection(db, 'affiliates');
 
       try {
         const querySnapshot = await getDocs(affiliatesCollectionRef);
         const affiliateCards = querySnapshot.docs.map((doc) => {
           const affiliateData = doc.data();
           return {
             type: "affiliate",
             active: affiliateData.active,
             company: affiliateData.company,
             company_logo_url: affiliateData.company_logo_url,
             image_url: affiliateData.image_url,
             price: affiliateData.price,
             product_name: affiliateData.product_name,
             tracking_link: affiliateData.tracking_link,
           };
         });
 
         // Determine a random index to insert the gem card
         const randomIndex = Math.floor(Math.random() * (affiliateCards.length + 1));
 
         // Insert the gem card at the random index
         const newAllCards = [...affiliateCards];
         newAllCards.splice(randomIndex, 0, {
           type: 'gem',
           gemCardUrl: '../assets/GemCard.jpg',
           onClick: () => handleGemCardClick(),
         });
 
         setRenderedCards(newAllCards);
       } catch (error) {
         console.error('Error fetching affiliate cards:', error);
       }
     };
 
     fetchData();
   }, []);
  */



  useEffect(() => {
    const fetchAffiliateCards = async () => {
      const affiliatesCollectionRef = collection(db, 'affiliates');

      try {

        const userRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userRef);


        if (userDoc.exists()) {
          const thisUserData = userDoc.data();
          ////console.log(thisUserData);
          ////console.log("Shop, User data existed, crystals: " + thisUserData.gems);
          setCrystals(thisUserData.gems)
        }

        const querySnapshot = await getDocs(affiliatesCollectionRef);

        const affiliateCards = querySnapshot.docs.map((doc) => {
          const affiliateData = doc.data();
          return {
            type: 'affiliate',
            active: affiliateData.active,
            company: affiliateData.company,
            company_logo_url: affiliateData.company_logo_url,
            image_url: affiliateData.image_url,
            price: affiliateData.price,
            product_name: affiliateData.product_name,
            tracking_link: affiliateData.tracking_link,
            onClick: () => affiliateCardClick(affiliateData.tracking_link),
          };
        });

        //const allCards = addGemCard(affiliateCards);
//at the moment we don't display the gem card anymore


        setRenderedCards(affiliateCards); //use AllCards if you want to include the gem card
      } catch (error) {
        console.error('Error fetching affiliate cards:', error);
        return [];
      }
    };

    fetchAffiliateCards();
  }, []);


  return (
    <div className="page-container">
      <LogoBar crystals={crystals} />
      <MainNavbar />


      <strong>Kauppa</strong>
      <br /><br />
      <small>Toistaiseksi Slipper ei tarjoa tällä hetkellä sisäisiä ostoja, mutta mm premium-tilaus, yritysyhteistyöt jne on tulossa!</small>
      <br /><br />
      <small>Ota yhteyttä slippertuki@gmail.com mikäli sinulla on yhteistyöehdotuksia. Näin alkuvaiheessa pääsee vielä edullisesti mukaan rakentamaan menestystarinaa.</small>
      <ToastContainer />

      </div>
  );
};

export default Shop;

      {/* {showMaxGemsDialog && (
        <div className='chat-infotext-container'>
          <span>Sinulla on jo maksimimäärä kristalleja. Käytä niitä ensin pois ilmoittautumalla tapaamisiin.</span>
        </div>
      )} */}
{/* <small>Ostamalla kumppaneiltamme tuet Slipperin kehitystyötä ja autat pysymään maksuttomana jatkossakin, kiitos! </small>
<br /><br />


      
      <div >
        {renderedCards.map((card, index) => (
          <div key={index} className={card.type === 'gem' ? 'gem-card' : 'affiliate-card'} onClick={card.onClick}>
            {card.type === 'gem' ? (
              <div className="gem-card-container">

                <img src={gemImage} alt="Gem Card" className="gem-card-content" />

              </div>
            ) : (
              <>
                
                <div className="image-container">
                  <img src={card.image_url} alt="Card Image" className="image" />
                
                  {card.product_name && <strong className="product-name">{card.product_name}</strong>}
                </div>
                
              </>
            )}
          </div>
        ))}
      </div> */}



