import { resizeFile } from "react-image-file-resizer";
import Resizer from "react-image-file-resizer";
import { doc, getDoc, GeoPoint, updateDoc } from 'firebase/firestore';
import { ref, deleteObject } from 'firebase/storage';
import { db, storage } from "../config/firebase";


// Function to check if a user document exists
export const doesUserDocumentExist = async (userId) => {
  try {
    const userRef = doc(db, "users", userId);
    const userDoc = await getDoc(userRef);
    ////console.log("Userdoc found")
    return userDoc.exists();
  } catch (error) {
    console.error("Error checking user document:", error);
    return false; // Return false if there's an error
  }
};

export const isSocialMediaLink = (url) => {
  //console.log("UserAgent:", navigator.userAgent);
  //console.log("URL:", url);

  // More specific Facebook URL regex, tailored to your needs
  const facebookUrlRegex = /^(?:https?:\/\/)?(?:www\.)?facebook\.com\/(?:share|permalink|groups)\/.*$/i;

  // Improved platform-specific checks (adapt based on your findings)
  const isFacebook = navigator.userAgent.match(/FBAN|FBAV/i) || typeof FB_IAB !== 'undefined' || url.includes("fb_ref="); // Consider URL parameters as well

  // Consider additional checks or server-side validation if needed

  return facebookUrlRegex.test(url) && isFacebook;
};

export const formatTime = (dateTimeString) => {
  const options = {
    hour: '2-digit',
    minute: '2-digit',
  };

  const dateTime = new Date(dateTimeString);
  const formattedTime = new Intl.DateTimeFormat('fi-FI', options).format(dateTime);
  return formattedTime;
};

export const formatDate = (dateTimeString) => {
  const date = new Date(dateTimeString);
  const options = {
    day: 'numeric',
    month: 'numeric'
    /*  year: '2-digit' */
  };
  const formattedDate = date.toLocaleDateString('fi-FI', options);
  //return formattedDate;
  return formattedDate.replace(/\.$/, '');
};

export const formatWeekday = (dateTimeString) => {
  const date = new Date(dateTimeString);
  const options = {
    weekday: 'short',

  };
  const formattedDate = date.toLocaleDateString('fi-FI', options);
  return formattedDate;
};


/* export const calculateDistanceTo = (destination_geopoint, callback) => {
  if (!navigator.geolocation) {
    // Handle case where geolocation is not available
    if (callback) {
      callback(null);
    }
  } else {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        const distanceInKm = getDistance(
          { latitude, longitude },
          destination_geopoint
        );
        const formattedDistance = distanceInKm.toFixed(0);
        if (callback) {
          callback(formattedDistance);
        }
      },
      (error) => {
        console.error(error);
        if (callback) {
          callback(null);
        }
      }
    );
  }
}; */

/* export const calculateDistanceTo = (destination_geopoint) => {
  return new Promise((resolve, reject) => {
    if (!navigator.geolocation) {
      // Handle case where geolocation is not available
      reject("Geolocation not available");
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const distanceInKm = getDistance(
            { latitude, longitude },
            destination_geopoint
          );
          const formattedDistance = distanceInKm.toFixed(0);
          resolve(formattedDistance);
        },
        (error) => {
          console.error(error);
          reject(error);
          
        }
      );
    }
  });
}; */

export const calculateDistanceTo = async (destination_geopoint) => {
  return new Promise(async (resolve, reject) => {
    if (!navigator.geolocation) {
      // Handle case where geolocation is not available
      reject("Geolocation not available");
    } else {
      try {
        const position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });

        const { latitude, longitude } = position.coords;
        const distanceInKm = getDistance({ latitude, longitude }, destination_geopoint);
        const formattedDistance = distanceInKm.toFixed(0);

        // Update current user's document with last_location (if successful)
        
       /*  const userRef = doc(db, 'users', currentUserId); // Assuming user ID is available
        const userDoc = await getDoc(userRef);
        if (!userDoc.exists || !userDoc.data().last_location) {
          await updateDoc(userRef, { last_location: new GeoPoint(latitude, longitude) });
        } */

        resolve(formattedDistance);
      } catch (error) {
        console.error(error);
        reject(error);
      }
    }
  });
};


const getDistance = (start, end) => {
  const earthRadius = 6371; // Radius of the earth in km
  const latDiff = degreesToRadians(end.latitude - start.latitude);
  const lonDiff = degreesToRadians(end.longitude - start.longitude);

  const a =
    Math.sin(latDiff / 2) * Math.sin(latDiff / 2) +
    Math.cos(degreesToRadians(start.latitude)) *
    Math.cos(degreesToRadians(end.latitude)) *
    Math.sin(lonDiff / 2) *
    Math.sin(lonDiff / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = earthRadius * c;

  return distance;
};

const degreesToRadians = (degrees) => {
  return (degrees * Math.PI) / 180;
};

/* export const calculateAge = (birthday) => {
  const today = new Date();

  const birthDate = new Date(birthday);
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();
  if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  //////console.log("Utils, calc age is:" + age)
  return age;
}; */

/* export const calculateAge = (birthdayString) => {
  const today = new Date();
  let birthDate;

  if (birthdayString.includes("-")) { // yyyy-mm-dd format
    const [year, month, day] = birthdayString.split("-").map(Number);
    birthDate = new Date(year, month - 1, day);
  } else if (birthdayString.includes(".")) { // dd.mm.yyyy format
    const [day, month, year] = birthdayString.split(".").map(Number);
    birthDate = new Date(year, month - 1, day);
  } else {
    throw new Error("Virheellinen päivämäärämuoto. Käytä joko pp.kk.vvvv tai vvvv-kk-pp.");
  }

  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();
  if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
};
 */

export const calculateAge = (birthdayString) => {
  try {
    if (!birthdayString || typeof birthdayString !== 'string') {
      console.error("Invalid birthday input:", birthdayString);
      return "0"; // Return "0" if the birthday input is invalid
    }

    const today = new Date();
    let birthDate;

    if (birthdayString.includes("-")) { // yyyy-mm-dd format
      const [year, month, day] = birthdayString.split("-").map(Number);
      birthDate = new Date(year, month - 1, day);
    } else if (birthdayString.includes(".")) { // dd.mm.yyyy format
      const [day, month, year] = birthdayString.split(".").map(Number);
      birthDate = new Date(year, month - 1, day);
    } else {
      console.error("Invalid date format for birthday:", birthdayString);
      return "0"; // Return "0" if the date format is invalid
    }

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age >= 0 ? age : "0"; // Return "0" if calculated age is negative
  } catch (error) {
    console.error("Error calculating age for birthday:", birthdayString, error);
    return "0"; // Return "0" in case of any error during calculation
  }
};



export const convertToThumbnailURL = (originalURL) => {
  // Split the original URL into parts
  const parts = originalURL.split("?");

  // Check if the URL contains a query string
  if (parts.length === 2) {
    const [baseURL, queryString] = parts;

    // Extract the path from the base URL
    const path = baseURL.split("?alt=media")[0];

    // Extract the filename from the path
    const pathParts = path.split("/");
    const filename = pathParts[pathParts.length - 1];

    // Extract the file extension
    const [name, extension] = filename.split(".");

    // Create the thumbnail filename by appending "_256x256"
    const thumbnailFilename = `${name}_256x256.${extension}`;

    // Create the thumbnail URL with the modified filename
    const thumbnailURL = `${path.replace(filename, thumbnailFilename)}?alt=media&${queryString}`;
    

    return thumbnailURL;
  }

  // Return the original URL if it doesn't have a query string
  return originalURL;
}



export const resizeImage = (originalFile, maxWidth, maxHeight) => {
  return new Promise((resolve, reject) => {
    const blob = new Blob([originalFile]);
    const img = new Image();
    img.src = URL.createObjectURL(blob);

    img.onload = () => {
      // Rest of your resizing logic remains the same
      // ...

      // Create a File object with the same name in the "thumbnails" folder
      const resizedFile = new File([blob], originalFile.name, {
        type: "image/jpeg",
      });

      resolve(resizedFile);
    };

    img.onerror = (error) => {
      reject(error);
    };
  });
};


/* Resizer.imageFileResizer(
  file, // Is the file of the image which will resized.
  maxWidth, // Is the maxWidth of the resized new image.
  maxHeight, // Is the maxHeight of the resized new image.
  compressFormat, // Is the compressFormat of the resized new image.
  quality, // Is the quality of the resized new image.
  rotation, // Is the degree of clockwise rotation to apply to uploaded image.
  responseUriFunc, // Is the callBack function of the resized new image URI.
  outputType, // Is the output type of the resized new image.
  minWidth, // Is the minWidth of the resized new image.
  minHeight // Is the minHeight of the resized new image.
); */

export const resizeThumbPic = (file) =>


  new Promise((resolve) => {

    try {
      Resizer.imageFileResizer(
        file,
        200,
        200,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file",
      );
    } catch (err) {
      ////console.log("Utils, error in resizing profile pic: " + err);
    }
  });

  export const resizeLargePic = (file) =>

  new Promise((resolve) => {

    try {
      Resizer.imageFileResizer(
        file,
        1024,
        1024,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file",
      );
    } catch (err) {
      ////console.log("Utils, error in resizing profile pic: " + err);
    }
  });

export const resizeMeetPic = (file) =>
  new Promise((resolve) => {
    try {
      Resizer.imageFileResizer(
        file,
        512,
        512,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file",
      );
    } catch (err) {
      ////console.log("Utils, error in resizing profile pic: " + err);
    }
  });

  export const resizeLargeMeetPic = (file) =>
  new Promise((resolve) => {
    try {
      Resizer.imageFileResizer(
        file,
        1024,
        1024,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file",
      );
    } catch (err) {
      ////console.log("Utils, error in resizing profile pic: " + err);
    }
  });

  // utils.js

export const withLoading = async (asyncFunction, setLoading) => {
  try {
    setLoading(true); // Set loading to true before the async function
    // Display loading image or perform any loading UI logic
    // For example, you can show an animated loading GIF
    const loadingImage = document.createElement('img');
    loadingImage.src = 'path/to/loading.gif';
    document.body.appendChild(loadingImage);

    await asyncFunction(); // Execute the async function
  } catch (error) {
    console.error(error);
  } finally {
    setLoading(false); // Set loading to false after the async function completes
    // Remove the loading image or perform any cleanup
    const loadingImage = document.querySelector('img[src="path/to/loading.gif"]');
    if (loadingImage) {
      loadingImage.remove();
    }
  }
};

export const deleteFileFromUrl = async (fileUrl) => {
  try {
    const fileRef = ref(storage, fileUrl);
    await deleteObject(fileRef);
    ////console.log(`File deleted successfully: ${fileUrl}`);
  } catch (error) {
    console.error(`Error deleting file ${fileUrl}:`, error);
    throw error; // Re-throw the error to handle it in the calling function if needed
  }
};