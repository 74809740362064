import React, { useState, useEffect } from 'react';
import { calculateAge } from '../config/utils';
import { query, where, doc, getDoc, getDocs, collection } from 'firebase/firestore';  // Import necessary Firestore functions
import { db } from "../config/firebase";

const MeetAvatar = ({ imageUrl, ownerUid, name, birthday, onClick }) => {
  const [imageError, setImageError] = useState(false);
  const [currentUserProfileImageUrl, setCurrentUserProfileImageUrl] = useState('');


 

  useEffect(() => {
    // Retrieve the current user's profile image if an error occurs with the meet owner's image
    //console.log("MeetAvatar, useEffect")
    if (imageError && ownerUid) {
      //console.log("MeetAvatar, userEffect with error and ownerUid")
      const fetchCurrentUserProfileImageUrl = async () => {
        try {
          const userRef = doc(db, "users", ownerUid);
          const userDoc = await getDoc(userRef);
          //console.log(userDoc)
          if (userDoc.exists()) {
            //console.log("MeetAvatar, userDoc found")
            const userData = userDoc.data();

            if (userData.thumbUrl) {
              //console.log(userData.thumbUrl)
              setCurrentUserProfileImageUrl(userData.thumbUrl);
            } else {
              setCurrentUserProfileImageUrl('https://www.testhouse.net/wp-content/uploads/2021/11/default-avatar.jpg');
            }
          } else {
            setCurrentUserProfileImageUrl('https://www.testhouse.net/wp-content/uploads/2021/11/default-avatar.jpg');
          }
        } catch (error) {
          console.error('Error fetching user image:', error);
          setCurrentUserProfileImageUrl('https://www.testhouse.net/wp-content/uploads/2021/11/default-avatar.jpg');
        }
      };

      fetchCurrentUserProfileImageUrl();
    }
  }, [imageError, ownerUid]);


  const handleImageError = () => {
    // Handle the image error (e.g., log the error, show a placeholder image)
    //console.log("image error")
    setImageError(true);
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', cursor: 'pointer' }} onClick={onClick}>
      {imageError ? (
        // Render the current user's profile image as a fallback
        <img src={currentUserProfileImageUrl} alt="Fallback Image" style={{ width: '80px', height: '80px', borderRadius: '50%', objectFit: 'cover', marginTop: '15px' }} />
      ) : (
        // Render the meet owner's image
        <img src={imageUrl} alt="Meet Owner's Image" style={{ width: '80px', height: '80px', borderRadius: '50%', objectFit: 'cover', marginTop: '15px', objectPosition: 'top' }} onError={handleImageError} />
      )}
      <div style={{ display: 'flex', alignItems: 'center', fontSize: '1.0rem' }}>
        <strong>{name},</strong>
        <strong style={{ marginLeft: '5px' }}>{calculateAge(birthday)}</strong>
      </div>
    </div>
  );
};



export default MeetAvatar;


/* import React from 'react';
import { UserAuth } from '../context/AuthContext';
import { calculateAge } from '../config/utils';


const MeetAvatar = ({ imageUrl, name, birthday, onClick }) => {
  const avatarStyle = {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    cursor: 'pointer',

  };

  const imageStyle = {
    width: '80px',
    height: '80px',
    borderRadius: '50%',
    objectFit: 'cover',
     marginTop: '15px',  
  };

  const textStyle = {
    display: 'flex',
    alignItems: 'center', 
    fontSize: '1.0rem',

  };

  return (
    <div style={avatarStyle} onClick={onClick}>
      <img src={imageUrl} alt="Avatar" style={imageStyle} />
      <div style={textStyle}>
        <strong>{name},</strong>
        <strong style={{ marginLeft: '5px' }}>{calculateAge(birthday)}</strong>
      </div>
    </div>
  );
};



export default MeetAvatar; */



