import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IconChevronLeft } from '@tabler/icons-react';
import slipperLogo from '../assets/SlipperIcon.png';

const Contact = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate('/account');

  };

  return (
    <div className="page-container">
      <nav style={{ display: 'flex', paddingRight: '10px' }}>
        <ul>
          <li>
            <a href="#" onClick={goBack}>
              <IconChevronLeft size={35} />
            </a>
          </li>
          <li>
            <strong>Ota yhteyttä</strong>
          </li>
        </ul>
      </nav>
     
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
       <img src={slipperLogo} alt="Slipper Logo" style={{ height: '100px' , width: 'auto'}} />
        <br />
        <p>
          Haluatko jutella jostakin? Ota yhteyttä sähköpostitse, niin lupaamme ottaa sinuun yhteyttä heti, kun voimme. Voit ottaa yhteyttä osoitteeseen{' '}
          <a href="mailto:slippertuki@gmail.com">slippertuki@gmail.com</a>.
        </p>
      </div>

    
      <ul>
        <li>Ohjeet ja tuki</li>
        <li>Kumppanuudet ja yhteistyöt</li>
        <li>Kehitysehdotukset</li>
      </ul>
      </div>
      );
     
};

      export default Contact;