import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { UserAuth } from '../context/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import { query, setDoc, collection, doc, getDoc, getDocs, updateDoc, where } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL, listAll, deleteObject } from 'firebase/storage';
import { db, storage } from '../config/firebase';
import { GeoPoint } from "firebase/firestore";
import MainNavbar from '../components/MainNavbar';
import { resizeMeetPic, resizeLargeMeetPic } from '../config/utils';
import LogoBar from '../components/LogoBar';
import locationsJson from '../assets/locations.json';
import { saveAction } from "../context/ActionService";



const MyMeetEditor = () => {
  const { user } = UserAuth();

  const { register, handleSubmit, setValue, formState: { errors } } = useForm();
  
  const navigate = useNavigate();
  const usersCollectionRef = collection(db, "users");
  const [locations, setLocations] = useState([]);

  const [meetImage, setMeetImage] = useState(null);
  const [existingMeetImageUrl, setExistingMeetImageUrl] = useState(null);
  const [existingMeetThumbUrl, setExistingMeetThumbUrl] = useState(null);
  const [userProfileExists, setUserProfileExists] = useState(false);

  const [userName, setUserName] = useState(null);
  const [userId, setUserId] = useState(null);

  const [userGender, setUserGender] = useState(null);
  const [userPicURL, setUserPicURL] = useState(null);
  const [userBirthday, setUserBirthday] = useState(null);
  const [uploadError, setUploadError] = useState(null);
  const [missingImageError, setMissingImageError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [crystals, setCrystals] = useState(0);
  const [meetData, setMeetData] = useState(null);
  const fileUploadStyle = {
    display: 'none', // Hide the default input button
  };
  const customButtonStyle = {
    backgroundColor: 'goldenrod', // Golden color, you can change this
    color: 'black',
    padding: '10px 15px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    textAlign: 'center', // Align text to the center
    display: 'inline-block', // Make the button an inline-block element
    width: 'auto', // Allow the button to adjust its width based on content
  };

  const activityOptions = [
    "Kävelylle",
    "Pyörälenkille",
    "Hiihtämään",
    "Uimaan",
    "Muuta liikuntaa",
    "Kahvilaan",
    "Kauppaan tai shoppailemaan",
    "Autoajelulle",
    "Moottoripyöräajelulle",
    "Eväsretkelle",
    "Drinkille",
    "Verkostoitumista",
    "Leikkitreffit lasten kanssa",
    "Syömään ravintolaan",
    "Kulttuurielämys",
    "Luontoretki",
    "Nuotiolle",
    "Elokuvateatteriin",
    "Tehdään ruokaa yhdessä",
    "Leffailta/musailta kotona",
    "Saunomaan",
    "Tarvitsen apua",
    "Jotain Muuta",
    "Ehdota sinä mitä tehdään, valitsen parhaan",
  ];


  const { meetId } = useParams(); // Meet ID parameter from the URL

  const fetchMeetData = async (meetId) => {
    try {
      const meetRef = doc(db, "meets", meetId);
      const meetDoc = await getDoc(meetRef);

      if (meetDoc.exists()) {
        const meetDocData = meetDoc.data();

        setMeetData(meetDocData);
        setExistingMeetImageUrl(meetDocData.meet_pic_url)
        setExistingMeetThumbUrl(meetDocData.meet_thumb_url)
        // ... (populate state variables with existing meet data)
        // Populate form fields using react-hook-form's setValue
        Object.keys(meetDocData).forEach((fieldName) => {
          setValue(fieldName, meetDocData[fieldName]);
        });
      } else {
        // Handle the case when the meet with the given ID doesn't exist
      }
    } catch (error) {
      //console.log("fetchMeetData, Error getting meet data:", error);
    }
  };

  useEffect(() => {
    //console.log("MyMeetEditor useEffect1")
    // If meetId is present, fetch the existing meet data for editing
    if (meetId) {
      fetchMeetData(meetId);
    } else {
      // Handle the case for adding a new meet
      // ... (initialize state variables or perform any specific logic for adding new meet)
    }
  }, [meetId]);

  useEffect(() => {
    //console.log("MyMeetEditor useEffect2")
    const fetchUserData = async () => {
      if (user) {
        try {
          ////console.log("fetchUserData, Trying to fetch user data for user " + user.uid);
          const querySnapshot = await getDocs(
            query(usersCollectionRef, where("uid", "==", user.uid))
          );

          if (querySnapshot.size > 0) {
            setUserProfileExists(true);
            const docSnap = querySnapshot.docs[0];
            const userData = docSnap.data();

            if (userData && typeof userData === "object") {
              ////console.log("Name:", userData.name); // Print the value of the "name" field
              setUserName(userData.name);
              setUserBirthday(userData.birthday); // Set the "birthday" state variable
              setUserGender(userData.gender); // Set the "gender" state variable
              setUserPicURL(userData.thumbUrl);
              setUserId(user.uid);
              //setCrystals(userData.gems)

              // ... Rest of the code
            } else {
              ////console.log("fetchUserData, Invalid user data:", userData);
            }
          } else {
            ////console.log("fetchUserData, No such document when trying to fetch user data!");
          }
        } catch (error) {
          ////console.log("fetchUserData, Error getting user data:", error);
        }
      }
    };



    if (user) {
      fetchUserData();
      setLocations(locationsJson);
      //fetchLocations();
    }
  }, [user]);

  const deleteOldMeetImages = async () => {
    try {
      if (existingMeetImageUrl && existingMeetThumbUrl) {
        const oldImageRef = ref(storage, existingMeetImageUrl);
        const oldThumbImageRef = ref(storage, existingMeetThumbUrl);

        await deleteObject(oldImageRef);
        await deleteObject(oldThumbImageRef);

        //console.log('Old meet image deleted successfully.');
        setExistingMeetImageUrl(null);
        setExistingMeetThumbUrl(null);
      }
    } catch (error) {
      console.error('Error deleting old meet image:', error);
      // Handle the error as needed
    }
  };


  const handlePicChange = (e) => {
    const picFile = e.target.files[0];
    setMeetImage(picFile);

    deleteOldMeetImages();
  };


  const onSubmit = async (data) => {
    // If meetId is present, update the existing meet
    if (meetId) {
      updateExistingMeet(meetId, data);
    } else {
      // Handle the case for adding a new meet
      addNewMeet(data);
    }
  };


  const updateExistingMeet = async (meetId, newData) => {
    let downloadURL;
    let downloadThumbURL;

    if (meetImage) {
      setIsLoading(true);

      ////console.log("Meet image existed: " + meetImage)
      let meetThumbnailPic;
      let meetLargeResizedPic;
      try {
        meetThumbnailPic = await resizeMeetPic(meetImage);
        meetLargeResizedPic = await resizeLargeMeetPic(meetImage);
        ////console.log("Resized Image:", meetThumbnailPic);
      } catch (error) {
        console.error("Error resizing image:", error);
      }

      try {
        const storageRef = ref(storage, `meet_images/${userId}/${meetImage.name}`);
        const uploadTask = uploadBytesResumable(storageRef, meetLargeResizedPic);

        const storageThumbRef = ref(storage, `meet_images/thumbnails/${userId}/${meetImage.name}`);
        const uploadThumbTask = uploadBytesResumable(storageThumbRef, meetThumbnailPic);

        await uploadTask;
        await uploadThumbTask;

        downloadURL = await getDownloadURL(storageRef);
        downloadThumbURL = await getDownloadURL(storageThumbRef);

        // Include the new download URLs in the Meet data
        newData.meet_pic_url = downloadURL;
        newData.meet_thumb_url = downloadThumbURL;

      } catch (error) {
        // Handle the error as needed
        console.error('Error uploading image:', error);
        setIsLoading(false);
        return;
      }
    }

    try {
      // Update the existing Meet document with the new data
      newData.description = document.getElementById('description').value;
      const meetDocRef = doc(db, 'meets', meetId);
      await updateDoc(meetDocRef, newData);

      // Redirect user to the MyMeet page
      setIsLoading(false);
      navigate('/meets');
    } catch (error) {
      // Handle error updating meet
      console.error('Error updating meet:', error);
      setIsLoading(false);
    }
  };

  /*   const validationSchema = yup.object().shape({
      futureDate: yup.date()
        .required('Date is required')
        .min(new Date(), 'Date cannot be in the past') // Validates that the date is not in the past
    }); */

  const validateMeetDate = (value) => {
    if (!value) {
      //console.log("Pakollinen aika")
      return 'Ajankohta on pakollinen';
    }
    const now = new Date();
    const selectedDate = new Date(value);
    if (selectedDate <= now) {
      //console.log("Mennyt aika")
      return 'Ajankohdan on oltava tulevaisuudessa';
    }

    // Check if selected date is before current date (past)
    return undefined;
  };


  const addNewMeet = async (data) => {
    let downloadURL;
    let downloadThumbURL;
  
    if (meetImage) {
      setIsLoading(true);
  
      let meetThumbnailPic;
      let meetLargeResizedPic;
      try {
        meetThumbnailPic = await resizeMeetPic(meetImage);
        meetLargeResizedPic = await resizeMeetPic(meetImage);
      } catch (error) {
        console.error("Error resizing image:", error);
      }
  
      const storageRef = ref(storage, `meet_images/${userId}/${meetImage.name}`);
      const uploadTask = uploadBytesResumable(storageRef, meetLargeResizedPic);
  
      const storageThumbRef = ref(storage, `meet_images/thumbnails/${userId}/${meetImage.name}`);
      const uploadThumbTask = uploadBytesResumable(storageThumbRef, meetThumbnailPic);
  
      try {
        await uploadTask;
        await uploadThumbTask;
        downloadURL = await getDownloadURL(storageRef);
        downloadThumbURL = await getDownloadURL(storageThumbRef);
      } catch (error) {
        console.error('Error uploading image:', error);
      }
  
      try {
        const geoLocationOfSelected = locations.find(location => location.name === data.location);
        const { latitude, longitude } = geoLocationOfSelected;
        const geopoint = new GeoPoint(latitude, longitude);
        const generatedMeetUID = `${userId}_${Date.now()}`;
        const description = document.getElementById('description').value;
  
        // Fetch current user's document to get liked_by_uids
        const currentUserRef = doc(collection(db, "users"), user.uid);
        const currentUserSnapshot = await getDoc(currentUserRef);
  
        if (currentUserSnapshot.exists()) {
          const currentUserData = currentUserSnapshot.data();
          const likedByUsers = currentUserData?.liked_by_uids || [];
          const userFavs = currentUserData?.favs_uids || []; // Get the current user's favorites
  
          // Loop through liked_by_uids and send notifications
          for (const likedUserId of likedByUsers) {
            if (!data.only_favs || (data.only_favs && userFavs.includes(likedUserId))) {
              // Send notification if the meet is public or if the receiving user is in the sender's favorites for private meets
              const notificationText = `Suosikkisi ${userName} loi uuden tapaamisen. Haluaisitko tavata hänet?`;
              await saveAction(user.uid, likedUserId, 'favourites_meet', notificationText);
            }
          }
        }
  
        // Prepare newMeetData with all necessary fields
        const newMeetData = {
          ...data, // Includes 'only_favs', and any other form data
          description,
          uid: generatedMeetUID,
          interested_users: [],
          geolocation: geopoint,
          owner_user: userId,
          owner_user_name: userName,
          owner_user_birthday: userBirthday,
          owner_user_gender: userGender,
          meet_pic_url: downloadURL,
          meet_thumb_url: downloadThumbURL,
          owner_pic_url: userPicURL,
          down_votes: 0,
          closed: false,
          archived: false
        };
  
        const meetDocRef = doc(db, 'meets', generatedMeetUID);
        await setDoc(meetDocRef, newMeetData);
  
        // Redirect user to the MyMeet page
        setIsLoading(false);
        navigate('/meets');
      } catch (error) {
        console.error('Error creating meet:', error);
        setIsLoading(false); // Ensure loading spinner stops on error
      }
    } else {
      // Meet image is null
      setMissingImageError("Kuva on pakollinen");
    }
  };
  

  //Submit
 /*  const addNewMeet = async (data) => {
    let downloadURL;
    let downloadThumbURL;

    if (meetImage) {

      setIsLoading(true);
      ////console.log("Meet image existed: " + meetImage)
      //let meetThumbnailPic;
      let meetThumbnailPic;
      let meetLargeResizedPic;
      try {
        meetThumbnailPic = await resizeMeetPic(meetImage);
        meetLargeResizedPic = await resizeMeetPic(meetImage);
        ////console.log("Resized Image:", meetThumbnailPic);
      } catch (error) {
        console.error("Error resizing image:", error);
      }

      const storageRef = ref(storage, `meet_images/${userId}/${meetImage.name}`);
      const uploadTask = uploadBytesResumable(storageRef, meetLargeResizedPic);

      const storageThumbRef = ref(storage, `meet_images/thumbnails/${userId}/${meetImage.name}`);
      const uploadThumbTask = uploadBytesResumable(storageThumbRef, meetThumbnailPic);

      try {
        await uploadTask;
        await uploadThumbTask;
        ////console.log("Await uploadtasks done")
        downloadURL = await getDownloadURL(storageRef);
        downloadThumbURL = await getDownloadURL(storageThumbRef);
        ////console.log("Download thumb url is " + downloadThumbURL)
        ////console.log("Download url done" + downloadURL)
        // Include the download URL in the Meet data

      } catch (error) {
        ////console.log('Error uploading image:', error);
        // Handle the error as needed
      }

      try {
        //const meetCollectionRef = collection(db, 'meets');
        ////console.log('onSubmit: Selected location:', data.location);
        const geoLocationOfSelected = locations.find(location => location.name === data.location);
        ////console.log("Geolocation of selected: " + geoLocationOfSelected)
        // Retrieve latitude and longitude values from the selected location
        const { latitude, longitude } = geoLocationOfSelected;
        // Create a geopoint object using the latitude and longitude values
        const geopoint = new GeoPoint(latitude, longitude);
        const generatedMeetUID = `${userId}_${Date.now()}`;
        const description = document.getElementById('description').value;

        const newMeetData = {
          ...data,
          description,
          uid: generatedMeetUID,
          interested_users: [], // Add the required fields here
          geolocation: geopoint, // Add the required fields here
          owner_user: userId, // Add the required fields here
          owner_user_name: userName,
          owner_user_birthday: userBirthday,
          owner_user_gender: userGender,
          meet_pic_url: downloadURL,
          meet_thumb_url: downloadThumbURL, 
          owner_pic_url: userPicURL,
          down_votes: 0, //users can vote down a meet if it has inappropriate content    
          closed: false, //Meet is closed when it is either removed by the owner or companion is chosen, closed meet is displayed to owner only, perhaps chosen applicant
          archived: false

        };


        const meetDocRef = doc(db, 'meets', generatedMeetUID);
        await setDoc(meetDocRef, newMeetData);


        // Fetch current user's document to get liked_by_uids
      const currentUserRef = doc(collection(db, "users"), user.uid);
      const currentUserSnapshot = await getDoc(currentUserRef);

      if (currentUserSnapshot.exists()) {
        const currentUserData = currentUserSnapshot.data();
        const likedByUsers = currentUserData?.liked_by_uids || [];
        const userName = currentUserData?.name || '';
       
        // Loop through liked_by_uids and send notifications
        for (const likedUserId of likedByUsers) {
          const notificationText = `Suosikkisi ${userName} loi uuden tapaamisen. Haluaisitko tavata hänet?`;
          await saveAction(user.uid, likedUserId, 'favourites_meet', notificationText);
        }
      }
        // Redirect user to the MyMeet page
        setIsLoading(false);
        navigate('/meets');
      } catch (error) {
        ////console.log('onSubmit: Error creating meet:', error);
      }
    } else {
      //meetImage is null
      ////console.log("Missing image")
      setMissingImageError("Kuva on pakollinen")
    }
  };
 */

  return (
    <div className="page-container">
      <LogoBar crystals={crystals} />

      <MainNavbar />
      {/* {isLoading && (
        <div className="loading-screen">
          <progress></progress>
        </div>
      )} */}

{isLoading && <div className="loading">Tallennetaan...</div>}

      <strong>
        {meetData ? "Muokkaa deitti-ilmoitusta" : "Luo uusi deitti-ilmoitus"}
      </strong>

      {userProfileExists ? (
        <div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <br />

        <label htmlFor="meet_type">Aktiviteetti</label>
        <select {...register('meet_type')} id="meet_type" name="meet_type">
          {activityOptions.map(option => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}

        </select>
        {errors.meet_type && <small className="error">{errors.meet_type.message}</small>}

        <label htmlFor="meet_gender">Tämä ilmoitus on</label>
        <select {...register('meet_gender')} id="meet_gender" name="meet_gender">
          {/* Add meet type options */}
          <option value="Naisille">Naisille</option>
          <option value="Miehille">Miehille</option>
          <option value="Kaikille">Kaikille</option>
          <option value="Pareille">Pareille</option>
        </select>
        {errors.meet_gender && <small className="error">{errors.meet_gender.message}</small>}

        <br /><br />
        {/*             <div style={{ textAlign: 'center' }}>
              <label htmlFor="file" style={customButtonStyle}>Lataa ilmoitukselle kuva</label>
            </div>
            <div>

              <input
                type="file"
                id="file"
                name="file"
                onChange={handlePicChange}
                accept="image/*"
                style={fileUploadStyle}
              />

              {meetImage && (
                <div>
                  <img src={URL.createObjectURL(meetImage)} alt="Selected Image" />
                </div>
              )}
             
              {uploadError && (
                <div style={{ color: 'red' }}>
                  {uploadError}
                  <br /><br />
                </div>
              )}

              {missingImageError && (
                <div>


                  <small className="error">{missingImageError}</small>

                  <br /><br />
                </div>
              )}

            </div> */}

        <div style={{ textAlign: 'center' }}>
          <label htmlFor="file" style={customButtonStyle}>
            Lataa ilmoitukselle kuva
          </label>
        </div>
        <div>
          <input
            type="file"
            id="file"
            name="file"
            onChange={handlePicChange}
            accept="image/*"
            style={fileUploadStyle}
          />

          {(meetData && existingMeetImageUrl) && (
            <div>

              <img
                src={existingMeetImageUrl}
                alt="Existing Image"

              />
              {/*  <button onClick={() => handleRemoveExistingImage()}>Remove Existing Image</button> */}
            </div>
          )}

          {meetImage && (
            <div>
              {/* <label>New Image</label> */}
              <img
                src={URL.createObjectURL(meetImage)}
                alt="Selected Image"

              />
            </div>
          )}

          {/* Display error message for image size */}
          {uploadError && (
            <div style={{ color: 'red' }}>
              {uploadError}
              <br /><br />
            </div>
          )}

          {missingImageError && (
            <div>
              <small className="error">{missingImageError}</small>
              <br /><br />
            </div>
          )}
        </div>

        <br />
        <small className="info">
          Voit ladata ilmoitukseesi vain yhden kuvan. Se voi esittää itseäsi tai liittyä valitsemaasi aktiviteettiin.
        </small>
        <br /><br />
        <div style={{ color: 'orange' }}>
        Huomaa, että vaikka ilmoituksesi näkyisikin vain suosikeille, kuva silti tallennetaan osaksi julkista profiiliasi. Ilmoituksen voi poistaa milloin vain, jolloin kuva katoaa profiilistakin.
            </div>
       
        <br /><br />

        <label htmlFor="description">Lisätiedot (500 merkkiä)</label>

        <textarea
          id="description"
          {...register("description")}
          rows={5}
          cols={50}
          maxLength="500"
          name="description"
        />

        <label htmlFor="meet_date">Valitse aika:</label>
              <input
              type="datetime-local"
              id="meet_date"
              name="meet_date"
              {...register("meet_date", {
                
                required: "Aika on pakollinen valinta", 
                validate: validateMeetDate,
                
                
              })}
            /> 
           {errors.meet_date && (
              <small className="error">{errors.meet_date.message}</small>
            )}
       

        <label htmlFor="location">Valitse sijainti:</label>
        {/*         <select
          id="location"
          {...register('location', {
            required: 'Sijainti on pakollinen valinta',
          })}
          value={meetData.location}

        >
          <option value="" disabled>
            Valitse paikkakunta
          </option>
          {locations.map((location) => (
            <option key={location.name} value={location.name}>
              {location.name}
            </option>
          ))}
        </select> */}
        <select
          id="location"
          {...register('location', {
            required: 'Sijainti on pakollinen valinta',
          })}
          name="location"
        >
          <option value="" disabled>
            Valitse paikkakunta
          </option>
          {locations.map((location) => (
            <option key={location.name} value={location.name}>
              {location.name}
            </option>
          ))}
        </select>

        {errors.location && (
          <small className="error">{errors.location.message}</small>
        )}

        <small className="info">Tarkempi sijainti kannattaa turvallisuussyistä sopia vasta viestitse</small>

        <label for="only_friends">
          <input
            type="checkbox"
            id="only_friends"
            name="only_friends"
            {...register("only_friends", { defaultValue: false })} // Set default value to false
          />
          Kaveritapaaminen

        </label>
        <small className="info">Tapaaminen vain kavereina, ei romanttisessa mielessä</small>
        <br /><br />
        <label for="only_favs">
          <input
            type="checkbox"
            id="only_favs"
            name="only_favs"
            {...register("only_favs", { defaultValue: false })} // Set default value to false
          />
          Näytä tapaaminen vain omille suosikeille

        </label>
        <small className="info">Tapaaminen ei näy julkisesti kaikille. Myöskään käyttäjät joiden suosikki sinä olet, eivät näe tapaamista.</small>
        <br /><br />
      </form>

      {/*       <strong>Luo uusi deitti-ilmoitus</strong>
          <form onSubmit={handleSubmit(onSubmit)}>
            <br />

            <label htmlFor="meet_type">Aktiviteetti</label>
            <select {...register('meet_type')} id="meet_type" >
              {activityOptions.map(option => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            {errors.meet_type && <small className="error">{errors.meet_type.message}</small>}

            <label htmlFor="meet_gender">Tämä ilmoitus on</label>
            <select {...register('meet_gender')} id="meet_gender" >
              
              <option value="Naisille">Naisille</option>
              <option value="Miehille">Miehille</option>
              <option value="Kaikille">Kaikille</option>
              <option value="Pareille">Pareille</option>
            </select>
            {errors.meet_gender && <small className="error">{errors.meet_gender.message}</small>}

            <br /><br />
            <div style={{ textAlign: 'center' }}>
              <label htmlFor="file" style={customButtonStyle}>Lataa ilmoitukselle kuva</label>
            </div>
            <div>

              <input
                type="file"
                id="file"
                name="file"
                onChange={handlePicChange}
                accept="image/*"
                style={fileUploadStyle}
              />

              {meetImage && (
                <div>
                  <img src={URL.createObjectURL(meetImage)} alt="Selected Image" />
                </div>
              )}
      
              {uploadError && (
                <div style={{ color: 'red' }}>
                  {uploadError}
                  <br /><br />
                </div>
              )}

              {missingImageError && (
                <div>


                  <small className="error">{missingImageError}</small>

                  <br /><br />
                </div>
              )}

            </div>
            <br /><br />
            <small className="error">
              Voit ladata ilmoitukseesi vain yhden kuvan. Se voi esittää itseäsi tai liittyä valitsemaasi aktiviteettiin.
            </small>
            <br /><br />

            <label htmlFor="description">Lisätiedot (500 merkkiä)</label>

            <textarea
              id="description"
              {...register("description")}
              rows={5}
              cols={50}
              maxLength="500"
            />

            <label htmlFor="meetDateTime">Valitse aika:</label>
            <input
              type="datetime-local"
              id="meetDateTime"
              {...register("meet_date", {
                required: "Aika on pakollinen valinta",
              })}
            />
            {errors.meet_date && (
              <small className="error">{errors.meet_date.message}</small>
            )}


            <label htmlFor="location">Valitse sijainti:</label>
            <select
              id="location"
              {...register('location', {
                required: 'Sijainti on pakollinen valinta',
              })}
              defaultValue=""

            >
              <option value="" disabled>
                Valitse paikkakunta
              </option>
              {locations.map((location) => (
                <option key={location.name} value={location.name}>
                  {location.name}
                </option>
              ))}
            </select>
            {errors.location && (
              <small className="error">{errors.location.message}</small>
            )}

            <small style="color: #D3D3D3;">Tarkempi sijainti kannattaa turvallisuussyistä sopia vasta viestitse</small>

            <label for="only_friends">
              <input
                type="checkbox"
                id="only_friends"
                name="only_friends"
                {...register("only_friends", { defaultValue: false })} // Set default value to false
              />
              Kaveritapaaminen

            </label>
            <small style="color: #D3D3D3;">Tapaaminen vain kavereina, ei romanttisessa mielessä</small>
            <br /><br />

            <label for="only_favs">
              <input
                type="checkbox"
                id="only_favs"
                name="only_favs"
                {...register("only_favs", { defaultValue: false })} // Set default value to false
              />
              Näytä tapaaminen vain omille suosikeille

            </label>

          </form> */}




   {/*    <div style={{ textAlign: 'center' }}>
        <a href="#" onClick={handleSubmit(onSubmit)} role="button">Tallenna</a>
        <br /><br />
      </div> */}


{/*       <div style={{ textAlign: 'center' }}>
  <button type="submit" disabled={isLoading} onClick={handleSubmit(onSubmit)}>Tallenna</button>
  <br /><br />
</div> */}

<div style={{ textAlign: 'center' }}>
  <button type="submit" 
  disabled={isLoading} 
  onClick={(event) => {
    if (isLoading) {
      event.preventDefault();
      return;
    }
    handleSubmit(onSubmit)(event);
  }}
>
  {isLoading ? 'Ladataan kuvaa. Odota sulkematta sivua!' : 'Tallenna'}
  </button>
  <br /><br />
</div>



      <details>
        <summary>Lue ohjeet</summary>
        <small>


          <p>Kiinnostuneiden on kirjoitettava viesti ilmoittautuessaan, jonka jälkeen voitte vapaasti viestitellä.
            Kukaan muu ei näe muita ilmoittautuneita, eikä kenet valitsit. Sinun ei myöskään ole pakko valita ketään.
            Ilmoituksen tai kenet tahansa ilmoittautuneista voi poistaa milloin tahansa ilman valintaakin.
          </p>
          <p>Voit lukita tapaamisen milloin tahansa uusilta ilmoittautumisilta, mikäli sopiva seuralainen tuntuu jo löytyneen. Seuralaisen valinta ja kohtaamisen tarkempi paikka sovitaan
            hänen kanssaan henkilökohtaisesti. Kaikki keskustelut jäävät auki, myös tapaamisen jälkeen, kunnes jompikumpi osapuoli ne poistaa.
          </p>
          <p>
            Deitti-ilmoituksilla pidät myös itsesi muiden käyttäjien mielessä ja jaat menojasi sekä elämäntyyliäsi, vaikkei kaikkiin tapaamisiisi ilmoittautuisikaan ketään.
            Kun tapaamisen ajankohta on ohitse, se ei enää näy muille käyttäjille, mutta löydät sen omien tapaamistesi näkymältä. Myös tapaamiseen lataamasi kuva näkyy profiilisivullasi
            kunnes itse poistat tapaamisen. Voit siis itse valita mitkä tapaamiset jätät muistoksi ja mitkä poistat.
          </p>
          {/* <p> Väärinkäytösten ehkäisemiseksi sydänpisteet saat tapahtuman luomisesta palkkioksi
            vasta valittuasi siihen kumppanin.
          </p> */}


        </small>
      </details>
</div>
    ) : (
        <div>
          <br/>
          <p>
            Sinun pitää luoda itsellesi profiili voidaksesi luoda omia tapaamisia.
          </p>

         
          {/* Rest of your code for displaying allMeets */}
        </div>
      )}

    </div>
  );
};

export default MyMeetEditor;

