// notificationService.js

import { getToken, onMessage } from 'firebase/messaging';
import { messaging } from '../config/firebase';
import {
  setDoc,
} from "firebase/firestore";

// Get token and if no token, ask permission to get one
/* export const getToken = async () => {
  let vapidKey;
      
      if (environment === 'prod') {
        vapidKey = process.env.REACT_APP_VAPID_KEY_PROD;
      } else if (environment === 'demo') {
        vapidKey = process.env.REACT_APP_VAPID_KEY_DEMO;
      }
  getToken(messaging, { vapidKey }).then((currentToken) => {
    if (currentToken) {
      return currentToken;
    } else {
     
      console.log('No registration token available. Request permission to generate one.');
      // ...
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // ...
  });
} */

/* 


    const permission = await Notification.requestPermission();
    if (permission === 'granted') { 
    
      const environment = process.env.REACT_APP_ENVIRONMENT;
      let vapidKey;
      
      if (environment === 'prod') {
        vapidKey = process.env.REACT_APP_VAPID_KEY_PROD;
      } else if (environment === 'demo') {
        vapidKey = process.env.REACT_APP_VAPID_KEY_DEMO;
      } else {
        console.error('Invalid environment variable. Please set REACT_APP_ENVIRONMENT to "prod" or "demo".');
        // You might want to throw an error here to prevent unexpected behavior
        return;
      }
      
      const token = await getToken(messaging, { vapidKey });

      ////console.log('FCM token:', token);
      return token;
    } else {
      console.warn('Notification permission denied.');
      return null;
    }
  } catch (error) {
    console.error('Error requesting notification permission:', error);
    return null;
  }
}; */

export const saveNotification = async (userDoc) => {
  /* const existingToken = userDoc.data()?.fcmToken;

  if (typeof existingToken === "string" && existingToken.trim()) {
    console.log('FCM token already exists:', existingToken);
    
    return; // Exit if token exists
  }
 */
  const environment = process.env.REACT_APP_ENVIRONMENT;
      let vapidKey;

  if (environment === 'prod') {
    vapidKey = process.env.REACT_APP_VAPID_KEY_PROD;
  } else if (environment === 'demo') {
    vapidKey = process.env.REACT_APP_VAPID_KEY_DEMO;
  } else {
    console.error('Invalid environment variable. Please set REACT_APP_ENVIRONMENT to "prod" or "demo".');
    // You might want to throw an error here to prevent unexpected behavior
    return;
  }
console.log("Vapidkey: "+vapidKey)
  // If no token or token is undefined/null/empty string
  try {
    /* const currentToken = await getToken(messaging, {
      vapidKey:
        "BCva3Kac0eat-1hAm-Nxvfp9-k0ZgSS8EgKbL_O7oVSlTMxiiHg7R2cI-o6lIAhG8UNvByQGnDBFFbs4oCTpM7k",
    }); */

    const currentToken = await getToken(messaging, { vapidKey });
    console.log("currentToken: "+currentToken+". ");
    if (currentToken && currentToken.trim()) {
      //await updateDoc(userDoc, { fcmToken: currentToken });
      //await updateDoc(userDoc, { fcmToken: currentToken, notificationsOn: true });

      await setDoc(userDoc, {
        fcmToken: currentToken,
        notificationsOn: true
      }, { merge: true });
      console.log("FCM token retrieved and stored in user document.");
/*           new Notification("Slipper", {
        body: "FCM token saved",
        icon: "/android-icon-192x192.png",
      }); */
    } else {
      console.log("Failed to retrieve a valid FCM token.");
    }
  } catch (err) {
    console.error("Error retrieving or storing token:", err);
  }
};


export const requestNotificationPermission = async (userDoc) => {
  if (Notification.permission !== "granted") {
    try {
      const permission = await Notification.requestPermission();
           if (permission === "granted") {
        console.log("Notification permission granted");

        //saveNotification(userDoc); 
       /*  new Notification("Slipper", {
          body: "Ilmoitukset ovat päällä",
          icon: "/android-icon-192x192.png",
        }); */
        return permission;
       
      } else {
        //console.warn("Notification permission denied");
      } 
    } catch (err) {
      //console.error("Error requesting notification permission:", err);
    }
  } else {
    //console.log("Notification permission already granted");
    // Display notification or handle other logic
  }
};


// Function to send a push notification
 /*  export const sendPushNotification = async (receiverUserId, message) => {
 try {
    // Request notification permission
    const token = await requestNotificationPermission();

    // If we have the FCM token, send the notification
    if (token) {
      // Customize the title and body of the notification
      const title = 'Slipper';
      const body = message;

      // Send the notification to the specified FCM token
      await sendNotification(token, title, body);

      ////console.log('Push notification sent successfully.');
    } else {
      console.warn('Failed to send push notification due to missing FCM token.');
    }
  } catch (error) {
    console.error('Error sending push notification:', error);
  } 
}; */


// Function to send a notification to a specific FCM token
/* export const sendNotification = async (token, title, body) => {
  try {
    await fetch('https://fcm.googleapis.com/fcm/send', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `key=${firebaseConfig.apiKey}`,
      },
      body: JSON.stringify({
        to: token,
        notification: {
          title,
          body,
          click_action: window.location.href, // Open the app when notification is clicked
        },
      }),
    });
    ////console.log('Notification sent successfully.');
  } catch (error) {
    console.error('Error sending notification:', error);
  } */



