import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { UserAuth } from '../context/AuthContext';
import { db } from "../config/firebase";
import { collection, onSnapshot, writeBatch, query, where, orWhere, runTransaction, updateDoc, arrayUnion, getDocs, doc, serverTimestamp, getDoc } from 'firebase/firestore';
import { IconLock, IconLockOpen, IconHelp, IconChevronLeft, IconCrownOff, IconTrash, IconCrown, IconSquareRoundedArrowUp, IconHandStop } from '@tabler/icons-react';
//import { setUserActive, isUserActive } from '../context/UserActivityService';
//import { sendPushNotification } from '../context/NotificationService';
import { saveAction } from "../context/ActionService";


const Chat = () => {
  const { user } = UserAuth();
  const [groupedChats, setGroupedChats] = useState({});
  const [userPreviewData, setUserPreviewData] = useState(null);

  const [isChatModalOpen, setIsChatModalOpen] = useState(false);
  const [chatData, setChatData] = useState(null);
  const [isLocked, setIsLocked] = useState(false);
  const [meetDocs, setMeetDocs] = useState([]);
  const [chatsUnsubscribe, setChatsUnsubscribe] = useState(null);
  const [users, setUsers] = useState([]);
  const [messages, setMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState([]);
  const [isChatFree, setIsChatFree] = useState([]);
  const messageContainerRef = useRef(null);
  const [chooseDialogOpen, setChooseDialogOpen] = useState(false);

  const [midnightApproaching, setMidnightApproaching] = useState(false);
  const [midnightNotificationVisible, setMidnightNotificationVisible] = useState(false);
  const navigate = useNavigate();
  const { chatId } = useParams();
  const [isCurrentUserOwner, setIsCurrentUserOwner] = useState(false)
  const [otherPicUrl, setOtherPicUrl] = useState(null)
  const [myPicUrl, setMyPicUrl] = useState(null)
  const [blockConfirmDialogOpen, setBlockConfirmDialogOpen] = useState(null)
  const [infoDialogOpen, setInfoDialogOpen] = useState(null)
  const [infoMessage, setInfoMessage] = useState("")
  const [deleteChatOpen, setDeleteChatOpen] = useState(null)
  const [midnightHasPassed, setMidnightHasPassed] = useState(false);
  const [isKeyboardVisible, setKeyboardVisible] = useState(false);
  const [cancelLockOpen, setCancelLockOpen] = useState(null)
  const [isSendMessageDisabled, setIsSendMessageDisabled] = useState(true);

  const isInStandaloneMode = () => {
    return window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone;
  };

  const handleBlockDialogOpen = () => {
    ////console.log("Setting block dialog open to true")
    setBlockConfirmDialogOpen(true);
  };

  const handleBlockDialogClose = () => {
    ////console.log("Setting block dialog open to false")
    setBlockConfirmDialogOpen(false);
  };

  const handleInfoDialogOpen = () => {
    ////console.log("Setting block dialog open to true")
    setInfoDialogOpen(true);
  };

  const handleInfoDialogClose = () => {
    ////console.log("Setting block dialog open to false")
    setInfoDialogOpen(false);
  };

  const handleCancelLockOpen = () => {
    setCancelLockOpen(true);
  };

  const handleCancelLockClose = () => {
    setCancelLockOpen(false);
  };

  const handleDeleteChatOpen = () => {
    setDeleteChatOpen(true);
  };

  const handleDeleteChatClose = () => {
    setDeleteChatOpen(false);
  };

  const handleChatClose = () => {
    if (chatId && user.uid) {
      updateLastCheckTime(chatId, user.uid);
    }
    navigate('/discussions');
  };



  /*   const sendMessage = async () => {
      if (chatId) {
        const chatDocRef = doc(db, 'chats', chatId);
  
        const timestamp = new Date();
  
        const newMessage = {
          text: currentMessage,
          sender_id: user.uid,
          timestamp: timestamp, // Use the saved timestamp constant
        };
  
        if (user.uid === chatData.owner_id) {
          ////console.log("User is owner");
          
  
          try {
              // Update lastCheckTimeOwner to the current time
              await updateDoc(chatDocRef, {
                  lastCheckTimeOwner: serverTimestamp(),
              });
  
              ////console.log("lastCheckTimeOwner updated");
          } catch (error) {
              console.error("Error updating lastCheckTimeOwner:", error);
          }
      } else if (user.uid === chat.chatData.applicant_id) {
          ////console.log("User is applicant");
         
          try {
              // Update lastCheckTimeApplicant to the current time
              await updateDoc(chatDocRef, {
                  lastCheckTimeApplicant: serverTimestamp(),
              });
  
              ////console.log("lastCheckTimeApplicant updated");
          } catch (error) {
              console.error("Error updating lastCheckTimeApplicant:", error);
          }
      }
  
        try {
          // Update the chat document by adding the new message to the 'messages' array
          await updateDoc(chatDocRef, {
            messages: arrayUnion(newMessage),
  //I would like to update the lastCheckTimeApplicant or lastCheckTimeOwner here instead of separate updateDoc calls above
  //please do those conditionally in this call, if the user is owner, for example, the lastCheckTimeApplicant should remain unchanged. And vice versa.
  
          });
          setCurrentMessage("");
          // Optionally, you can refresh the messages state variable to display the new message.
          scrollToBottom();
          
      
  
        } catch (error) {
          console.error('Error sending message:', error);
        }
      }
    }; */


  const updateLastCheckTime = async (chatId, userId) => {
    // Declare lastCheckTimeField outside the try block (optional)
    let lastCheckTimeField;

    try {
      const chatDocRef = doc(db, 'chats', chatId);

      const timestamp = new Date(); // Use a more accurate timestamp source if needed

      // Determine user role based on chat data (assuming it's accessible within the function)
      const userRole = userId === chatData.owner_id ? 'owner' : 'applicant';

      // Update lastCheckTimeField based on userRole
      lastCheckTimeField = userRole === 'owner' ? 'lastCheckTimeOwner' : 'lastCheckTimeApplicant';

      const messageUpdate = {
        [lastCheckTimeField]: timestamp,
      };

      // Update the chat document with await to ensure completion
      await updateDoc(chatDocRef, messageUpdate);

      ////console.log(`${lastCheckTimeField} updated`); // Optional logging
    } catch (error) {
      // Access lastCheckTimeField within the catch block
      console.error(`Error updating ${lastCheckTimeField}:`, error);
      // Handle errors gracefully, e.g., display appropriate messages to the user
    }
  };

  
  const sendMessage = async () => {
    let trimmedMessage;
    if (typeof currentMessage === 'string') {
      trimmedMessage = currentMessage.trim();
    }
  
    if (chatId && trimmedMessage !== '') {
      const chatDocRef = doc(db, 'chats', chatId);
      const timestamp = new Date();
  
      const newMessage = {
        text: currentMessage,
        sender_id: user.uid,
        timestamp: timestamp,
      };
  
      try {
        // Fetch chat document data before using it
        const chatDocSnapshot = await getDoc(chatDocRef);
        const chatData = chatDocSnapshot.data(); // Now chatData is defined properly
  
        // Fetch user document for name
        const userDocRef = doc(db, 'users', user.uid);
        const userDocSnapshot = await getDoc(userDocRef);
        const userName = userDocSnapshot.data().name;
  
        const lastCheckTimeField = user.uid === chatData.owner_id
          ? 'lastCheckTimeOwner'
          : 'lastCheckTimeApplicant';
  
        const lastCheckTimeUpdate = {
          [lastCheckTimeField]: serverTimestamp(),
        };
  
        // Get the latest message sent by the current user
        const lastMessageByCurrentUser = chatData.messages
          .filter((msg) => msg.sender_id === user.uid)
          .sort((a, b) => b.timestamp.toMillis() - a.timestamp.toMillis())[0];
  
          const twelveHoursInMillis = 12 * 60 * 60 * 1000; // 12 hours in milliseconds
          const now = new Date();
          
          let shouldSendNotification = true;
          
          if (lastMessageByCurrentUser) {
            const lastMessageTime = lastMessageByCurrentUser.timestamp.toDate();
            const timeDifference = now - lastMessageTime;
          
            if (timeDifference < twelveHoursInMillis) {
              shouldSendNotification = false;
            }
          }
          
  
        // Update the chat document with the new message and last check time
        await updateDoc(chatDocRef, {
          messages: arrayUnion(newMessage),
          ...lastCheckTimeUpdate,
        });
  
        setCurrentMessage("");
  
        let receiverUserId;
  
        if (user.uid === chatData.owner_id) {
          receiverUserId = chatData.applicant_id;
        } else if (user.uid === chatData.applicant_id) {
          receiverUserId = chatData.owner_id;
        } else {
          console.error('Invalid user in chat');
          return;
        }
  
        // Send notification if enough time has passed since the last message
        if (shouldSendNotification) {
          const notificationText = `${userName} lähetti sinulle uuden viestin!`;
          await saveAction(user.uid, receiverUserId, 'new_message', notificationText);
        }
  
        scrollToBottom();
  
      } catch (error) {
        console.error(`Error updating lastCheckTimeField and messages:`, error);
      }
    } else {
      setCurrentMessage("");
      scrollToBottom();
    }
  };
  
  
  


  /* const sendMessage = async () => {
    let trimmedMessage;
    if (typeof currentMessage === 'string') {
      trimmedMessage = currentMessage.trim();
    }
    if (chatId && trimmedMessage !== '') {
      const chatDocRef = doc(db, 'chats', chatId);

      const timestamp = new Date();

      const newMessage = {
        text: currentMessage,
        sender_id: user.uid,
        timestamp: timestamp, // Use the saved timestamp constant
      };


      try {

        const lastCheckTimeField = user.uid === chatData.owner_id
          ? 'lastCheckTimeOwner'
          : 'lastCheckTimeApplicant';


        const lastCheckTimeUpdate = {
          [lastCheckTimeField]: serverTimestamp(),
        };


        await updateDoc(chatDocRef, {
          messages: arrayUnion(newMessage),
          ...lastCheckTimeUpdate,
        });

        ////console.log(`${lastCheckTimeField} and messages updated`);
        setCurrentMessage("");
        // Optionally, you can refresh the messages state variable to display the new message.


        //scrollToBottom();
      } catch (error) {
        console.error(`Error updating lastCheckTimeField and messages:`, error);
      }
    } else {
      setCurrentMessage("");

      scrollToBottom();
    }

  }; */


  /*   const updateLastSeen = async () => {
      if (user.uid === chatData.owner_id) {
          ////console.log("User is owner");
          const chatDocRef = doc(db, 'chats', chat.id);
  
          try {
              // Update lastCheckTimeOwner to the current time
              await updateDoc(chatDocRef, {
                  lastCheckTimeOwner: serverTimestamp(),
              });
  
              ////console.log("lastCheckTimeOwner updated");
          } catch (error) {
              console.error("Error updating lastCheckTimeOwner:", error);
          }
      } else if (user.uid === chat.chatData.applicant_id) {
          ////console.log("User is applicant");
          const chatDocRef = doc(db, 'chats', chat.id);
  
          try {
              // Update lastCheckTimeApplicant to the current time
              await updateDoc(chatDocRef, {
                  lastCheckTimeApplicant: serverTimestamp(),
              });
  
              ////console.log("lastCheckTimeApplicant updated");
          } catch (error) {
              console.error("Error updating lastCheckTimeApplicant:", error);
          }
      }
  } */



  const handleConfirmLock = async () => {
    ////console.log("ConfirmLockClicked!");
    setChooseDialogOpen(true);
  };

  const handleMidnightNotification = () => {
    ////console.log("MidnightNotificationClicked!");
    setMidnightNotificationVisible(true);
  };

  const cancelConfirmLock = async () => {
    ////console.log("ConfirmLockClicked!");
    setCancelLockOpen(true);
  };




  /*   const onTahdonClick = async () => {
      try {
        const meetId = chatData.meet_id;
  
        const chatsCollectionRef = collection(db, 'chats');
        // Retrieve all chats with the same meet_id
        const querySnapshot = await getDocs(
          query(chatsCollectionRef, where('meet_id', '==', meetId))
        );
        ////console.log('TahdonClick, querySnapshot saatu');
  
        // Find the currently active chat
        const activeChatDoc = querySnapshot.docs.find(
          (doc) => doc.data().uid === chatData.uid
        );
  
        // Check if the active chat is found
        if (activeChatDoc) {
          ////console.log('Active chat found:', activeChatDoc.data());
  
          // Archive all other chats belonging to the same meet
          const batch = writeBatch(db);
          querySnapshot.docs.forEach((doc) => {
            if (doc.id !== activeChatDoc.id) {
              const chatRef = doc(db, 'chats', doc.id);
              batch.update(chatRef, { archived: true });
            }
          });
  
          // Update the active chat with the "locked" field set to true
          const activeChatRef = doc(db, 'chats', activeChatDoc.id);
          batch.update(activeChatRef, { locked: true });
  
          // Commit the batch update
          await batch.commit();
  
          ////console.log('Chats archived successfully.');
        } else {
          console.warn('Active chat not found.');
        }
      } catch (error) {
        console.error('Error archiving chats:', error);
      }
      setChooseDialogOpen(false)
    }; */

  const onLockClick = async () => {
    //console.log("onLockClick")
    try {
      const meetId = chatData.meet_id;
      //console.log("MeetId: " + meetId)
      // Update the "closed" field in the meet document
      const meetDocRef = doc(db, 'meets', meetId);
      await updateDoc(meetDocRef, { closed: true });

      /*       const chatsCollectionRef = collection(db, 'chats');
            // Retrieve all chats with the same meet_id
            const querySnapshot = await getDocs(
              query(chatsCollectionRef, where('meet_id', '==', meetId))
            );
            //console.log('TahdonClick, querySnapshot saatu: '+querySnapshot.size);
      
            // Find the currently active chat
           
      
            //const activeChatRef = doc(db, 'chats', chatData.uid);  // Assuming uid uniquely identifies the active chat
            //const activeChatDoc = await getDoc(activeChatRef);
      
            const activeChatDoc = querySnapshot.docs.find(
              (doc) => doc.data().uid === chatData.uid
            );
      
            // Check if the active chat is found
            if (activeChatDoc) {
              ////console.log('Active chat found:', activeChatDoc.data());
      
              // Archive all other chats belonging to the same meet
              const batch = writeBatch(db);
              querySnapshot.docs.forEach((doc) => {
                //console.log("Doc to work: "+doc.id+" and active chat doc: "+activeChatDoc.id)
                if (doc.id !== activeChatDoc.id) {
                  const chatRef = doc(db, 'chats', doc.id);
                  batch.update(chatRef, { archived: true });
                }
              });
      
      
              // Update the active chat with the "locked" field set to true
              //const activeChatRef = doc(db, 'chats', activeChatDoc.id);
              batch.update(activeChatRef, { locked: true });
      
              // Commit the batch update
              await batch.commit();
      
              ////console.log('Chats archived successfully.');
            } else {
              console.warn('Active chat not found.');
            } */
      //console.log("Should be updated now ")
    } catch (error) {
      console.error('Error archiving chats:', error);
    }
    setChooseDialogOpen(false);
  };


  const cancelLockClick = async () => {
    try {

      const meetId = chatData.meet_id;

      // Update the "closed" field in the meet document
      const meetDocRef = doc(db, 'meets', meetId);
      await updateDoc(meetDocRef, { closed: false });

      /* const chatsCollectionRef = collection(db, 'chats');
      // Retrieve all chats with the same meet_id
      const querySnapshot = await getDocs(
        query(chatsCollectionRef, where('meet_id', '==', meetId))
      );
      ////console.log('CancelTahdonClick, querySnapshot saatu');

      // Find the currently active chat
      const activeChatDoc = querySnapshot.docs.find(
        (doc) => doc.data().uid === chatData.uid
      );

      // Check if the active chat is found
      if (activeChatDoc) {
        ////console.log('Active chat found:', activeChatDoc.data());

        // Update the active chat with the "locked" field set to false
        const activeChatRef = doc(db, 'chats', activeChatDoc.id);
        await updateDoc(activeChatRef, { locked: false });

        // Unarchive all other chats belonging to the same meet
        const batch = writeBatch(db);
        querySnapshot.docs.forEach((doc) => {
          if (doc.id !== activeChatDoc.id) {
            const chatRef = doc(db, 'chats', doc.id);
            batch.update(chatRef, { archived: false });
          }
        });

        // Commit the batch update
        await batch.commit();

        ////console.log('Chats reverted successfully.');
      } else {
        console.warn('Active chat not found.');
      } */
    } catch (error) {
      console.error('Virhe tapaamisen lukituksen perumisessa:', error);
    }
    setCancelLockOpen(false);
  };

  const onPeruutaClick = () => {
    //should only hide the info, hopefully does it immediately
    setChooseDialogOpen(false);
  };



  const scrollToBottom = () => {
    ////console.log("Scroll to bottom")
    if (messageContainerRef.current) {

      const lastMessage = messageContainerRef.current.lastElementChild;


      if (lastMessage) {

        ////console.log("Last message found for scrolling:", lastMessage.textContent);
        lastMessage.scrollIntoView();
      }
    }
  };

  /*  const scrollToBottom = () => {
     ////console.log("Scroll to bottom");
 
     if (messageContainerRef.current) {
       const container = messageContainerRef.current;
       //console.log("container scroll height" + container.scrollHeight)
       //console.log("container client height" + container.clientHeight)
       // Check if the container has a scrollable area
       if (container.scrollHeight > container.clientHeight) {
         // Scroll to the bottom of the container using scrollIntoView with options
         const lastMessage = container.lastElementChild;
         //console.log("Scrolling to last element")
         if (lastMessage) {
           lastMessage.scrollIntoView({
             inline: "end"
           });
         } else {
           // Container is scrollable but has no elements, scroll to top
           //console.log("Scrolling to top with smooth")
           container.scrollTo({ top: 0, behavior: "smooth" });
         }
       } else {
         //console.log("Container not scrollable")
         // Container is not scrollable, scroll the window to the bottom
         window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
       } */


  /*       const lastMessage = messageContainerRef.current.lastElementChild;
  
        
        if (lastMessage) {
          
          //console.log("Last message found for scrolling: "+lastMessage)
          lastMessage.scrollIntoView();
        } 
}
};
*/



  // Function to check if midnight is approaching
  const checkMidnightApproaching = (meetDate) => {
    const now = new Date();
    ////console.log("Midnight check: Now is " + now);
    ////console.log("Midnight check: Meetdate is " + meetDate);

    // Parse meetDate into a Date object
    const meetDateTime = new Date(meetDate);

    // Create a new Date object representing the next midnight
    const nextMidnight = new Date(meetDateTime);
    nextMidnight.setHours(23, 59, 59, 999);
    ////console.log("Next midnight is " + nextMidnight);

    const timeDifference = nextMidnight - now;
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

    const formattedTime = `Aikaa keskustelun tuhoutumiseen ${days} päivää ${hours} tuntia ja ${minutes} minuuttia`;

    ////console.log(formattedTime);
    setInfoMessage(formattedTime);

    if (now > meetDateTime && now < nextMidnight) {
      // If the current time is after the meet date and before the next midnight, set midnightApproaching to true
      ////console.log("Midnight should be approaching");
      setMidnightApproaching(true);
    } else {
      ////console.log("Midnight IS NOT YET");
      // Otherwise, set midnightApproaching to false
      setMidnightApproaching(false);
    }

    // Check if midnight has passed
    if (now > nextMidnight) {
      ////console.log("Midnight has passed");
      setMidnightHasPassed(true);
      const midnightPassedMessage = `Kello on lyönyt yli puolenyön tämän tapaamisen osalta, ja keskustelunne on poistettu. Toivottavasti oli mukavaa ja tervetuloa luomaan uusia kohtaamisia!`;

      ////console.log(midnightPassedMessage);
      setInfoMessage(midnightPassedMessage);
    } else {
      ////console.log("Midnight has not passed yet");
      setMidnightHasPassed(false);
    }

  };



  // Function to check if current time is past midnight
  const checkWhetherToArchiveChat = async (meetDate, chat) => {
    const now = new Date();
    const meetDateMidnight = new Date(meetDate);
    meetDateMidnight.setHours(23, 59, 59, 999); // Set to the last millisecond of the day
    ////console.log("Archive check for midnight, time now is " + now + " and midnight is " + meetDateMidnight)
    if (now > meetDateMidnight) {
      try {
        // Get the chat document reference
        const chatDocRef = doc(db, 'chats', chatId);

        // Update the chat document's archived field to true
        await updateDoc(chatDocRef, {
          archived: true
        });

        // You can perform additional actions or state updates here
      } catch (error) {
        console.error('Error updating chat document:', error);
      }
    }
  };


  const fetchUserPics = async () => {
    ////console.log("Fetch pics, chatdata:")
    ////console.log(chatData)
    try {
      // Fetch the user data for the applicant and owner
      const [applicantSnapshot, ownerSnapshot] = await Promise.all([
        getDoc(doc(db, 'users', chatData.applicant_id)),
        getDoc(doc(db, 'users', chatData.owner_id)),
      ]);

      const applicantData = applicantSnapshot.data();
      const ownerData = ownerSnapshot.data();

      ////console.log("Applicant and owner data")
      ////console.log(applicantData)
      ////console.log(ownerData)

      // Update state variables with the picture URLs

      const myTempUrl = user.uid === chatData.owner_id ? ownerData.picUrl : applicantData.picUrl;
      const otherTempUrl = user.uid === chatData.owner_id ? applicantData.picUrl : ownerData.picUrl;

      ////console.log("tempUrls:")
      ////console.log(myTempUrl)
      ////console.log(otherTempUrl)

      setMyPicUrl(myTempUrl);
      setOtherPicUrl(otherTempUrl);

    } catch (error) {
      console.error('Error fetching user pics:', error);
    }
  };



  /*   useEffect(() => {
      ////console.log("useEffect: chatId is obviously available " + chatId)
      const chatDocRef = doc(db, 'chats', chatId);
  
      // Subscribe to the chat document and listen for updates
      const unsubscribe = onSnapshot(chatDocRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          const chat = docSnapshot.data();
          const chatMessages = chat.messages || [];
          setMessages(chatMessages);
          setIsLocked(chat.locked);
          setChatData(chat);
          ////console.log("useEffect: chatData was set: " + chatData)
          const isOwner = user.uid === chat.owner_id;
          setIsCurrentUserOwner(isOwner)
          // Check the 'open_chat' field and update the state
  
          scrollToBottom();
  
          // Check if midnight is approaching
          const chatMeetDate = new Date(chat.meet_date);
  
          if (!isNaN(chatMeetDate.getTime())) {
            // Now chatMeetDate is a valid Date object
            ////console.log("Parsed Date:", chatMeetDate);
          } else {
            console.error("Invalid date string:", chatData.meet_date);
          }
  
  
          checkMidnightApproaching(chatMeetDate);
  
          // Check if current time is past midnight
          checkWhetherToArchiveChat(chatMeetDate, chat);
        }
      });
  
      // Clean up the subscription when the component unmounts or when another chat is selected
      return () => unsubscribe();
  
    }, []); */

  /*   const handleResize = useCallback(() => {
      setKeyboardVisible(window.innerHeight < window.outerHeight);
    }, []); */



  const BlockConfirmDialog = ({ isOpen, onClose }) => {
    return (
      <dialog open={isOpen} className="universal-dialog">
        <p>
          Oletko varma, että haluat estää tämän käyttäjän? Tällä hetkellä sovelluksessa ei ole toteutettua toiminnallisuutta eston poistamiseksi.
        </p>

        <div className="button-container">
          <button onClick={onClose}>Peruuta</button>
          <button onClick={handleBlockUser}>Kyllä, estä käyttäjä</button>
        </div>
      </dialog>
    );
  };

  const InfoDialog = ({ isOpen, onClose }) => {
    return (
      <dialog open={isOpen} className="universal-dialog">
        <p>
          <IconLock color={'grey'} /> Lukitsee tapaamisen ja piilottaa sen käyttäjiltä. Uusia ilmoittautumisia ei näin enää voi tulla. Tähän mennessä ilmoittautuneiden kanssa keskustelut jatkuvat normaalisti, myös deittien jälkeen.
        </p>
        <p>
          <IconLock
            color={'#FFC139'}
          /> Tällä ikonilla voit puolestaan avata tapaamisen jälleen uusille ilmoittautumisille.
        </p>
        <p>
          <IconHandStop color={'#FF4F4F'} /> Stop-toiminnolla voit laittaa asiattomasti käyttäytyvän henkilön estoon. Tämä myös poistaa keskustelun.
        </p>
        <p>
          <IconTrash color={'#C4A484'} /> Poisto-ikonilla voit poistaa keskustelun, mutta laittamatta käyttäjää estoon.
        </p>

        <p>
          Kaikissa toiminnoissa on varmistus ennen lopullista hyväksymistä.
        </p>

        <div className="button-container">
          <button onClick={onClose}>Sulje</button>

        </div>
      </dialog>
    );
  };

  const CancelLockDialog = ({ isOpen, onClose }) => {
    return (
      <dialog open={isOpen} className="universal-dialog">
        <p>
          Oletko varma, että haluat perua tämän lukituksen ja palauttaa tapaamisen jälleen avoimeksi uusille ilmoittumisille?
        </p>
        <p>
          Tapaaminen näkyy edelleen vain suosikeillesi, mikäli niin olet asettanut.
        </p>
        <div className="button-container">
          <button onClick={onClose}>Ei, pidetään lukittuna</button>
          <button onClick={cancelLockClick}>Kyllä, peru lukitus</button>
        </div>
      </dialog>
    );
  };

  const DeleteChatDialog = ({ isOpen, onClose }) => {
    return (
      <dialog open={isOpen} className="universal-dialog">
        <p>
          Oletko varma, että haluat poistaa tämän keskustelun ja perua mahdollisen tapaamisen tämän henkilön kanssa?
        </p>

        <div className="button-container">
          <button onClick={onClose}>Peruuta</button>
          <button onClick={handleDeleteChat}>Kyllä</button>
        </div>
      </dialog>
    );
  };

  const ChooseConfirmDialog = ({ isOpen, onClose }) => {
    return (


      <dialog open={isOpen} className="universal-dialog">
        <div className="confirm-lock-container">
          <p>Haluatko lukita ja piilottaa tämän tapaamisen? Uusia ehdokkaita ei tällöin enää voi ilmoittautua, mutta keskustelut jo ilmoittautuneiden
            kanssa jatkuvat normaalisti, eivätkä katoa tapaamisen jälkeenkään.</p>
          <p>Seuralaisen valinta deiteille tapahtuu sanallisesti. Voit toki käsin poistaa muut ehdokkaat valintasi mukaan. Muista kiittää kiinnostuksesta ja toivottaa hyvää jatkoa.</p>
          <div>
            <button onClick={onLockClick} className="confirm-button">Piilota tapaaminen</button>
            <button onClick={onPeruutaClick}>Peruuta</button>
          </div>
          {/*           <small className="confirmation-text">
            Valinta lopettaa automaattisesti keskustelut muiden tapaamiseen ilmoittautuneiden kanssa, sekä poistaa tapaamisen näkyviltä muilta paitsi sinulta ja valitsemaltasi ihmiseltä.
          </small> */}
        </div>
      </dialog>
    );
  };

  const handleDeleteChat = async () => {
    ////console.log("Trying to archive chat");
    try {
      const chatDocRef = doc(db, 'chats', chatId);
      await updateDoc(chatDocRef, {
        archived: true,
      });

      ////console.log(`Chat document ${chatId} archived successfully.`);
      handleDeleteChatClose();
      navigate('/discussions'); // Assuming you have a navigate function for routing
    } catch (error) {
      console.error("Error archiving chat:", error);
      // Handle the error as needed
    }
  };


  const handleBlockUser = async () => {
    ////console.log("Trying to block user")
    let userToBeBlocked;
    if (user.uid === chatData.owner_id) {
      ////console.log("Current User is owner");
      userToBeBlocked = chatData.applicant_id;
    } else if (user.uid === chatData.applicant_id) {
      ////console.log("User is applicant");
      userToBeBlocked = chatData.owner_id;
    }

    try {
      // Update "blocked_by_users" array for userToBeBlocked
      const blockedUserDocRef = doc(db, 'users', userToBeBlocked);
      await updateDoc(blockedUserDocRef, {
        blocked_by_uids: arrayUnion(user.uid),
      });

      // Update "blocked_uids" array for the current user
      const currentUserDocRef = doc(db, 'users', user.uid);
      await updateDoc(currentUserDocRef, {
        blocked_uids: arrayUnion(userToBeBlocked),
      });

      const chatsQuery = query(
        collection(db, 'chats'),
        where('owner_id', 'in', [user.uid, userToBeBlocked]),
        where('applicant_id', 'in', [user.uid, userToBeBlocked])
      );

      const chatDocs = await getDocs(chatsQuery);

      // Archive all relevant chats
      const updatePromises = chatDocs.docs.map(async (chatDoc) => {
        const chatId = chatDoc.id;
        const chatDocRef = doc(db, 'chats', chatId);
        await updateDoc(chatDocRef, {
          archived: true,
        });
        ////console.log(`Chat document ${chatId} archived successfully.`);
      });

      await Promise.all(updatePromises);
      ////console.log('Document updated successfully.');
      // This is where you should add chat.chatData to the "blocked" state
      // You would typically remove the chat or hide it from the user interface.
      handleBlockDialogClose();
      navigate('/discussions');
    } catch (error) {
      console.error("Error updating blocked users: ", error);
    }
  };



  useEffect(() => {
    ////console.log("useEffect: chatId is obviously available " + chatId);


    const chatDocRef = doc(db, 'chats', chatId);

    // Subscribe to the chat document and listen for updates
    const unsubscribe = onSnapshot(chatDocRef, async (docSnapshot) => {
      if (docSnapshot.exists()) {
        ////console.log("useEffect, trying to get chatData");
        const chat = docSnapshot.data();
        //////console.log(chat);

        // Retrieve the meet document using chat.meet_id
        const meetId = chat.meet_id;
        const meetDocRef = doc(db, 'meets', meetId);

        // Use await to wait for the meet document
        const meetSnapshot = await getDoc(meetDocRef);

        if (meetSnapshot.exists()) {
          const meetData = meetSnapshot.data();
          const isLocked = meetData.closed; // Check for closed field in meet data
          const chatMessages = chat.messages || [];
          setMessages(chatMessages);
          setChatData(chat);
          //////console.log("useEffect: chatData was set: " + chatData);
          const isOwner = user.uid === chat.owner_id;
          setIsCurrentUserOwner(isOwner);
          setIsLocked(isLocked);
        } else {
          console.error('Meet document not found for chat:', chatId);
        }







        /*     const chatDocRef = doc(db, 'chats', chatId);
        
            // Subscribe to the chat document and listen for updates
            const unsubscribe = onSnapshot(chatDocRef, (docSnapshot) => {
              ////console.log("Chat, UseEffect, unsubscribe");
              if (docSnapshot.exists()) {
                ////console.log("useEffect, trying to get chatData");
                const chat = docSnapshot.data();
                //////console.log(chat);
        
                // Retrieve the meet document using chat.meet_id
                const meetId = chat.meet_id;
                const meetDocRef = doc(db, 'meets', meetId);
                const meetSnapshot = getDoc(meetDocRef);
        
                if (meetSnapshot.exists()) {
                  const meetData = meetSnapshot.data();
                  const isLocked = meetData.closed; // Check for closed field in meet data
        
                  setIsLocked(isLocked);
                }
        
                const chatMessages = chat.messages || [];
                setMessages(chatMessages); */

        //we must check meet here, not chat anymore!
        //setIsLocked(chat.locked);


        // Check if midnight is approaching
        // const chatMeetDate = new Date(chat.meet_date);

        /*       if (!isNaN(chatMeetDate.getTime())) {
               
              } else {
                console.error("Invalid date string:", chatData.meet_date);
              } */

        //checkMidnightApproaching(chatMeetDate);

        // Check if current time is past midnight
        //checkWhetherToArchiveChat(chatMeetDate, chat);
        /* Sekä keskustelun automaattinen tuhoaminen, että ilmoituksen näyttäminen keskustelun poistumisesta on tilapäisesti otettu pois käytöstä. */
      }
    });

    // Clean up the subscription when the component unmounts or when another chat is selected
    return () => {
      unsubscribe();
    };
  }, [chatId, user.uid, cancelLockOpen, chooseDialogOpen]);

  // Scroll to bottom when messages are updated
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    scrollToBottom();
  }, []);

  /*   useEffect(() => {
      // Check if currentMessage exists and is a string, then use optional chaining
      if (currentMessage && typeof currentMessage === 'string') {
        //console.log("current message exists and type is string:")
        //console.log(currentMessage.trim() === '')
        setIsSendMessageDisabled(currentMessage.trim() === '');
      } else {
        //console.log("current message exists and type is string:")
        // Handle other cases (e.g., currentMessage is not set, not a string, etc.)
        setIsSendMessageDisabled(true); // Or set a default state based on your logic
      }   
    }, [currentMessage]); */


  useEffect(() => {
    // Set initial textarea rows based on the message length
    const rows = currentMessage ? Math.min(Math.ceil(currentMessage.length / 40), 5) : 1;
    const textarea = document.getElementById('chattextarea'); // Replace with your actual ID
    if (textarea) {
      textarea.rows = rows;
    }
  }, [currentMessage]);

  // A separate useEffect for fetching images
  useEffect(() => {
    // Ensure that chatData is not null before fetching images
    if (chatData) {
      fetchUserPics();
    }
  }, [chatData]);

  /*     useEffect(() => {
        //console.log("Use effect for messages")
        if (messageContainerRef.current) {
          const container = messageContainerRef.current;
          if (container.scrollHeight > container.clientHeight) {
            
            const lastMessage = container.lastElementChild;
            if (lastMessage) {
              lastMessage.scrollIntoView({
                behavior: "smooth",
                block: "end",
                inline: "end",
              });
            }
          } else {
            //console.log("Use effect for messages, plan b")
            
  
            const lastMessage = container.lastElementChild;
            if (lastMessage) {
              lastMessage.scrollIntoView({
                
                block: "end",
                inline: "end",
              });
            }
          }
        }
  
        
      }, [messages]);  */

  /* const handleInputChange = (e) => {
   setCurrentMessage(e.target.value);

   // Adjust the input field height based on the content
   const textarea = e.target;
   textarea.style.height = 'auto';  // Reset the height to auto
   textarea.style.height = textarea.scrollHeight + 'px';  // Set the height to the scrollHeight
 };
 
 const handleKeyDown = (e) => {
   // Handle Enter key press to insert a line break
   if (e.key === 'Enter' && !e.shiftKey) {
     e.preventDefault();
     setCurrentMessage((prevMessage) => prevMessage + '\n'); // Append a newline character
   }
 }; */

  const handleInputChange = (event) => {
    const maxLength = 1000;
    if (event.target.value.length > maxLength) {
      event.target.value = event.target.value.slice(0, maxLength);
    }
    setCurrentMessage(event.target.value);
  };

  const handleEnterPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      setCurrentMessage((prevMessage) => prevMessage + '\n');
    }
  };

  return (

    <div className="no-scroll-container">

      <nav className="chat-top-navbar">
        <ul>
          <li>
            <a href="#" onClick={handleChatClose}>
              <IconChevronLeft size={35} />
            </a>
          </li>
          {/*  {myPicUrl && otherPicUrl && (
            <li>
              <img src={myPicUrl} alt="My Sender" className="sender-image" />
              <img src={otherPicUrl} alt="Other Sender" className="sender-image" />
            </li>
          )} */}
        </ul>
        <ul>



          {isCurrentUserOwner && !isLocked && (
            <li>
              <a href="#" onClick={() => handleConfirmLock()}>
                <IconLock
                  color={'grey'}
                  size={35}

                />
              </a>
            </li>
          )}
          {/*  {isLocked && (
            <li style={{ color: '#8A2BE2' }}>
              <strong>Seuralainen Valittu</strong>
            </li>
          )} */}

          {isCurrentUserOwner && isLocked && (
            <li>
              <a href="#" onClick={() => handleCancelLockOpen()}>
                <IconLock
                  color={'#FFC139'}
                  size={35}

                />
              </a>
            </li>
          )}

          <li>
            <a href="#" onClick={() => handleBlockDialogOpen()}>
              <IconHandStop size={35}
                color={'#FF4F4F'} />
            </a>
          </li>

          <li>
            <a href="#" onClick={() => handleDeleteChatOpen()}>
              <IconTrash size={35}
                color={'#C4A484'} />
            </a>
          </li>
          <li>
            <a href="#" onClick={() => handleInfoDialogOpen()}>
              <IconHelp size={35}
                color={'#4285F4'} />
            </a>
          </li>
        </ul>

      </nav>

      {infoDialogOpen && (
        <div>

          <InfoDialog
            isOpen={infoDialogOpen}
            onClose={handleInfoDialogClose}

          />
        </div>
      )}

      {blockConfirmDialogOpen && (
        <div>

          <BlockConfirmDialog
            isOpen={blockConfirmDialogOpen}
            onClose={handleBlockDialogClose}
            onConfirm={handleBlockUser}
          />
        </div>
      )}

      {cancelLockOpen && (
        <div>

          <CancelLockDialog
            isOpen={cancelLockOpen}
            onClose={handleCancelLockClose}
            onConfirm={cancelLockClick}
          />
        </div>
      )}

      {deleteChatOpen && (
        <div>

          <DeleteChatDialog
            isOpen={deleteChatOpen}
            onClose={handleDeleteChatClose}
            onConfirm={handleDeleteChat}
          />
        </div>
      )}

      {chooseDialogOpen && (
        <div>
          <ChooseConfirmDialog
            isOpen={chooseDialogOpen}
            onClose={onPeruutaClick}
            onConfirm={onLockClick}
          />
        </div>
      )}

      {/*   {midnightHasPassed ? (
        
        <div className="message-container">
          <span>{infoMessage}</span>
        </div>
      ) : ( */}

      <div>

        <div ref={messageContainerRef} className="message-container">
          {messages.map((message, index) => (
            <div className="message-content" key={index}>
              {message.sender_id !== user.uid && otherPicUrl && (
                <div className="sender-image-container">
                  <img src={otherPicUrl} alt="Other Sender" className="sender-image" />
                </div>
              )}
              {/*   <div className={`message-wrapper ${message.sender_id === user.uid ? 'own-message' : 'other-message'}`}>
                  <p>{message.text}</p>
                </div> */}
              <div className={`message-wrapper ${message.sender_id === user.uid ? 'own-message' : 'other-message'}`}>
                {/* Replace newline characters with <br> tags */}
                {typeof message.text === 'string' && message.text.split('\n').map((line, index) => (
                  /* {message.text.split('\n').map((line, index) => ( */
                  <React.Fragment key={index}>
                    {line.trim()} {/* Apply trim() to remove leading and trailing whitespace */}
                    <br />
                  </React.Fragment>
                ))}
              </div>
              {message.sender_id === user.uid && myPicUrl && (
                <div className="sender-image-container">
                  <img src={myPicUrl} alt="My Sender" className="sender-image" />
                </div>
              )}
            </div>
          ))}
          {/* {infoMessage && midnightApproaching && (
            <div className="message-content info-message">
              <small>{infoMessage}</small>
            </div>
          )} */}
        </div>
        {/*  <nav className="chat-bottom-navbar" style={{ height: isInStandaloneMode() ? '60px' : '120px' }}> */}
         <div className="chat-bottom-navbar"> 

          <textarea
            id="chattextarea"
            className="textarea"
            placeholder="Kirjoita viesti..."
            value={currentMessage}
            onChange={handleInputChange}
            onKeyDown={handleEnterPress}
          />

          <a href="#" className="send-button" onClick={sendMessage} disabled={isSendMessageDisabled}>
            <IconSquareRoundedArrowUp size={50} />
          </a>

         </div> 

      </div>
      {/* )
      } */}

    </div >
  );


}

export default Chat;