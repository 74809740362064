import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IconChevronLeft } from '@tabler/icons-react';
import slipperLogo from '../assets/SlipperIcon.png';

const Terms = () => {
 /*  const navigate = useNavigate();

  const goBack = () => {
    navigate('/account');
    
  }; */

  return (
    <div className="page-container">
       {/*  <nav style={{ display: 'flex', paddingRight: '10px' }}>
        <ul>
          <li>
            <a href="#" onClick={goBack}>
              <IconChevronLeft size={35} />
            </a>
          </li>
        </ul>
      </nav> */}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
      <img src={slipperLogo} alt="Slipper Logo" style={{ height: '200px' , width: 'auto'}} />
      
      </div>
      

      <h4>Evästeiden käyttö</h4>

      <h4>Evästeiden käyttö</h4>

<details>
  <summary>Mihin käytämme evästeitä?</summary>
  <ul>
    <li>Parantaaksemme sivustomme käyttäjäkokemusta ja tarjotaksemme räätälöityjä sisältöjä.</li>
  </ul>
</details>

<details>
  <summary>Miten evästeitä voi hallita?</summary>
  <ul>
    <li>Voit muuttaa selaimen asetuksia estääksesi evästeiden vastaanottamisen.</li>
    <li>Useimmat selaimet mahdollistavat evästeiden hyväksymisen tai hylkäämisen sekä ilmoittavat niiden vastaanottamisesta.</li>
    <li>Voit asettaa laitteesi tai selaimen niin, että saat ilmoituksen aina ennen evästeen tallentamista.</li>
    <li>Evästeiden hallintaan voit käyttää myös kolmannen osapuolen työkaluja.</li>
  </ul>
</details>

<details>
  <summary>Selaimen ja laitteen asetukset</summary>
  <ul>
    <li>Suosittelemme tarkistamaan selaimen asetukset ja päivittämään ne säännöllisesti.</li>
    <li>Varoitus: Evästeiden estäminen saattaa vaikuttaa sivuston toimivuuteen ja käyttäjäkokemukseen.</li>
    <li>Tarkista laitteesi asetukset, jotta voit hallita evästeiden käyttöä myös laitteellasi.</li>
  </ul>
</details>

<details>
  <summary>Google Analytics</summary>
  <ul>
    <li>Käytämme Google Analytics -palvelua sivuston käytön analysointiin.</li>
    <li>Google Analytics käyttää evästeitä kerätäkseen tietoja sivuston käytöstä.</li>
    <li>Voit estää Google Analyticsin evästeiden käytön asentamalla Google Analyticsin virallisen käytöstä poissulkemisen lisäosan selaimellesi.</li>
    <li>Lisätietoja saat tutustumalla Google Analyticsin käyttöehtoihin ja tietosuojakäytäntöön.</li>
  </ul>
</details>

<details>
  <summary>Miten saat yhteyden meihin?</summary>
  <ul>
    <li>Voit ottaa meihin yhteyttä sähköpostitse osoitteeseen: slippertuki@gmail.com</li>
    <li>Käytä yhteydenottolomaketta sivustollamme.</li>
    <li>Seuraamme säännöllisesti saapuvia viestejä ja vastaamme niihin mahdollisimman pian.</li>
  </ul>
</details>



    </div>
  );
};

export default Terms;
