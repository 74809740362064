import { useNavigate } from 'react-router-dom'
import React from "react";
import { Range } from "react-range";
import { IconArrowLeft, IconCircleX } from '@tabler/icons-react';
import { UserAuth } from '../context/AuthContext';
import { useEffect, useState } from "react";
import { db, storage } from "../config/firebase";
import { useForm, Controller } from 'react-hook-form';
import {
  query,
  where,
  getDocs,
  getDoc,
  setDoc,
  collection,
  addDoc,
  deleteDoc,
  updateDoc,
  doc,
  onSnapshot,
} from "firebase/firestore";
import { ref, uploadBytes, list, uploadBytesResumable, getDownloadURL, listAll, deleteObject } from "firebase/storage";
import placeholderImage from '../assets/empty-profile-image.png';
import { resizeThumbPic, resizeLargePic, calculateAge } from '../config/utils';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import locationsJson from '../assets/locations.json';

const Profile = () => {

  const { user } = UserAuth();
  const [ existingUser, setExistingUser ]  = useState(false);
  const { register, handleSubmit, setError, watch, setValue, formState: { errors, submitting, touched } } = useForm();
  const usersCollectionRef = collection(db, "users");
  const [refreshImages, setRefreshImages] = useState(false);
  const navigate = useNavigate();
  //const [profilePic, setProfilePic] = useState(null);
  const [displayPicURL, setDisplayPicURL] = useState(null);
  const [displayPic, setDisplayPic] = useState(null);
  const [existingImageUrl, setExistingImageUrl] = useState(null);
  const [existingThumbUrl, setExistingThumbUrl] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadError, setUploadError] = useState(null);
  const [picHasBeenChanged, setPicHasBeenChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const fileUploadStyle = {
    display: 'none', // Hide the default input button
  };
  const customButtonStyle = {
    backgroundColor: 'goldenrod', // Golden color, you can change this
    color: 'black',
    padding: '10px 15px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    textAlign: 'center', // Align text to the center
    display: 'inline-block', // Make the button an inline-block element
    width: 'auto', // Allow the button to adjust its width based on content
  };
  const watchDisplayPic = watch("displayPicURL", "");
  const watchProfilePic = watch("profilePic", "");
  const [imageMandatoryError, setImageMandatoryError] = useState(false);
  const [ageRangeError, setAgeRangeError] = useState(false);
  const [progressValue, setProgressValue] = useState(0);
  const [locations, setLocations] = useState([]);

  const genderOptions = [
    { label: 'Mies', value: 'Mies' },
    { label: 'Nainen', value: 'Nainen' },
    { label: 'Pari', value: 'Pari' },
    { label: 'Ei määritelty', value: 'Ei määritelty' }
  ];

  const genderInterestOptions = [
    { label: 'Mies', value: 'Mies' },
    { label: 'Nainen', value: 'Nainen' },
    { label: 'Sukupuolella ei väliä', value: 'Sukupuolella ei väliä' },
    { label: 'Pari', value: 'Pari' },
  ];

  const starSignOptions = [
    { label: 'Oinas', value: 'Oinas' },
    { label: 'Härkä', value: 'Härkä' },
    { label: 'Kaksonen', value: 'Kaksonen' },
    { label: 'Rapu', value: 'Rapu' },
    { label: 'Leijona', value: 'Leijona' },
    { label: 'Neitsyt', value: 'Neitsyt' },
    { label: 'Vaaka', value: 'Vaaka' },
    { label: 'Skorpioni', value: 'Skorpioni' },
    { label: 'Jousimies', value: 'Jousimies' },
    { label: 'Kauris', value: 'Kauris' },
    { label: 'Vesimies', value: 'Vesimies' },
    { label: 'Kalat', value: 'Kalat' }
  ];

  const lookingForOptions = [
    "Pitkäaikaista suhdetta",
    "Pitkää tai lyhyttä suhdetta",
    "En mitään vakavaa",
    "Uusia kavereita",
    "Tiedän vasta tavattaessa"
  ];

  const educationOptions = [
    'Peruskoulu',
    'Lukio',
    'Ammattikoulu',
    'Ammattikorkeakoulu',
    'Yliopisto',
    'Ei merkitystä minulle'
  ];

  const exerciseOptions = [
    "En juurikaan liiku",
    "Vain arjen hyötyliikuntaa",
    "Liikun satunnaisesti",
    "Usein",
    "Päivittäinen elämäntapa"
  ];

  const smokingOptions = [
    "En polta",
    "Vain juhliessa",
    "Päivittäin",
    "Vain sähkötupakkaa",
    "Vain nuuskaa",
    "Ole minulle syy lopettaa"
  ];

  const alcoholOptions = [
    "En juo",
    "Harvemmin",
    "Viikonloppuisin",
    "Päivittäin",
    "Haluaisin vähentää"
  ];

  const relationshipStatusOptions = [
    "Sinkku",
    "Parisuhteessa",
    "Eettisesti monisuhteinen",
    "Avoimessa suhteessa",
    "Tilanne epäselvä"
  ];

  const kidsOptions = [
    "Ei, eikä ole toiveissa",
    "Ei, mutta sinulla saa olla",
    "Kyllä, asuvat kanssani",
    "Kyllä, eivät asu kanssani koko aikaa",
    "Kyllä, mutta ovat muuttaneet jo omilleen",
    "Ei, mutta haluaisin"
  ];

  const petsOptions = [
    "Ei",
    "Ei, mutta tykkään",
    "Olen allerginen",
    "Kyllä, koiria",
    "Kyllä, kissoja",
    "Kyllä, jotain muuta",
    "Eläintarha"
  ];






// Date conversion function (as a const)
const convertDateFormat = (dateString) => {
  if (!dateString) return ""; // Handle empty input

  const [year, month, day] = dateString.split("-").map(Number);
  return `${day.toString().padStart(2, '0')}.${month.toString().padStart(2, '0')}.${year}`;
};


  const fetchUserData = async () => {
    if (user) {
      try {
        ////console.log("fetchUserData, Trying to fetch user data for user " + user.uid);

        const userRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userRef);

       /*  const querySnapshot = await getDocs(
          query(usersCollectionRef, where("uid", "==", user.uid))
        ); */

        if (userDoc.exists()) {
          //const docSnap = querySnapshot.docs[0];
          const userData = userDoc.data();

          if (userData && typeof userData === "object") {
            ////console.log("Name:", userData.name); // Print the value of the "name" field

            if (userData.birthday) {
              const isNewFormat = userData.birthday.includes("."); // Check for dots
  
              if (!isNewFormat) { // If it's the old format, convert it
                const newDateFormat = convertDateFormat(userData.birthday);
                userData.birthday = newDateFormat; 
              }
            }

            if (userData.thumbUrl) {
              ////console.log("Found thumbUrl: " + userData.thumbUrl)
              //setProfilePic(userData.thumbUrl);
              setExistingImageUrl(userData.picUrl)
              setExistingThumbUrl(userData.thumbUrl)
            } else setExistingThumbUrl(null) //setProfilePic(null);

            Object.keys(userData).forEach((fieldName) => {
              setValue(fieldName, userData[fieldName]);
            });

            setExistingUser(true);
          } else {
            ////console.log("fetchUserData, Invalid user data:", userData);
          }
        } else {
          setExistingUser(false);
          ////console.log("fetchUserData, No such document when trying to fetch user data!");
        }
      } catch (error) {
        
        console.log("fetchUserData, Error getting user data:", error);
      }
    }
  };

  /* const handleFormSubmitNew = async (data) => {
    // If meetId is present, update the existing meet
    if (existingUser) {
      updateExistingUser(user.uid, data);
    } else {
      // Handle the case for adding a new meet
      addNewUser(user.uid, data);
    }
  }; */

  const handleFormSubmit = async (data) => {
    //console.log("handleFormSubmit");
    try {
      setIsLoading(true);
  
      const userId = user.uid;
      const userRef = doc(db, "users", userId);
      const userDoc = await getDoc(userRef);
  
      if (userDoc.exists()) {
        await handleUpdateUser(data, userId);
      } else {
        await handleCreateUser(data);
      }
  
      setIsLoading(false);
      
    } catch (error) {
      console.error("Error saving form data:", error);
      setIsLoading(false);
    }
  };



  const showErrorToast = (errorMessage) => {
    toast.error(errorMessage, {

      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",

    });
  };

  const handleUpdateUser = async (data, userId) => {
    
    //console.log("handleUpdateUser");
    setIsLoading(true);
    // Validate age range
    
    ////console.log("Update user: Age range: "+partner_min_age+" - "+partner_max_age)
    const { partner_min_age, partner_max_age } = data;
    if (parseInt(partner_min_age) > parseInt(partner_max_age)) {
      //console.log('Minimum age cannot be larger than maximum age.');
      //console.log("Update user: Age range: "+partner_min_age+" - "+partner_max_age)
      setAgeRangeError("Minimi-ikä ei voi olla suurempi kuin maksimi-ikä")
      showErrorToast("Minimi-ikä ei voi olla suurempi kuin maksimi-ikä")
      return;
    }

    let downloadURL = null;
      let thumbnailURL = null; 
  
    if (displayPic) { //user has updated image, if not, then we save just everything else but don't upload images
      //console.log("displayPic found for update");
      const profileThumbnailPic = await resizeThumbPic(displayPic);
      const profilePic = await resizeLargePic(displayPic);
      //const [downloadURL, thumbnailURL] = await uploadImages(userId, displayPic, profileThumbnailPic);
  

          const storageRef = ref(storage, `user_profile_images/${userId}/${displayPic.name}`);
          const storageThumbnailRef = ref(storage, `user_profile_images/thumbnails/${userId}/${displayPic.name}`);
          const uploadTask = uploadBytesResumable(storageRef, profilePic);
          const uploadThumbTask = uploadBytesResumable(storageThumbnailRef, profileThumbnailPic);
      
        
        
        try {
          setProgressValue(15);
          await uploadTask;
          //console.log("Await uploadtask done");
          setProgressValue(50);
          await uploadThumbTask;
          //console.log("Await uploadThumbtask done");
          setProgressValue(70);
          downloadURL = await getDownloadURL(storageRef);
          setProgressValue(80);
          thumbnailURL = await getDownloadURL(storageThumbnailRef);
          setProgressValue(90);
          //console.log("Thumbnail url: " + thumbnailURL);
          //console.log("Download url: " + downloadURL);
          data.picUrl = downloadURL;
          data.thumbUrl = thumbnailURL;
          setProgressValue(100);
        } catch (error) {
          //console.log('Error uploading image:', error);
          showErrorToast("Jotain meni pieleen kuvien lataamisessa palvelimelle")
        } 
    } else if (!existingImageUrl) {
      //console.log("No display pic and no existingImageUrl either");
        //console.log("Cannot create user without profile picture.");
        setImageMandatoryError(true);
        showErrorToast("Profiilikuva on pakollinen")
        return;
       
    }
    const userDocRef = doc(db, "users", userId);
    await updateDoc(userDocRef, data);
    //console.log("User data updated successfully! Navigating to account");
    navigate("/account");
  };



  
  const handleCreateUser = async (data) => {

    //console.log("handleCreateUser");
    const userId = user.uid;
    setIsLoading(true);
    // Validate age range
    const { partner_min_age, partner_max_age } = data;
    if (parseInt(partner_min_age) > parseInt(partner_max_age)) {
      //console.log('Minimum age cannot be larger than maximum age.');
      setAgeRangeError("Minimi-ikä ei voi olla suurempi kuin maksimi-ikä")
      showErrorToast("Minimi-ikä ei voi olla suurempi kuin maksimi-ikä")
      return;
    }

    let downloadURL = null;
    let thumbnailURL = null; 

    if (!displayPic) {
      //console.log("Cannot create user without profile picture.");
      setImageMandatoryError(true);
      showErrorToast("Profiilikuva on pakollinen")
      return;
    } else { //user has updated image, if not, then we save just everything else but don't upload images
      const profileThumbnailPic = await resizeThumbPic(displayPic);
      //const [downloadURL, thumbnailURL] = await uploadImages(userId, displayPic, profileThumbnailPic);
  

          const storageRef = ref(storage, `user_profile_images/${userId}/${displayPic.name}`);
          const storageThumbnailRef = ref(storage, `user_profile_images/thumbnails/${userId}/${displayPic.name}`);
          const uploadTask = uploadBytesResumable(storageRef, displayPic);
          const uploadThumbTask = uploadBytesResumable(storageThumbnailRef, profileThumbnailPic);
      
        
        
        try {
          setProgressValue(15);
          await uploadTask;
          //console.log("Await uploadtask done");
          setProgressValue(50);
          await uploadThumbTask;
          //console.log("Await uploadThumbtask done");
          setProgressValue(70);
          downloadURL = await getDownloadURL(storageRef);
          setProgressValue(80);
          thumbnailURL = await getDownloadURL(storageThumbnailRef);
          setProgressValue(90);
          //console.log("Thumbnail url: " + thumbnailURL);
          //console.log("Download url: " + downloadURL);
          data.picUrl = downloadURL;
          data.thumbUrl = thumbnailURL;
          setProgressValue(100);
        } catch (error) {
          //console.log('Error uploading image:', error);
          showErrorToast("Jotain meni pieleen kuvien lataamisessa palvelimelle")
        }
       
      
    }


  
  

    await setDoc(doc(usersCollectionRef, userId), {
      ...data,
      meet_tokens: 5,
      max_meet_tokens: 5,
      blocked_by_uids: [],
      blocked_uids: [],
      picUrl: downloadURL,
      thumbUrl: thumbnailURL,
      heartPoints: 1,
      gems: 10,
      down_votes: [],
      uid: user.uid,
      max_gems: 10,
      archived: false,
      notificationsOn: true,
      fcmToken: null,
      likes_uids: [],
      liked_by_uids: [],
      hideProfile: false,
    });
  
    //await setDoc(doc(usersCollectionRef, userId), dataWithDefaults);
    //console.log("New user created successfully! Navigate to account");
    navigate("/account");
  };
  


/*   const handleFormSubmitOld = async (data) => {
    //console.log("handleFormSubmit")
    try {

      setIsLoading(true);
      
      let downloadURL = null;
      let thumbnailURL = null;

      //const maxSizeInBytes = 10 * 1024 * 1024;
      const userId = user.uid;

      const userRef = doc(db, "users", userId);
      const userDoc = await getDoc(userRef);
      
      //console.log("Trying to save userdata for uid: " + user?.uid)
      const { partner_age_min, partner_age_max } = data;
      
    // Validate age range
    if (parseInt(partner_age_min) > parseInt(partner_age_max)) {
      ////console.log('Minimum age cannot be larger than maximum age.');
      setAgeRangeError("Minimi-ikä ei voi olla suurempi kuin maksimi-ikä")
      return;
    } else {
      setAgeRangeError(null)
    }

      let oldImageUrl = null;
      let oldThumbImageUrl = null;
      
      if (userDoc.exists() && picHasBeenChanged) {
        const existingUserData = userDoc.data();
        //console.log("Old image urls:")
        oldImageUrl = existingUserData.picUrl;
        //console.log(oldImageUrl)
        oldThumbImageUrl = existingUserData.thumbUrl;
        //console.log(oldThumbImageUrl)
      }
          
     
      if (profilePic) {

        if (picHasBeenChanged) {

          const profileThumbnailPic = await resizeProfilePic(profilePic);
          //console.log("Profile pic resized: " + profileThumbnailPic)
          const storageRef = ref(storage, `user_profile_images/${userId}/${profilePic.name}`);
          const storageThumbnailRef = ref(storage, `user_profile_images/thumbnails/${userId}/${profilePic.name}`);
          const uploadTask = uploadBytesResumable(storageRef, profilePic);
          const uploadThumbTask = uploadBytesResumable(storageThumbnailRef, profileThumbnailPic);
          //console.log("Storage ref and upload task done ")
          uploadTask.on('state_changed', (snapshot) => {
            // Get the current upload progress
            //const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            //setUploadProgress(progress); // Update the progress state
          });
          uploadThumbTask.on('state_changed', (snapshot) => {
            // Get the current upload progress
            //const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            //setUploadProgress(progress); // Update the progress state
          });

          
          try {
            await uploadTask;
            ////console.log("Await uploadtask done");
            await uploadThumbTask;
            ////console.log("Await uploadThumbtask done");
            downloadURL = await getDownloadURL(storageRef);
            thumbnailURL = await getDownloadURL(storageThumbnailRef);
            ////console.log("Thumbnail url: " + thumbnailURL);
            ////console.log("Download url: " + downloadURL);

             if (oldImageUrl && oldThumbImageUrl && picHasBeenChanged) {
              const oldImageRef = ref(storage, oldImageUrl);
              const oldThumbImageRef = ref(storage, oldThumbImageUrl);
              await deleteObject(oldImageRef);
              await deleteObject(oldThumbImageRef);
            } 

          } catch (error) {
            //console.log('Error uploading image:', error);
            // Handle the error as needed
          }

        }
      }
      
      //if (querySnapshot.size > 0) {
        if (userDoc.exists()) {
        if (picHasBeenChanged) {

          data.picUrl = downloadURL;
          data.thumbUrl = thumbnailURL;
        }
        ////console.log("Document exists, here is form data:", data);
        // Document exists, update it
        
        const userDocRef = doc(db, "users", userId);
        await updateDoc(userDocRef, data);
        ////console.log("User data updated successfully!");
      } else {
        // Document doesn't exist, create a new one
        await setDoc(doc(usersCollectionRef, userId), {
          ...data,
          meet_tokens: 5,
          max_meet_tokens: 5,
          blocked_by_uids: [],
          blocked_uids: [],
          picUrl: downloadURL,
          thumbUrl: thumbnailURL,
          heartPoints: 1,
          gems: 5,
          down_votes: [],
          uid: user.uid,
          max_gems: 10,
          archived: false
        });
      }
      // Navigate to the account page
      
      setIsLoading(false)
      navigate("/account");
    } catch (error) {
      console.error("Error saving form data:", error);
    }
  }; */

  const goBack = () => {
    navigate("/account");
  };

  useEffect(() => {
    ////console.log('user in useeffect:', user.uid);
    fetchUserData();
    setLocations(locationsJson);
  }, [user]);

  // Assuming 'userData' is fetched from your database and contains the old format
/* useEffect(() => {
  if (userData && userData.birthday) {
    const newDateFormat = convertDateFormat(userData.birthday);
    setValue("birthday", newDateFormat);
  }
}, [userData, setValue]); */

/*   useEffect(() => {
    const validatePic = async () => {
      if ((!watchDisplayPic && !watchProfilePic) && !picHasBeenChanged) {
        setImageMandatoryError(true); // Set custom error state
      } else {
        setImageMandatoryError(false); // Clear error state
       
      }
    };
  
    validatePic(); // Initial validation
  
    // Re-run validation on changes to displayPicURL and profilePic

  }, [watchDisplayPic, watchProfilePic, setError, picHasBeenChanged]); */
  





  const deleteOldProfileImages = async () => {
    try {
      if (existingImageUrl && existingThumbUrl) {
        const oldImageRef = ref(storage, existingImageUrl);
        const oldThumbImageRef = ref(storage, existingThumbUrl);

        await deleteObject(oldImageRef);
        await deleteObject(oldThumbImageRef);

        //console.log('Old profile images deleted successfully.');
        setExistingImageUrl(null);
        setExistingThumbUrl(null);
      }
    } catch (error) {
      console.error('Error deleting old meet image:', error);
      // Handle the error as needed
    }
  };

  const handlePicChange = (e) => {
    //console.log("HandlePicChange called")
    const newFile = e.target.files[0];
    setDisplayPicURL(URL.createObjectURL(newFile)); //just the url for display
    setDisplayPic(newFile); //file object
    deleteOldProfileImages();
    setImageMandatoryError(false);
    //setPicHasBeenChanged(true);
    //setProfilePic(newFile);
  };

 

/*   const validateAge = (value) => {
    if (!value) {
      return 'Syntymäpäivä on pakollinen';
    }
    const age = calculateAge(value);
    if (age < 18) {
      return 'Et voi käyttää sovellusta, koska olet alle 18-vuotias.';
    }

    const [day, month, year] = value.split('.').map(Number);

    // Get the last day of the specified month
    const lastDayOfMonth = new Date(year, month, 0).getDate();
  
    // Additional validation for the day based on the month
    if (day < 1 || day > lastDayOfMonth) {
      return 'Virheellinen päivämäärä';
    }

    return undefined; // No validation error
  }; */

  const validateAge = (value) => {
    // Check if the birthdate is provided
    if (!value) {
      return 'Syntymäpäivä on pakollinen';
    }
  
    // Validate date format strictly (only dd.mm.yyyy allowed)
    const datePattern = /^\d{1,2}\.\d{1,2}\.\d{4}$/; // 1 or 2 digits for day/month
  if (!datePattern.test(value)) {
    return 'Virheellinen päivämäärämuoto (pp.kk.vvvv tai p.k.vvvv)';
  }
  
    const [day, month, year] = value.split('.').map(Number);
  
    // Basic checks for valid numerical values and reasonable year range
    if (
      isNaN(day) ||
      isNaN(month) ||
      isNaN(year) ||
      year < 1900 || year > new Date().getFullYear() ||
      month < 1 || month > 12 
    ) {
      return 'Virheellinen päivämäärä';
    }
  
    // Adjust month (January is 0 in JavaScript)
    const adjustedMonth = month - 1;
  
    // Get the last day of the specified month
    const lastDayOfMonth = new Date(year, adjustedMonth + 1, 0).getDate();
  
    // Check if day is valid for the given month
    if (day < 1 || day > lastDayOfMonth) {
      return 'Virheellinen päivämäärä';
    }
  
    // Create a Date object and calculate age
    const birthday = new Date(year, adjustedMonth, day);
    const ageDiffMs = Date.now() - birthday.getTime();
    const ageDate = new Date(ageDiffMs);
    const age = Math.abs(ageDate.getUTCFullYear() - 1970);
  
    // Check if user is over 18 years old
    if (age < 18) {
      return 'Et voi käyttää sovellusta, koska olet alle 18-vuotias.';
    }
  
    return undefined; // No validation error
  };
  
  


  if (!user) {
    return <div>Loading...</div>; // or show a loading state
  } else {
    //////console.log("currentUser in else: " + user)
    return (

      <div className="page-container">

        <nav className="context-navbar">
          <ul>
            <li><a class="secondary" onClick={goBack}><IconArrowLeft size={24} /></a></li>
          </ul>
          <ul>
            <li><strong>Muokkaa profiilia</strong></li>
          </ul>
          <ul>
            <li><a href="#" disabled={imageMandatoryError} onClick={handleSubmit(handleFormSubmit)} role="button">Tallenna</a></li>
        

          </ul>
          
        </nav>

        <ToastContainer />

        {isLoading && (
         <div className="loading-screen">
            {/* <progress></progress> */}
            <progress value={progressValue} max="100" />
         </div>
      )}

        <form onSubmit={handleSubmit(handleFormSubmit)}>


          {/* Progress bar */}
{/*           {uploadProgress > 0 && uploadProgress < 100 && (
            <div>
              <progress value={uploadProgress} max="100"></progress>
              {uploadProgress.toFixed(2)}% Complete
            </div>
          )} */}
          <article>
            <header>Esittely</header>


            {/*  <label htmlFor="file">Muokkaa ja lataa kuvia</label>

              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {picUrls.map((picUrl, index) => (
                  <div key={index} style={{ marginRight: '10px' }}>
                    <img src={picUrl.url} alt={`Image ${index + 1}`} />
                    <button type="button" onClick={() => handleRemoveImage(picUrl.filename)} style={{ border: 'none', background: 'none', padding: 0 }}>
                      <IconCircleX />
                    </button>
                  </div>
                ))}
              </div>

              <input
                type="file"
                id="file"
                name="file"
                onChange={handleFileUpload}
                disabled={picUrls.length >= 6}
              /> */}
           {/*  <label htmlFor="file">Profiilikuva</label> 
            <br />*/}
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',

              }}
            >

{/* {Object.keys(errors).length > 0 && (
  <ul style={{ color: 'red' }}>
    {Object.entries(errors).map(([field, error]) => (
      <li key={field}>
        {field}: {error.message}
      </li>
    ))}
  </ul>
)} */}






              <img
                key={existingThumbUrl || displayPicURL || placeholderImage}
                src={displayPicURL || existingThumbUrl || placeholderImage}
                alt="Selected Image"
                style={{
                  width: '100px',
                  height: '100px',
                  borderRadius: '50%',
                  objectFit: 'cover',
                  objectPosition: 'top'
                }}
                onError={(e) => {
                  ////console.log("Error in loading profile pic " + e + " which seems to be " + profilePic);
                  e.target.src = placeholderImage;
                }}
              />
            </div>
           
            <br />
            <div style={{ textAlign: 'center' }}>

<br/>

{/*  <input
  type="hidden"
  {...register("hasImage", { required: "Kuva on pakollinen" })}
  value={displayPicURL || profilePic} // Updated value logic
/>  */}

<label htmlFor="file" style={customButtonStyle}>Lataa profiilikuva</label>


<input
  type="file"
  id="file"
  name="file"
  onChange={handlePicChange}
  accept="image/*"
  style={fileUploadStyle}
  
/> 
{/* 
{errors.file && touched.file (<p style={{ color: 'red' }}>{errors.file.message}</p>)} */}
{errors.file && ( // Check for both errors and touched state
  <p style={{ color: 'red' }}>{errors.file.message}</p>
)}

{imageMandatoryError && (
  <p style={{ color: 'red' }}>Profiilikuva on pakollinen</p>
)}


            </div>
            {/* Display error message for image size */}
            {uploadError && (
              <div style={{ color: 'red' }}>
                {uploadError}
                <br /><br />
              </div>
            )}
           
              <p>
                <small>
                  Voit ladata profiiliisi vain yhden kuvan, joten varmista, että se on paras ja aidoin versio itsestäsi. Voit liittää omiin tapaamisiisi myöhemmin lisää kuvia.
                </small>
              </p>

            {/* 

            <label htmlFor="file">Lataa itsellesi profiilikuva</label>
            <input
              type="file"
              id="file"
              name="file"
              onChange={handlePicUpload}
              required
              accept="image/*"
              {...register("file", { required: "Kuva on pakollinen" })}
              {...(errors.file ? { 'aria-invalid': 'true' } : {})}
            />
            {errors.file ? (
              <small className="error">{errors.file.message}</small>
            ) : (
              <p>
                <small>
                  Voit ladata profiiliisi vain yhden kuvan, joten varmista, että se on paras ja aidoin versio itsestäsi. Voit liittää omiin tapaamisiisi myöhemmin lisää kuvia.
                </small>
              </p>
            )}
 */}

            <br />
            <label htmlFor="bioText">Vapaa kuvausteksti (valinnainen)</label>
            <textarea
              id="bioText"
              placeholder="Kerro lyhyesti itsestäsi ja mikä tekee sinusta kiinnostavan. Voit mainita harrastuksesi, haaveesi, intohimosi tai ainutlaatuiset piirteesi. Pieni ripaus huumoriakaan ei ole pahitteeksi."
              {...register("bio_text")}
              maxLength={500}
              rows={7}
              cols={50}
            />

          </article>

          <article>
            <header>Perustiedot</header>
            <label htmlFor="name">Anna etunimesi</label>
            <input
              type="text"
              {...register("name", { required: "Nimi on pakollinen" })}

              {...(errors.name ? { 'aria-invalid': 'true' } : {})}
            />
            {errors.name && <small className="error">{errors.name.message}</small>}

            <label htmlFor="email">Sähköpostiosoite</label>
            <input
              type="email"
              id="email"
              {...register("email", {
                required: "Sähköpostiosoite on pakollinen",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Syötä kelvollinen sähköpostiosoite",
                },
              })}
              {...(errors.email ? { 'aria-invalid': 'true' } : {})}

            />
            {errors.email ? (
              <small className="error">{errors.email.message}</small>
            ) : (
              <small>Sähköpostiosoitettasi ei näytetä kenellekään</small>
            )}

            <label htmlFor="snapchat">Snapchat (valinnainen)</label>
            <input
              type="text"
              {...register("snapchat")}
              {...(errors.snapchat ? { 'aria-invalid': 'true' } : {})}
            />
            {errors.snapchat && <small className="error">{errors.snapchat.message}</small>}

            <label htmlFor="instagram">Instagram (valinnainen)</label>
            <input
              type="text"
              {...register("instagram")}
              {...(errors.instagram ? { 'aria-invalid': 'true' } : {})}
            />
            {errors.instagram && <small className="error">{errors.instagram.message}</small>}

{/*             <label htmlFor="birthday">Syntymäpäivä</label>
            <input
              type="date"
              id="birthday"
              {...register("birthday", {
                required: "Syntymäpäivä on pakollinen",
                validate: validateAge, // Import and use the validation function
              })}
            />
            {errors.birthday && (
              <small className="error">{errors.birthday.message}</small>
            )}
  */}

<label htmlFor="birthday">Syntymäpäivä (pp.kk.vvvv tai p.k.vvvv)</label>
<input
  type="text"
  id="birthday"
  placeholder="pp.kk.vvvv"
  {...register("birthday", {
    required: "Syntymäpäivä on pakollinen",
    /* pattern: {
      value: /^\d{2}\.\d{2}\.\d{4}$/,
      message: "Virheellinen päivämäärämuoto (pp.kk.vvvv)"
    }, */
    validate: validateAge
  })}
/>
{errors.birthday && (
  <small className="error">{errors.birthday.message}</small>
)} 

            <label htmlFor="gender">Sukupuoli</label>
            <select id="gender" name="gender" required {...register('gender')}>
              {genderOptions.map((option) => (
                <option key={option.value} value={option.value}>{option.label}</option>
              ))}
            </select>

            <label htmlFor="height">Pituus (cm)</label>
            <input
              type="number"
              id="height"
              name="height"
              {...register('height', {
                required: 'Pituus on pakollinen tieto'
              })}
              {...(errors.height ? { 'aria-invalid': 'true' } : {})}
            />
            {errors.height && (
              <small>
                {errors.height.message}
              </small>
            )}


            <label htmlFor="location">Asuinpaikkakunta</label>
            {/* <input
              type="text"
              id="location"
              name="location"
              {...register('location', {
                required: 'Asuinpaikkakunta on pakollinen tieto',
              })}
              {...(errors.location ? { 'aria-invalid': 'true' } : {})}
            />
            {errors.location && (
              <small>{errors.location.message}</small>
            )} */}

<select
              id="location"
              {...register('location', {
                required: 'Sijainti on pakollinen valinta',
              })}
              name="location"
            >
              <option value="" disabled>
                Valitse paikkakunta
              </option>
              {locations.map((location) => (
                <option key={location.name} value={location.name}>
                  {location.name}
                </option>
              ))}
            </select>

            {errors.location && (
              <small className="error">{errors.location.message}</small>
            )}

            <label htmlFor="starSign">Horoskooppimerkki</label>
            <select
              id="starSign"
              name="starSign"
              defaultValue={starSignOptions[0].value}
              {...register('starSign')}
            >
              {starSignOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>


          </article>

          <article>
            <header>Toiveet</header>

            <label htmlFor="genderInterest">Haluan tavata</label>
            <select id="genderInterest" name="gender_interest" required {...register('gender_interest')}>
              {genderInterestOptions.map((option) => (
                <option key={option.value} value={option.value}>{option.label}</option>
              ))}
            </select>

            <label htmlFor="partner_min_age" className="label">Ikähaitari</label>
            <input
              type="number"
              id="partner_min_age"
              name="partner_min_age"
              min={18}
              max={100}
              {...register('partner_min_age', { required: true, min: 18, max: 100 })}
              style={{ width: '6rem' }}
              {...(errors.partner_min_age ? { 'aria-invalid': 'true' } : {})}
            />

            <span className="input-narrow"> - </span>

            <input
              type="number"
              id="partner_max_age"
              name="partner_max_age"
              min={18}
              max={100}
              {...register('partner_max_age', { required: true, min: 18, max: 100 })}
              style={{ width: '6rem' }}
              {...(errors.partner_max_age ? { 'aria-invalid': 'true' } : {})}
            />
            <small>{errors.partner_min_age && "Ikäryhmän minimi-ikä vaaditaan (18-100)"}</small>
            <small>{errors.partner_max_age && "Ikäryhmän maksimi-ikä vaaditaan (18-100)"}</small>

            {ageRangeError && (
              <div>
                <small className="error">{ageRangeError}</small>   
                <br/><br/>
              </div>
                  
              )}

            <label htmlFor="distance_for_meets">Etäisyys tapaamisille (km)</label>
            <input
              type="number"
              id="distance_for_meets"
              name="distance_for_meets"
              defaultValue={300}
              min={5}
              max={300}
              {...register('distance_for_meets', {
                required: true,
                min: 5,
                max: 300
              })}
              {...(errors.distance_for_meets ? { 'aria-invalid': 'true' } : {})}
            />
            {errors.distance_for_meets && (
              <small>
                Etäisyys tapaamisille on pakollinen (5-300 km)
              </small>
            )}

            <label htmlFor="looking_for">Toivoisin</label>
            <select id="looking_for" name="looking_for" required {...register('looking_for')}>
              {lookingForOptions.map((option) => (
                <option key={option} value={option}>{option}</option>
              ))}
            </select>
          </article>

          <article>
            <header>Työ ja koulutus</header>

            <label htmlFor="education">Koulutus</label>
            <select id="education" name="education" required {...register('education')}>
              {educationOptions.map((option) => (
                <option key={option} value={option}>{option}</option>
              ))}
            </select>


            <label htmlFor="occupation">Ala tai ammatti</label>
            <input
              type="text"
              id="occupation"
              name="occupation"
              {...register('occupation', {
                required: 'Ala tai ammatti on pakollinen tieto'
              })}
              {...(errors.occupation ? { 'aria-invalid': 'true' } : {})}
            />
            {errors.occupation && (
              <small>
                {errors.occupation.message}
              </small>
            )}


          </article>

          <article>
            <header>Elämäntavat</header>

            <label htmlFor="alcohol">Alkoholi</label>
            <select id="alcohol" name="alcohol" required {...register('alcohol')}>
              {alcoholOptions.map((option) => (
                <option key={option} value={option}>{option}</option>
              ))}
            </select>
            {errors.alcohol && (
              <small>
                {errors.alcohol.message}
              </small>
            )}

            <label htmlFor="smoking">Tupakointi</label>
            <select id="smoking" name="smoking" required {...register('smoking')}>
              {smokingOptions.map((option) => (
                <option key={option} value={option}>{option}</option>
              ))}
            </select>
            {errors.smoking && (
              <small>
                {errors.smoking.message}
              </small>
            )}

            <label htmlFor="exercise">Liikunta</label>
            <select id="exercise" name="exercise" required {...register('exercise')}>
              {exerciseOptions.map((option) => (
                <option key={option} value={option}>{option}</option>
              ))}
            </select>
            {errors.exercise && (
              <small>
                {errors.exercise.message}
              </small>
            )}

          </article>

          <article>
            <header>Elämäntilanne</header>

            <label htmlFor="relationship_status">Suhdetilanne</label>
            <select id="relationship_status" name="relationship_status" required {...register('relationship_status')}>
              {relationshipStatusOptions.map((option) => (
                <option key={option} value={option}>{option}</option>
              ))}
            </select>
            {errors.relationship_status && (
              <small>
                {errors.relationship_status.message}
              </small>
            )}

            <label htmlFor="kids">Lapset</label>
            <select id="kids" name="kids" required {...register('kids')}>
              {kidsOptions.map((option) => (
                <option key={option} value={option}>{option}</option>
              ))}
            </select>
            {errors.kids && (
              <small>
                {errors.kids.message}
              </small>
            )}

            <label htmlFor="pets">Lemmikit</label>
            <select id="pets" name="pets" required {...register('pets')}>
              {petsOptions.map((option) => (
                <option key={option} value={option}>{option}</option>
              ))}
            </select>
            {errors.pets && (
              <small>
                {errors.pets.message}
              </small>
            )}


          </article>

         

          {/* 
        
        //elämäntavat
        exercise: "", //mandatory, string, select input with options: "En juurikaan liiku", "Vain arjen hyötyliikuntaa", "Liikun satunnaisesti", "Usein", "Päivittäinen elämäntapa", label "Liikunta"
        smoking: "", //mandatory, string, select input with values "En polta", "Vain juhliessa", "Päivittäin", "Vain sähkötupakkaa", "Vain nuuskaa", "Ole minulle syy lopettaa", label "Tupakointi"
        alcohol: "", //mandatory, string, select input with values "En juo", "Harvemmin", "Viikonloppuisin", "Päivittäin", "Haluaisin vähentää", label "Alkoholi"

        Elämäntilanne:
        relationship_status: "", //mandatory, string, select input with options: "Sinkku", "Parisuhteessa", "Eettisesti monisuhteinen", "Avoimessa suhteessa", "Tilanne epäselvä", label "Suhdetilanne"
        kids: "", //mandatory, string, select input with options: "Ei, eikä ole toiveissa", "Ei, mutta sinulla saa olla", "Kyllä, asuvat kanssani", "Kyllä, eivät asu kanssani koko aikaa", "Ei, mutta haluaisin", label "Lapsia"
        pets: "", //mandatory, string, select input with options: "Ei", "Ei, mutta tykkään", "Olen allerginen", "Kyllä, koiria", "Kyllä, kissoja", "Kyllä, jotain muuta", "Eläintarha", label "Lemmikit" */}


        </form>
      </div>


    )
  }
}

export default Profile

