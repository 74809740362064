import React, { useEffect, useState } from "react";
import MainNavbar from "../components/MainNavbar";
import MeetListing from "../components/MeetListing";
import LogoBar from "../components/LogoBar";
import { UserAuth } from "../context/AuthContext";
import { db } from "../config/firebase";
import {
  getDoc,
  doc,
} from "firebase/firestore";

const Meets = () => {
  const { user } = UserAuth();
  const [userProfileExists, setUserProfileExists] = useState(null);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const userRef = doc(db, "users", user.uid); // Replace user.uid with the actual user ID logic
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          setUserProfileExists(true); // Profile exists
        } else {
          setUserProfileExists(false); // Profile does not exist
        }
      } catch (error) {
        console.error("Error fetching user profile:", error);
        setUserProfileExists(false); // Set false on error to fail gracefully
      } 
    };

    if (user?.uid) {
      fetchUserProfile();
    }
  }, [user]);

  return (
    <div className="page-container">
      <LogoBar />
      <MainNavbar />
     
      {userProfileExists ? (
        <MeetListing />
      ) : (
        <div>
           <strong>Lähellä olevat ihmiset </strong>
           <br/><br/>
          <p>Sinun pitää luoda itsellesi profiili voidaksesi selata tapaamisia
          </p>
          {/* Any other relevant message or UI */}
        </div>
      )}
   
     {/*  <div className="tabs">
        <button
          className={!isNearbyMode ? "active" : ""}
          onClick={handleTabClick}
        >
          Selaa tapaamisia
        </button>
        <button
          className={isNearbyMode ? "active" : ""}
          onClick={handleTabClick}
        >
          Selaa käyttäjiä
        </button>
      </div>

      {isNearbyMode ? <Nearby /> : <MeetListing />} */}


    </div>
  );
};

export default Meets;
