
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconChevronLeft } from '@tabler/icons-react';
import { UserAuth } from '../context/AuthContext';
import slipperLogo from '../assets/SlipperIcon.png';
import { db } from "../config/firebase";
import {
  query,
  where,
  getDocs,
  getDoc,
  collection,
  updateDoc,
  doc,
  writeBatch,
  commitBatch,
  arrayRemove
} from "firebase/firestore";

const HideProfile = () => {
  const navigate = useNavigate();
  const { user } = UserAuth();
  const [isHidden, setIsHidden] = useState(false);
  const goBack = () => {
    navigate('/account');

  };


  const updateLikes = async () => {
    try {

      // Get current user document (outside the batch)
      const userRef = doc(db, "users", user.uid);
      //const userDocSnap = await getDoc(userRef);

      /* let hide;
      if (userDocSnap.exists) {
        hide = userDocSnap.data().hideProfile;
      } else {
        hide = true; // Set default if document doesn't exist
      }
     */
      const batch = writeBatch(db);
      // Update current user's "liked_by_uids"
      batch.update(userRef, { liked_by_uids: [] });
      //console.log('Updated current user liked_by_uids to empty array');

      // Query all users (excluding current user)
      const usersQuery = query(collection(db, "users"), where("uid", "!=", user.uid));
      const querySnapshot = await getDocs(usersQuery);

      // Update "likes_uids" in other users' documents (within the batch)
      querySnapshot.forEach((doc) => {
        const userDocData = doc.data();
        if (userDocData.likes_uids?.includes(user.uid)) {
          /* batch.update(doc.ref, { likes_uids: arrayRemove(userDocData.likes_uids, user.uid) }); */
          batch.update(doc.ref, { likes_uids: arrayRemove(user.uid) });
          //console.log(`User ${userDocData.uid} - Removed ${user.uid} from likes_uids`);
        } else {
          //console.log(`User ${userDocData.uid} - No update needed (not in likes_uids)`);
        }
      });

      // Update hideProfile for current user (within the batch)
      //batch.update(userRef, { hideProfile: newState !== undefined ? newState : hide });
      //console.log('Updated current user hideProfile');

      // Commit the batch write
      await batch.commit();

      //console.log('User profile hidden and likes updated successfully (with batch writes)!');

    } catch (err) {
      console.error('Error hiding profile and updating likes:', err);

      // Handle errors (e.g., retry or log the error)
    }
  };

  const hideProfile = async (isHidden) => {
    try {

      const userRef = doc(db, "users", user.uid);

      await updateDoc(userRef, { hideProfile: isHidden });
      await updateLikes();

      // Update local state if needed
      if (isHidden) {

        setIsHidden(true);
        navigate('/account');
      } else {
        setIsHidden(false);
        navigate('/account');
      }

      // Show success message
      //console.log('Profile hidden/unhidden successfully!');
    } catch (err) {
      console.error('Error hiding/unhiding profile:', err);
    }
  };


  useEffect(() => {
    const checkIfHidden = async () => {
      if (user) {
        const userRef = doc(db, "users", user.uid); // Reference user document
        const userDocSnap = await getDoc(userRef);

        if (userDocSnap.exists) {
          setIsHidden(userDocSnap.data().hideProfile || false); // Set default if missing
        }
      } else {
        // User is not signed in
        setIsHidden(false); // Reset state if user signs out
      }
    };

    // Since unsubscribe is handled elsewhere, remove it from here
    checkIfHidden(); // Call initially with current user

  }, []);

  return (
    <div className="page-container">
      <nav style={{ display: 'flex', paddingRight: '10px' }}>
        <ul>
          <li>
            <a href="#" onClick={goBack}>
              <IconChevronLeft size={35} />
            </a>
          </li>
          <li>
            <strong>Piilota profiilisi</strong>
          </li>
        </ul>
      </nav>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img src={slipperLogo} alt="Slipper Logo" style={{ height: '100px', width: 'auto' }} />
        <br />
        {isHidden ? (
          <div>
            <strong style={{ color: 'gold' }}>Profiilisi on piilossa</strong>

            <br /> <br />
           
          </div>
        ) : (
          <div></div>
        )}
        <p>
          Kun olet piilossa, sinua ei näytetä lähistöllä olevien käyttäjien näkymässä, mutta luomasi tapaamiset ovat edelleen normaalisti avoinna. Sinut myös poistetaan automaattisesti kaikkien käyttäjien suosikkilistoilta.

        </p>

        {isHidden ? (
          <div>
           
            <button onClick={() => hideProfile(false)}>Palauta profiili näkyviin</button>
          </div>
        ) : (
          <button onClick={() => hideProfile(true)}>Haluan piilottaa profiilini</button>
        )}
      </div>


    </div>
  );

};

export default HideProfile;