import React, { useState, useEffect } from "react";
import MainNavbar from "../components/MainNavbar";
import Nearby from "../components/Nearby";
import LogoBar from "../components/LogoBar";
import { UserAuth } from "../context/AuthContext";
import { db } from "../config/firebase";
import {
  getDoc,
  doc,
} from "firebase/firestore";

const Users = () => {
  const { user } = UserAuth();
  const [userProfileExists, setUserProfileExists] = useState(null);


  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const userRef = doc(db, "users", user.uid); // Replace user.uid with the actual user ID logic
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          setUserProfileExists(true); // Profile exists
        } else {
          setUserProfileExists(false); // Profile does not exist
        }
      } catch (error) {
        console.error("Error fetching user profile:", error);
        setUserProfileExists(false); // Set false on error to fail gracefully
      } 
    };

    if (user?.uid) {
      fetchUserProfile();
    }
  }, [user]);


  return (
    <div className="page-container">
      <LogoBar />
      <MainNavbar />
      {userProfileExists ? (
       <Nearby />
      ) : (
        <div>
          <strong>Lähellä olevat ihmiset </strong>
          <br/><br/>
          <p>Sinun pitää luoda itsellesi profiili voidaksesi selata muita käyttäjiä
          </p>
          {/* Any other relevant message or UI */}
        </div>
      )}
      
      
    </div>
  );
};

export default Users;
