import React, { useEffect, useState } from 'react';
import { UserAuth } from '../context/AuthContext';
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { IconChevronLeft } from '@tabler/icons-react';
import UserPreview from '../components/UserPreview';
import { collection, doc, deleteDoc, writeBatch, where, getDocs, query, getDoc, orderBy } from "firebase/firestore";
import { db } from "../config/firebase";

const ActionsLog = () => {
  const { user } = UserAuth();
  //const { uid } = useParams();
  const [actions, setActions] = useState([]);
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);

  const goBack = () => {
    window.history.back();
  };

  /* const getTimeDifference = (timestamp) => {
    const now = Date.now();
    const difference = now - timestamp.toDate().getTime();
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

    if (days) {
      return `${days} day${days > 1 ? 's' : ''} ago`;
    } else {
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    }
  }; */

  const getTimeDifference = (createdAt) => {
    const now = new Date();
    const diffInMs = now - createdAt.toDate(); // Assuming Firestore Timestamp
    const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
    const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
    const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

    if (diffInMinutes < 60) {
        return `${diffInMinutes} minuuttia sitten`; // minutes ago
    } else if (diffInHours < 24) {
        return `${diffInHours} tuntia sitten`; // hours ago
    } else if (diffInDays < 30) {
        return `${diffInDays} päivää sitten`; // days ago
    } else {
        return `Yli kuukausi sitten`; // Over a month ago
    }
};


const fetchUserAvatars = async (userIds) => {
  try {
      const userPromises = userIds.map(async (uid) => {
          const userRef = doc(db, "users", uid);
          const userDoc = await getDoc(userRef);

          if (userDoc.exists()) {
              const userData = userDoc.data();
              console.log(`Fetched user data for ${uid}:`, userData); // Log user data
              return {
                  userId: uid,
                  thumbUrl: userData.thumbUrl,
              };
          } else {
              console.error(`User with UID ${uid} not found`);
              return null;
          }
      });

      const usersData = await Promise.all(userPromises);

      const validUsers = usersData.filter(user => user !== null);
      return validUsers;

  } catch (error) {
      console.error('Error fetching user avatars:', error);
  }
};


const getActions = async () => {
  try {
    const actionsRef = collection(db, "actions");

    // Query actions for the current user without using `orderBy`
    const q = query(
      actionsRef,
      where('receiver', '==', user.uid)
    );

    const querySnapshot = await getDocs(q);

    const now = new Date();
    const oneMonthAgo = new Date(now.getTime() - 4 * 7 * 24 * 60 * 60 * 1000);

    const fetchedActions = [];
    const senderIds = new Set();
    const actionIdsToDelete = [];

    // Process each document in the query
    querySnapshot.forEach(doc => {
      const actionData = doc.data();
      const createdAt = actionData.createdAt.toDate();

      if (createdAt >= oneMonthAgo) {
        fetchedActions.push({
          id: doc.id,
          ...actionData
        });
        senderIds.add(actionData.sender);
      } else {
        // Mark action for deletion if it's older than one month
        actionIdsToDelete.push(doc.id);
      }
    });

    // Sort actions by 'createdAt' in descending order on the client side
    const sortedActions = fetchedActions.sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate());

    const uniqueSenderIds = Array.from(senderIds);

    // Fetch user avatars for the unique sender IDs
    const userAvatars = await fetchUserAvatars(uniqueSenderIds);

    // Merge sorted actions with the corresponding avatars
    const actionsWithAvatars = sortedActions.map(action => {
      const userAvatar = userAvatars.find(user => user.userId === action.sender);
      return {
        ...action,
        senderAvatar: userAvatar ? userAvatar.thumbUrl : null,
      };
    });

    // Set the sorted actions with avatars to state
    setActions(actionsWithAvatars);

    // Mark all displayed actions as read
    await markActionsAsRead(fetchedActions.map(action => action.id));

    // Delete actions older than one month
    if (actionIdsToDelete.length > 0) {
      await deleteOldActions(actionIdsToDelete);
    }

  } catch (error) {
    console.error('Error fetching or processing action documents:', error);
  }
};

// Function to mark actions as read
const markActionsAsRead = async (actionIds) => {
  const batch = writeBatch(db); // Start a batch for writing

  actionIds.forEach(actionId => {
    const actionRef = doc(db, "actions", actionId);
    batch.update(actionRef, { read: true }); // Set read to true
  });

  await batch.commit(); // Commit the batch write
};

// Function to delete actions
const deleteOldActions = async (actionIds) => {
  const batch = writeBatch(db); // Start a batch for deleting

  actionIds.forEach(actionId => {
    const actionRef = doc(db, "actions", actionId);
    batch.delete(actionRef); // Delete the action document
  });

  await batch.commit(); // Commit the batch delete
};




/* const getActions = async () => {
  try {
    const actionsRef = collection(db, "actions");
    
    const q = query(
      actionsRef,
      where('receiver', '==', user.uid)
    );

    const querySnapshot = await getDocs(q);

    const now = new Date();
    const oneMonthAgo = new Date(now.getTime() - 4 * 7 * 24 * 60 * 60 * 1000);

    const fetchedActions = [];
    const senderIds = new Set();

    querySnapshot.forEach(async (doc) => {
      const actionData = doc.data();
      const createdAt = actionData.createdAt.toDate();

      if (createdAt >= oneMonthAgo) {
        fetchedActions.push({
          id: doc.id,
          ...actionData
        });
        senderIds.add(actionData.sender);
      } else {
        await deleteDoc(doc.ref);
      }
    });

    const uniqueSenderIds = Array.from(senderIds);

    console.log('Unique Sender IDs:', uniqueSenderIds); // Log sender IDs

    const userAvatars = await fetchUserAvatars(uniqueSenderIds);

    console.log('Fetched User Avatars:', userAvatars); // Log fetched avatars

    const actionsWithAvatars = fetchedActions.map(action => {
      const userAvatar = userAvatars.find(user => user.userId === action.sender);
      console.log('Action Sender:', action.sender, 'User Avatar:', userAvatar); // Log each action with its avatar
      return {
        ...action,
        senderAvatar: userAvatar ? userAvatar.thumbUrl : null,
      };
    });

    setActions(actionsWithAvatars);

  } catch (error) {
    console.error('Error fetching or processing action documents:', error);
  }
}; */

  



  useEffect(() => {
    
    getActions();
  }, []);

 

  

const renderActionItem = (action) => (
  <div key={action.id} className="action-item">
    <img
      src={action.senderAvatar || 'path/to/default/avatar.png'}
      alt={action.senderName || 'Unknown'}
      className="action-avatar"
    />
    <div className="action-content">
      <div>{action.content}</div>
      <span className="timestamp">{getTimeDifference(action.createdAt)}</span>
    </div>
  </div>
);

  return (
    <div className="page-container">
      <nav style={{ display: 'flex', paddingRight: '10px' }}>
        <ul>
          <li>
            <a href="#" onClick={goBack}>
              <IconChevronLeft size={35} />
            </a>
          </li>
        </ul>
      </nav>
     
      <div className="actions-list">
        {actions.length > 0 ? (
          actions.map(renderActionItem)
        ) : (
          <p>Ei viimeaikaisia ilmoituksia</p>
        )}
      </div>
    </div>
  );
};

export default ActionsLog;
