import React, { useEffect, useState } from 'react';
import { UserAuth } from '../context/AuthContext';
import { collection, getDoc, doc, getDocs } from 'firebase/firestore';
import { db } from '../config/firebase';
import MeetCard from '../components/MeetCard';
import { IconChevronLeft } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';




const OwnMeets = () => {
  const { user } = UserAuth();
  const [userMeets, setUserMeets] = useState([]);
  const [meetsPerPage, setMeetsPerPage] = useState(10);

  const navigate = useNavigate();

  const goBack = () => {
    navigate('/account');

  };



  const sortMeetsFromLatest = (meets) => {
    return meets.slice().sort((a, b) => {
      const dateA = new Date(b.meet_date); // Use b instead of a for descending order
      const dateB = new Date(a.meet_date); // Use a instead of b for descending order
      return dateA - dateB;
    });
  };

  useEffect(() => {
    ////console.log("Meets: useEffect called");


    const fetchMeets = async () => {
      try {
        const snapshot = await getDocs(collection(db, "meets"));
        const meetDocs = snapshot.docs.map((doc) => ({ ...doc.data(), meetId: doc.id }));
        ////console.log("Meetdocs: " + meetDocs.length);

        const userRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userRef);

        let userInitialMeets;


        if (userDoc.exists()) {
          const thisUserData = userDoc.data();
          ////console.log(thisUserData);

          userInitialMeets = meetDocs.filter((meet) => {
            // Assuming user is your current user object, and meet.owner_user is the owner's user ID
            const isArchived = meet.archived;
            ////console.log("Omat miitit: This meet was archived: " + meet.archived)
            const isOwner = meet.owner_user === user.uid;
            ////console.log("Omat miitit: isOwner: " + isOwner)

            return !isArchived && isOwner;
          });

          /*  if (meet.owner_user === user.uid) {
             userInitialMeets.push(meet);
             ////console.log("Promise. Found owner user from a meet, now userInitialmeets has " + userInitialMeets.length);
           }
  */

          ////console.log("Number of initial userMeets:", userInitialMeets.length);

          const userMeetsSorted = sortMeetsFromLatest(userInitialMeets);
          setUserMeets(userMeetsSorted);

          ////console.log("User meets sorted: " + userMeetsSorted.length);

        } else {
          
          ////console.log("OwnMeets, User has no profile, user should not be able to even navigate to this view")


        }
      } catch (error) {
        console.error("Error fetching meets:", error);
      }
    };

    fetchMeets();
  }, [user]);








  const handleScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;
    if (scrollTop + clientHeight >= scrollHeight) {
      setMeetsPerPage(meetsPerPage + 10);
    }
  };



  return (
    <div className="page-container">
      <nav style={{ display: 'flex', paddingRight: '10px' }}>
        <ul>
          <li>
            <a href="#" onClick={goBack}>
              <IconChevronLeft size={35} />
            </a>
          </li>
          <li>
            <strong>Omat tapaamiset</strong>
          </li>
        </ul>
      </nav>

      <div>
  {userMeets.length > 0 ? (
    userMeets.map((meet) => (
      <div key={meet.id}>
        <MeetCard meet={meet} />
      </div>
    ))
  ) : (
    <div>
      Sinulla ei ole omia tapaamisia, voit luoda niitä{' '}
      <a href="/mymeeteditor">täällä</a> tai ilmoittautua kiinnostuneeksi muiden tapaamisiin.
    </div>
  )}
</div>

    </div>
  );

}

export default OwnMeets;