export default {
  apiKey: "AIzaSyA2RsiMnunv7vSGAs-pRHGqo1MxsJnBLB0",
  authDomain: "miitti-ad8c4.firebaseapp.com",
  projectId: "miitti-ad8c4",
  storageBucket: "miitti-ad8c4.appspot.com",
  messagingSenderId: "364886325157",
  appId: "1:364886325157:web:eabadfd9f6fa5af9519a4c",
  measurementId: "G-VDTRE99H5Z"
};

  