import React from 'react';
import { UserAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { IconChevronLeft } from '@tabler/icons-react';
import slipperLogo from '../assets/SlipperIcon.png';
import { db } from "../config/firebase";
import { collection, query, where, getDocs, getDoc, deleteDoc, doc,   writeBatch, arrayRemove } from 'firebase/firestore';
//import { getStorage, ref, listAll, deleteObject } from 'firebase/storage';
import { deleteFileFromUrl } from '../config/utils';
import { deleteUser } from "firebase/auth";

const RemoveAccount = () => {
  const navigate = useNavigate();
  const { user } = UserAuth();

  const goBack = () => {
    navigate('/account');
  };

  /* const handleRemoveAccount = async () => {
    try {
      const chatsQuery = query(collection(db, 'chats'), where('owner_id', '==', user.uid));
      const chatDocs = await getDocs(chatsQuery);

      chatDocs.forEach(async (chatDoc) => {
        await deleteDoc(doc(db, 'chats', chatDoc.id));
      });

      const meetsQuery = query(collection(db, 'meets'), where('owner_user', '==', user.uid));
      const meetDocs = await getDocs(meetsQuery);

      meetDocs.forEach(async (meetDoc) => {
        await deleteDoc(doc(db, 'meets', meetDoc.id));
      });

      // Finally, delete the user profile
      await deleteDoc(doc(db, 'users', user.uid));

      // After cleaning up, you can navigate to a different page, e.g., the home page
      navigate('/account');
    } catch (error) {
      console.error('Error removing account:', error);
    }
  };
 */



  const handleRemoveAccount = async () => {
    try {

      const userId = user.uid;
      ////console.log(userId)
      // Retrieve user document to get picUrl and thumbUrl
      const userDocRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userDocRef);
      const userDocData = userDoc.data();

      // Remove profile image and thumbnails
      // await deleteFileFromUrl(userDocData.picUrl);
      // await deleteFileFromUrl(userDocData.thumbUrl);


      try {
        await deleteFileFromUrl(userDocData.picUrl);
        await deleteFileFromUrl(userDocData.thumbUrl);
      } catch (deleteImageError) {
        console.error('Error deleting images:', deleteImageError);
        // Log or report the error, but continue with database deletions
      }

      // Remove chats where user is the owner
      const ownerChatsQuery = query(collection(db, 'chats'), where('owner_id', '==', userId));
      const ownerChatDocs = await getDocs(ownerChatsQuery);
      ownerChatDocs.forEach(async (chatDoc) => {
        await deleteDoc(doc(db, 'chats', chatDoc.id));
      });

      // Remove chats where user is the applicant
      const applicantChatsQuery = query(collection(db, 'chats'), where('applicant_id', '==', userId));
      const applicantChatDocs = await getDocs(applicantChatsQuery);
      applicantChatDocs.forEach(async (chatDoc) => {
        await deleteDoc(doc(db, 'chats', chatDoc.id));
      });

      // Remove meets where user is the owner
      const ownerMeetsQuery = query(collection(db, 'meets'), where('owner_user', '==', userId));
      const ownerMeetDocs = await getDocs(ownerMeetsQuery);
      ownerMeetDocs.forEach(async (meetDoc) => {
        const meet_pic_url = meetDoc.data().meet_pic_url;
        const meet_thumb_url = meetDoc.data().meet_thumb_url;
        try {
          await deleteFileFromUrl(meet_pic_url);
          await deleteFileFromUrl(meet_thumb_url);
        } catch (deleteMeetImageError) {
          console.error('Error deleting meet images:', deleteMeetImageError);
          // Log or report the error, but continue with database deletions
        }

        await deleteDoc(doc(db, 'meets', meetDoc.id));
      });

      // Remove meets where user is the applicant
      const applicantMeetsQuery = query(collection(db, 'meets'), where('applicant_user', '==', userId));
      const applicantMeetDocs = await getDocs(applicantMeetsQuery);
      applicantMeetDocs.forEach(async (meetDoc) => {
        const meet_pic_url = meetDoc.data().meet_pic_url;
        const meet_thumb_url = meetDoc.data().meet_thumb_url;
        await deleteFileFromUrl(meet_pic_url);
        await deleteFileFromUrl(meet_thumb_url);
        await deleteDoc(doc(db, 'meets', meetDoc.id));
      });


      const batch = writeBatch(db);
      const usersQuery = query(collection(db, "users"), where("uid", "!=", userId));
      const querySnapshot = await getDocs(usersQuery);

      // Update "likes_uids" in other users' documents (within the batch)
      querySnapshot.forEach((doc) => {
        const userDocData = doc.data();
        if (userDocData.likes_uids?.includes(userId)) {
          /* batch.update(doc.ref, { likes_uids: arrayRemove(userDocData.likes_uids, user.uid) }); */
          batch.update(doc.ref, { likes_uids: arrayRemove(userId) });
          //console.log(`User ${userDocData.uid} - Removed ${user.uid} from likes_uids`);
        } else {
          //console.log(`User ${userDocData.uid} - No update needed (not in likes_uids)`);
        }
      });

      // Update hideProfile for current user (within the batch)
      //batch.update(userRef, { hideProfile: newState !== undefined ? newState : hide });
      //console.log('Updated current user hideProfile');

      // Commit the batch write
      await batch.commit();


      // Remove user document
      await deleteDoc(userDocRef);
      await deleteUser(user); // Use the current user object

      // Navigate to a different page after cleanup
      navigate('/account');
    } catch (error) {
      console.error('Error removing account:', error);
    }
  };


  /* const handleRemoveAccount = async () => {
    try {
      const userId = user.uid;
  
      // Retrieve user document first for data
      const userDocRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userDocRef);
      const userDocData = userDoc.data();
  
      // Attempt to delete profile images, handling potential errors
      try {
        // Ensure non-root references before deletion
        const picRef = storage().refFromURL(userDocData.picUrl); // Create reference from URL
        const thumbRef = storage().refFromURL(userDocData.thumbUrl);
  
        await Promise.all([
          picRef?.delete(), // Delete only if picRef is not null
          thumbRef?.delete(), // Delete only if thumbRef is not null
        ]);
      } catch (deleteImageError) {
        console.error('Error deleting images:', deleteImageError);
        // Log or report the error, but continue with database deletions
      }
  
      // ... rest of the code for deleting chats and meets, unchanged ...
  
      // Remove user document regardless of image deletion outcomes
      await deleteDoc(userDocRef);
  
      // Navigate to a different page after cleanup
      navigate('/account');
    } catch (error) {
      console.error('Error removing account:', error);
      // Handle any other errors that might occur
    }
  }; */


  return (
    <div className="page-container">
      <nav style={{ display: 'flex', paddingRight: '10px' }}>

        <ul>
          <li>
            <a href="#" onClick={goBack}>
              <IconChevronLeft size={35} />
            </a>
          </li>
          <li>
            <strong>Poista tili</strong>
          </li>
        </ul>
      </nav>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img src={slipperLogo} alt="Slipper Logo" style={{ height: '100px', width: 'auto' }} />
        <br />
        <p>Ikävää, että harkitset tilisi poistamista. Muistathan kuitenkin, että halutessasi voit pelkästään piilottaa profiilisi. Tilisi pysyy kuitenkin tallessa.</p>
        <p>Mikäli kuitenkin haluat poistaa tilitietosi ja kaikki sovellukseen lataamasi kuvat, klikkaa allaolevaa nappia.</p>
        <button onClick={handleRemoveAccount}>Haluan poistaa tilini</button>
      </div>
    </div>
  );
};

export default RemoveAccount;


