import React from "react";
import { useEffect, useState } from "react";
import MeetAvatar from "./MeetAvatar";
import UserPreview from "./UserPreview";
import {
  IconSquareX,
  IconShield,
  IconDiamond,
  IconClockHour12,
  IconCalendarEvent,
} from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import {
  query,
  addDoc,
  setDoc,
  getDoc,
  collection,
  doc,
  arrayUnion,
  getDocs,
  updateDoc,
  where,
  QuerySnapshot,
  writeBatch,
} from "firebase/firestore";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  listAll,
  deleteObject,
} from "firebase/storage";
import { db, app, storage } from "../config/firebase";
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import {
  calculateDistanceTo,
  formatDate,
  formatTime,
  formatWeekday,
} from "../config/utils";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { saveAction } from "../context/ActionService";

const customStyles = {
  content: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    padding: 0, // Remove padding if any
    margin: 0, // Remove margin if any
    position: "fixed", // Use a fixed position to cover the full viewport
    background: "linear-gradient(to bottom, #333, #666)", // Gradient background
    display: "flex", // Make the content flex to occupy the full screen
    flexDirection: "column", // Set the flex direction to column
    //alignItems: 'center', // Center horizontally
    //justifyContent: 'center', // Center vertically
    zIndex: 10000,
  },
};

const MeetCard = ({ meet, crystals, setCrystals }) => {
  const navigate = useNavigate();
  const { user } = UserAuth();
  const [meetStateId, setMeetStateId] = useState(null);
  const [dataBubbles, setDataBubbles] = useState([]);
  const [distanceToDate, setDistanceToDate] = useState();
  const [currentView, setCurrentView] = useState("default"); // 'normal', 'interested', 'report', 'archive', 'profile', 'meetpic'
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isOwner, setIsOwner] = useState(false);
  const [hasChats, setHasChats] = useState(false);
  const [isClosed, setIsClosed] = useState(false);
  const [isApplicant, setIsApplicant] = useState(false);
  const [currentMessage, setCurrentMessage] = useState("");
  //const [profileExists, setProfileExists] = useState(false);
  const [isArchived, setIsArchived] = useState(false);
  const [alreadyReported, setAlreadyReported] = useState(false);
  const [currentCrystals, setCurrentCrystals] = useState(0);
  const [cardUpdated, setCardUpdated] = useState(0);
  const [sendErrorMessage, setSendErrorMessage] = useState(null);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [isSending, setIsSending] = useState(false); // Add this state

  /*   useEffect(() => {
  
      const distance = calculateDistanceTo(meet.geolocation, (distance) => {
        if (distance !== null) {
  
          setDistanceToDate(distance);
          // Handle the distance value here
        } else {
          ////console.log("Error calculating distance");
          setDistanceToDate("X");
          // Handle the case where geolocation is not available or an error occurred
        }
      });
      const thisTime = new Date();
      thisTime.setHours(thisTime.getHours());
      const isPast = new Date(meet.meet_date) < thisTime;
      setMeetStateId(meet.uid)
      setIsClosed(meet.closed);
      const isArchived = meet.archived;
      setIsOwner(meet.owner_user === user.uid)
      const isCurrentUserApplicant = meet.interested_users.includes(user.uid);
  
      setIsApplicant(isCurrentUserApplicant);
  
      ////console.log("Meetcard: isPast: " + isPast +
        ", isClosed: " + meet.closed +
        ", isOwner: " + meet.owner_user === user.uid +
        ", isArchived: " + meet.archived +
        ", isApplicant: " + isCurrentUserApplicant);
  
      if (isOwner) {
        if (isClosed) {
          setDataBubbles([meet.meet_gender, "Sinun tapaamisesi", "Tapaaminen Lukittu"])
        } else {
          setDataBubbles([meet.meet_gender, "Sinun tapaamisesi"]);
        }
  
      } else if (isCurrentUserApplicant) {
        setDataBubbles([meet.meet_gender, "Olet kiinnostunut"]);
      } else if (isArchived) {
        setDataBubbles([meet.meet_gender, "Poistettu tapaaminen"]);
      } else {
        setDataBubbles([meet.meet_gender]);
  
      }
  
  
    }, []); */

  /*  const copyLink = async (meetId) => {
     
      // Create a Firebase Dynamic Link
      const link = await getDynamicLink({
        link: `${baseUrl}/meets/${meetId}`, // Target URL for the deep link
        domain: 'your-domain.page.link', // Replace with your Firebase Dynamic Links domain
        iosParams: {
          bundleId: 'your.app.bundle.id', // Replace with your TWA bundle ID (iOS)
        },
        androidParams: {
          packageName: 'your.app.package.name', // Replace with your TWA package name (Android)
        },
      });
    
      // Set the copied link state (optional for displaying feedback)
      setCopiedLink(link);
    
      // After a short delay, clear the copiedLink state to remove the feedback
      setTimeout(() => setCopiedLink(''), 2000);
    
      // Copy the generated link to the clipboard
      navigator.clipboard.writeText(link);
    }; */

  const updateBubblesForClosed = (closedBoolean) => {
    let bubbles = []; // Create an empty array to store bubbles

    bubbles.push(meet.meet_gender);
    if (meet.owner_user === user.uid) {
      ////console.log("Updatebubbles. This is my meet, and isClosed is " + isClosed + " and meet is closed " + meet.closed + " and the boolean given as parameter: " + closedBoolean)

      //bubbles.push(meet.meet_gender); // Add the first bubble (gender)
      bubbles.push("Sinun ilmoituksesi"); // Add "Sinun tapaamisesi" if owner
      if (closedBoolean) {
        ////console.log("Ilmoitus lukittu on pushattu kupliin")
        bubbles.push("Ilmoitus lukittu"); // Add "Tapaaminen Lukittu" if closed
      }
    } else if (meet.interested_users.includes(user.uid)) {
      ////console.log("Olet kiinnostunut")

      bubbles.push("Olet kiinnostunut");
    }

    if (meet.archived) {
      bubbles.push("Poistettu ilmoitus");
    }
    if (meet.only_friends) {
      bubbles.push("Vain kavereita");
    }
    setDataBubbles(bubbles); // Pass the updated bubbles array
    ////console.log(bubbles)
  };

  useEffect(() => {
    ////console.log("Main useEffect")
    const {
      geolocation,
      meet_gender,
      uid,
      closed,
      archived,
      owner_user,
      interested_users,
      down_votes,
    } = meet; // Destructure meet object
    const fetchData = async () => {
      try {
        const distance = await calculateDistanceTo(geolocation, user.uid);
        setDistanceToDate(distance);
        // Handle the distance value here
      } catch (distanceError) {
        ////console.log("Error calculating distance:", distanceError);
        setDistanceToDate("0");
        // Handle the case where geolocation is not available or an error occurred
      }

      try {
        const thisTime = new Date();
        thisTime.setHours(thisTime.getHours());
        //const isPast = new Date(meet_date) < thisTime;
        setMeetStateId(uid);
        setIsClosed(closed);
        setIsArchived(archived);

        // Check if there are chats associated with the meet
        const chatsSnapshot = await getDocs(
          query(collection(db, "chats"), where("meet_id", "==", uid))
        );
        const hasChats = chatsSnapshot.size > 0;
        setHasChats(hasChats);

        const izOwner = owner_user === user.uid;
        ////console.log("izOwner:", izOwner);

        setIsOwner(izOwner);
        const isCurrentUserApplicant = interested_users.includes(user.uid);
        setIsApplicant(isCurrentUserApplicant);

        const downVotes = down_votes || [];
        ////console.log("downvotes array: " + downVotes.length);
        if (Array.isArray(downVotes) && downVotes.includes(user.uid)) {
          ////console.log("User already reported");
          setAlreadyReported(true);
        }

        if (downVotes.length >= 3) {
          ////console.log("Deleting the meet due to 3 or more down votes");

          // Fetch the meet document to get the meet image URLs
          const meetDocRef = doc(db, "meets", uid);
          const meetDocSnapshot = await getDoc(meetDocRef);

          if (meetDocSnapshot.exists()) {
            await updateDoc(meetDocRef, { archived: true });

            // Optionally, you can set additional state or perform other actions after deletion
          } else {
            // Handle the case where the Meet document does not exist
            console.error("Meet document not found.");
          }
        } else {
          updateBubblesForClosed(closed);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [meet]);

  /*   useEffect(() => {
      //console.log("Main useEffect")
      const fetchData = async () => {
        const { geolocation, meet_date, uid, closed, archived, owner_user, interested_users, down_votes } = meet; // Destructure meet object
        try {
          const distance = await calculateDistanceTo(meet.geolocation);
          setDistanceToDate(distance);
          // Handle the distance value here
        } catch (distanceError) {
          ////console.log("Error calculating distance:", distanceError);
          setDistanceToDate("0");
          // Handle the case where geolocation is not available or an error occurred
        }
  
        try {
          const thisTime = new Date();
          thisTime.setHours(thisTime.getHours());
          const isPast = new Date(meet.meet_date) < thisTime;
          setMeetStateId(meet.uid);
          setIsClosed(meet.closed);
          setIsArchived(meet.archived);
  
          // Check if there are chats associated with the meet
          const chatsSnapshot = await getDocs(query(collection(db, 'chats'), where('meet_id', '==', meet.uid)));
          const hasChats = chatsSnapshot.size > 0;
          setHasChats(hasChats);
  
          const izOwner = meet.owner_user === user.uid;
          ////console.log("izOwner:", izOwner);
  
          setIsOwner(izOwner);
          const isCurrentUserApplicant = meet.interested_users.includes(user.uid);
          setIsApplicant(isCurrentUserApplicant);
  
          const downVotes = meet.down_votes || [];
          //console.log("downvotes array: " + downVotes.length);
          if (downVotes.includes(user.uid)) {
            ////console.log("User already reported");
            setAlreadyReported(true);
  
          }
  
          if (downVotes.length >= 3) {
            ////console.log("Deleting the meet due to 3 or more down votes");
  
            // Fetch the meet document to get the meet image URLs
            const meetDocRef = doc(db, "meets", meet.uid);
            const meetDocSnapshot = await getDoc(meetDocRef);
  
            if (meetDocSnapshot.exists()) {
  
              await updateDoc(meetDocRef, { archived: true });
  
              // Optionally, you can set additional state or perform other actions after deletion
            } else {
              // Handle the case where the Meet document does not exist
              console.error("Meet document not found.");
            }
          } else {
  
            let bubbles = []; // Create an empty array to store bubbles
  
            if (meet.owner_user === user.uid) {
              //console.log("This is my meet, and isClosed is "+isClosed+" and meet is closed "+meet.closed)
              
              bubbles.push(meet.meet_gender); // Add the first bubble (gender)
              bubbles.push("Sinun ilmoituksesi"); // Add "Sinun tapaamisesi" if owner
              if (meet.closed) {
                
                bubbles.push("Ilmoitus lukittu"); // Add "Tapaaminen Lukittu" if closed
              }
              //console.log(bubbles)
              setDataBubbles(bubbles); // Pass the updated bubbles array
            } else if (meet.interested_users.includes(user.uid)) {
              ////console.log("Olet kiinnostunut")
  
              bubbles.push("Olet kiinnostunut");
            } else if (meet.archived) {
              bubbles.push("Poistettu ilmoitus");
            } else {
              bubbles.push(meet.meet_gender);
            }
            
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
  
      fetchData();
  
    }, [cardUpdated]); */

  /*  useEffect(() => {
     const doesUserDocumentExist = async () => {
       try {
         const userRef = doc(db, "users", user.uid);
         const userDoc = await getDoc(userRef);
         const exists = userDoc.exists()
         setProfileExists(exists)
          if (exists) {
           const userData = userDoc.data();
           const currentGems = userData.gems;
           setCurrentCrystals(currentGems);
           ////console.log("MeetCard, useEffect, current user crystals: " + currentGems)
         } 
 
       } catch (error) {
         console.error("Error checking user document:", error);
         setProfileExists(false) // Return false if there's an error
       }
     };
     doesUserDocumentExist();
   }, [user]); */

  const toggleView = (view) => {
    ////console.log("Setting view to " + view)
    setCurrentView(view);
  };

  const getBackgroundColor = (tag) => {
    // Define a mapping of tag values to background colors
    /* const tagColors = {
      'Miehille': '#5cbeff',
      'Ilmoitus lukittu': '#daa520',
      'Vain kavereita': '#dca590',
      'Poistettu ilmoitus': '#00bcd4',
      'Sinun ilmoituksesi': '#00bcd4',
      'Olet kiinnostunut': '#27ae60',
      'Naisille': '#ff4d6a',
      'Kaikille': '#47ff51', // You can change the color code here
      'Pareille': '#4CAF50'
      // Add more tag-color mappings as needed
    }; */
    const tagColors = {
      Miehille: "#ff4d6a", // Light Pink (previously used for Naisille)
      "Ilmoitus lukittu": "#daa520", // Golden Yellow (unchanged)
      "Vain kavereita": "#ffc107", // Orange
      "Poistettu ilmoitus": "#f44336", // Red (low priority, worst readability)
      "Sinun ilmoituksesi": "#4CAF50", // Teal (previously used for Pareille)
      "Olet kiinnostunut": "#27ae60", // Dark Sea Green (unchanged)
      Naisille: "#29b6f6", // Light Blue
      Kaikille: "#47ff51", // Light Green (unchanged)
      Pareille: "#9ccc65", // Light Greenish Teal
      // Add more tag-color mappings as needed
    };
    // Return the color associated with the tag; default to a standard color if not found
    return tagColors[tag] || "#000000"; // Use white as the default color
  };

  /*   const sendMessage = async () => {
    try {

      const userRef = doc(db, "users", user.uid);
    const userDoc = await getDoc(userRef);

    // Ensure the user's document exists and retrieve the name
    let userName = '';
    if (userDoc.exists()) {
      const userData = userDoc.data();
      userName = userData.name; // Assuming the 'name' field contains the user's name
    } else {
      console.error("User document not found");
      return; // Exit if user not found
    }

      // Update the 'interested_users' array in the 'meets' collection
      const meetDocRef = doc(db, "meets", meetStateId);
      await updateDoc(meetDocRef, {
        interested_users: arrayUnion(user.uid),
      });

      // Next, create the chat document with a randomly generated ID
      const chatCollectionRef = collection(db, "chats");

      const generatedChatUID = `${user.uid}_${meetStateId}`;
      const chatDocument = {
        lastCheckTimeOwner: new Date(), // Set to the current time
        lastCheckTimeApplicant: new Date(), // Set to the current time
        uid: generatedChatUID,
        meet_id: meetStateId,
        owner_id: meet.owner_user,
        meet_date: meet.meet_date,
        applicant_id: user.uid,
        locked: false,
        archived: false,
        messages: [
          {
            sender_id: user.uid,
            timestamp: new Date(),
            text: currentMessage,
          },
        ],
      };

      // Directly add the chat document to the collection
      await addDoc(chatCollectionRef, chatDocument);
      const notificationText = `${userName} ilmoittautui tapaamiseesi!`;


      await saveAction(user.uid, meet.owner_user, 'join_meet', notificationText); // Call with appropriate parameters



      setIsApplicant(true);
      toggleView("default");
    } catch (error) {
      console.error(
        "MeetCard: onSubmit: Error updating meet or creating chat:",
        error
      );

      setSendErrorMessage(
        <div>
          <p>
            Tapahtui virhe ilmoittautumisen yhteydessä. Yritä uudelleen
            myöhemmin.
          </p>
          <small style={{ color: "gray" }}>{error.message}</small>
        </div>
      );
    }
  }; */

  const sendMessage = async () => {
    if (isSending) return; // Prevent multiple clicks
    setIsSending(true); // Disable button once clicked

    try {
      const userRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userRef);

      // Ensure the user's document exists and retrieve the name
      let userName = "";
      if (userDoc.exists()) {
        const userData = userDoc.data();
        userName = userData.name; // Assuming the 'name' field contains the user's name
      } else {
        console.error("User document not found");
        setIsSending(false); // Re-enable the button if there's an error
        return; // Exit if user not found
      }

      // Update the 'interested_users' array in the 'meets' collection
      const meetDocRef = doc(db, "meets", meetStateId);
      await updateDoc(meetDocRef, {
        interested_users: arrayUnion(user.uid),
      });

      // Next, create the chat document with a randomly generated ID
      const chatCollectionRef = collection(db, "chats");
      const generatedChatUID = `${user.uid}_${meetStateId}`;

      const chatDocument = {
        lastCheckTimeOwner: new Date(),
        lastCheckTimeApplicant: new Date(),
        uid: generatedChatUID,
        meet_id: meetStateId,
        owner_id: meet.owner_user,
        meet_date: meet.meet_date,
        applicant_id: user.uid,
        locked: false,
        archived: false,
        messages: [
          {
            sender_id: user.uid,
            timestamp: new Date(),
            text: currentMessage,
          },
        ],
      };

      await addDoc(chatCollectionRef, chatDocument);

      const notificationText = `${userName} ilmoittautui tapaamiseesi!`;
      await saveAction(
        user.uid,
        meet.owner_user,
        "join_meet",
        notificationText
      );

      setIsApplicant(true);
      toggleView("default");
    } catch (error) {
      console.error(
        "MeetCard: onSubmit: Error updating meet or creating chat:",
        error
      );

      setSendErrorMessage(
        <div>
          <p>
            Tapahtui virhe ilmoittautumisen yhteydessä. Yritä uudelleen
            myöhemmin.
          </p>
          <small style={{ color: "gray" }}>{error.message}</small>
        </div>
      );
    } finally {
      setIsSending(false); // Re-enable the button once the process completes
    }
  };

  const handleReportMeet = async (data) => {
    try {
      const meetDocRef = doc(db, "meets", meet.uid);
      const meetDoc = await getDoc(meetDocRef);

      if (meetDoc.exists()) {
        const meetData = meetDoc.data();

        // Initialize the "down_votes" array if it doesn't exist
        meetData.down_votes = meetData.down_votes || [];

        // Check if the current user's UID is already in the "down_votes" array
        const reportingUser = user;
        const userAlreadyReported = meetData.down_votes.includes(
          reportingUser.uid
        );

        // If the user hasn't reported this meet yet, add their UID to the "down_votes" array
        if (!userAlreadyReported) {
          meetData.down_votes.push(reportingUser.uid);

          // Update the meet data in the Firestore database
          await updateDoc(meetDocRef, meetData);
          setAlreadyReported(true);

          // Log a message indicating a successful report
          ////console.log("Meet reported successfully");
        } else {
          // Log a message indicating that the user has already reported this meet
          ////console.log("User already reported this meet");
        }
      } else {
        // Handle the case where the Meet document does not exist
        console.error("Meet document not found.");
      }
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };

  const getTags = () => {
    return (
      <div className="tags">
        {dataBubbles.map((bubble, index) => (
          <div
            key={index}
            className="tag"
            style={{ backgroundColor: getBackgroundColor(bubble) }}
          >
            {bubble}
          </div>
        ))}
      </div>
    );
  };

  // Define handle functions to set the view

  const handleArchiveModalConfirm = async (meet) => {
    try {
      ////console.log("Yritetään arkistoida miitti: " + meet);

      // Fetch the user document to get the current gems count
      /* const userDocRef = doc(db, 'users', user.uid);
      const userDocSnapshot = await getDoc(userDocRef);

   if (userDocSnapshot.exists()) {
        const userData = userDocSnapshot.data();
        const currentHearts = userData.heartPoints;

        // Reduce one gem from the user's count
        await updateDoc(userDocRef, {
          heartPoints: currentHearts - 10,
        });
      }
 */
      const chatsCollectionRef = collection(db, "chats");
      // Retrieve all chats with the same meet_id
      const querySnapshot = await getDocs(
        query(chatsCollectionRef, where("meet_id", "==", meet.uid))
      );
      ////console.log("QuerySnapshot:")
      ////console.log(QuerySnapshot)
      // Archive all found chats
      const batch = writeBatch(db);
      querySnapshot.docs.forEach((document) => {
        const chatRef = doc(db, "chats", document.id);
        batch.update(chatRef, { archived: true });
      });

      if (meet.meet_thumb_url && meet.meet_pic_url) {
        /* const meetImageRef = ref(storage, meet.meet_pic_url);
        const meetThumbImageRef = ref(storage, meet.meet_thumb_url);
        await deleteObject(meetImageRef);
        await deleteObject(meetThumbImageRef); */
        try {
          const meetImageRef = ref(storage, meet.meet_pic_url);
          const meetThumbImageRef = ref(storage, meet.meet_thumb_url);
          await deleteObject(meetImageRef);
          await deleteObject(meetThumbImageRef);
        } catch (error) {
          console.error("Error deleting images:", error);
          // Handle image deletion error gracefully (explained below)
        }
      }

      try {
        await batch.commit(); // Attempt to archive chats

        // Update meet document only if chat archiving succeeds
        const meetRef = doc(db, "meets", meet.uid);
        await updateDoc(meetRef, {
          archived: true,
          meet_thumb_url: null,
          meet_pic_url: null,
        });

        setCardUpdated(Date.now());
        toggleView("disabled");
      } catch (error) {
        console.error("Error deleting chats or meet:", error);
        // Handle the chat deletion error here (e.g., show a message to the user)
      }
      setCardUpdated(Date.now());
      toggleView("disabled");
    } catch (error) {
      console.error("Error archiving meet:", error);
      // Handle the error as needed
    }
  };

  const handleLockModalConfirm = async (meet) => {
    try {
      // Update meet document to mark it as archived and delete image URLs
      const meetRef = doc(db, "meets", meet.uid);
      await updateDoc(meetRef, {
        closed: true,
      });

      //setCardUpdated(Date.now())
      ////console.log("Updating meet for closed true")
      setIsClosed(true);
      updateBubblesForClosed(true);
      toggleView("default");
    } catch (error) {
      console.error("Error archiving meet:", error);
      // Handle the error as needed
    }
  };

  const handleUnlockModalConfirm = async (meet) => {
    try {
      // Update meet document to mark it as archived and delete image URLs
      const meetRef = doc(db, "meets", meet.uid);
      await updateDoc(meetRef, {
        closed: false,
      });
      ////console.log("Updated meet for closed false")
      //setCardUpdated(Date.now())
      setIsClosed(false);
      updateBubblesForClosed(false);
      ////console.log("CardUpdated")
      toggleView("default");
    } catch (error) {
      console.error("Error archiving meet:", error);
      // Handle the error as needed
    }
  };

  /* const handleArchiveModalConfirm = async (meet) => {
    try {
      ////console.log("Yritetään arkistoida miitti: " + meet);
      const meetRef = doc(db, "meets", meet.uid);
      ////console.log("Meetref: " + meetRef);
      await updateDoc(meetRef, {
        archived: true,
      });

      toggleView('disabled');

      // Fetch the user document to get the current gems count
      const userDocRef = doc(db, 'users', user.uid);
      const userDocSnapshot = await getDoc(userDocRef);

      if (userDocSnapshot.exists()) {
        const userData = userDocSnapshot.data();
        const currentHearts = userData.heartPoints;

        // Reduce one gem from the user's count
        await updateDoc(userDocRef, {
          heartPoints: currentHearts - 10
        });
      }

    } catch (error) {
      console.error("Error archiving meet:", error);
      // Handle the error as needed
    }
  }; */

  return (
    <div key={meet.uid}>
      {/* <ToastContainer /> */}

      {/* Render the toggle view based on the currentView */}
      {currentView === "interested" && (
        <div className="fullscreen-container">
          <div className="fullscreen-content">
            <h6>Ilmoittaudu tapaamiseen</h6>
            {/*  <input
              type="text"
              style={{
                flex: 1,
                padding: '10px',
                width: '100%',
                resize: 'vertical',
                wordWrap: 'break-word',
              }}
              placeholder="Kirjoita viesti..."
              value={currentMessage}
              onChange={(e) => setCurrentMessage(e.target.value)}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  // Call the function to handle sending the message (e.g., sendMessage) here
                  // Ensure that you trigger the same functionality that was in the form approach.
                }
              }}
            /> */}
            <textarea
              style={{
                flex: 1,
                padding: "10px",
                width: "100%",
                height: "150px", // Set the height to make it larger
                resize: "vertical",
                wordWrap: "break-word",
              }}
              placeholder="Kirjoita lyhyt viesti voidaksesi lähettää ilmoittautumisen.."
              value={currentMessage}
              onChange={(e) => setCurrentMessage(e.target.value)}
              onInput={(e) => {
                const maxLength = 1000; // Maximum character count
                if (e.target.value.length > maxLength) {
                  e.target.value = e.target.value.slice(0, maxLength); // Truncate text
                }
              }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  // Call the function to handle sending the message (e.g., sendMessage) here
                  // Ensure that you trigger the same functionality that was in the form approach.
                }
              }}
            />
            <small>
              Kukaan muu kuin tapaamisen perustaja, ei näe ilmoittautumistasi.
              Kiinnostuneiden määrä ei myöskään ole kuin ilmoittajan tiedossa.
            </small>

            <div className="button-container">
              <button type="button" onClick={() => toggleView("default")}>
                Peruuta
              </button>

              <button
                disabled={!currentMessage.trim() || isSending}
                onClick={sendMessage}
              >
                Lähetä
              </button>
            </div>
            {sendErrorMessage && (
              <div className="send-error-message">{sendErrorMessage}</div>
            )}

            {/* <small>
              {profileExists
                ? "Voit milloin tahansa poistaa tästä ilmoittautumisesta syntyvän keskustelun, jolloin sinua ei enää voida valita tapaamiseen."
                : "Tarvitset profiilin voidaksesi ilmoittautua tapaamisiin"
              }
            </small>
            <br />
            <br />
            <small style={{ color: '#d48dff' }}>
              {currentCrystals < 1
                ? "Sinulla ei ole tarpeeksi kristalleja ilmoittautumiseen. Käy etsimässä lisää kumppanuusedut-näkymästä."
                : ""}
            </small> */}

            {/*  <form onSubmit={handleSubmit((data) => onSubmit(data, meetStateId))}>
              <label htmlFor="openingMessage">Avausviesti</label>
              <textarea
                id="openingMessage"
                autofocus
                {...register('openingMessage', {
                  required: 'Avausviesti on pakollinen',
                  maxLength: 1000, // Enforce character limit
                })}
                rows="4" // Set the number of visible rows
                cols="40" // Set the number of visible columns
                placeholder="Kerro miksi sinut pitäisi valita seuraksi tapaamiseen tai mikä ilmoituksessa herätti kiinnostuksesi."
                onInput={(e) => {
                  const maxLength = 1000;
                  if (e.target.value.length > maxLength) {
                    e.target.value = e.target.value.slice(0, maxLength);
                  }
                }}
              />
              {errors.openingMessage && (
                <small className="error">
                  {errors.openingMessage.message}
                </small>
              )} 
              <small>
                Avausviesti on pakollinen, tapaamisen chat aukeaa vasta kun ilmoituksen jättäjä on vastannut siihen,
                joten tämä voi olla ainoa mahdollisuutesi kirjoittaa hänelle. Käytä se siis viisaasti. Kukaan muu kuin sinä ja tapaamisen ilmoittaja ei näe että olet kiinnostunut.
              </small>
              <div className="button-container">

                <button type="button" onClick={() => toggleView('default')}>
                  Peruuta
                </button>
                <button onClick={handleSubmit(onSubmit)}>Lähetä</button>
              </div>
            </form>*/}
          </div>
        </div>
      )}
      {currentView === "report" && (
        <div className="fullscreen-container">
          <div className="fullscreen-content">
            {alreadyReported ? (
              <div>
                Kiitos! Olet jättänyt ilmoituksen tästä tapaamisesta. Kun tietty
                määrä muitakin käyttäjiä on tehnyt ilmoituksen epäasiallisesta
                sisällöstä tai käytöksestä, tämä tapaaminen poistetaan
                automaattisesti.
                {/* <div className="button-container"> */}
                <br /> <br />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <button
                    style={{ width: "48%" }}
                    type="button"
                    onClick={() => toggleView("default")}
                  >
                    Poistu
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <h6>Raportoi tämän tapaamisen sisältö epäasiallisena</h6>
                <div>
                  <p>
                    Slipperissä noudatamme sisältösääntöjä, joiden mukaisesti
                    alla on esimerkkejä asioista, joita yleisesti katsotaan
                    sopimattomiksi tai kielletyiksi. Alastomuus ja
                    seksuaalisesti vihjaileva sisältö. Henkilöön tai
                    ihmisryhmään kohdistuva vihainen tai uhkaava kielenkäyttö.
                    Sisältö, joka viittaa itsevahingoittamiseen tai sisältää
                    vihjauksia väkivallasta. Keksityn tai toisen henkilön
                    esittäminen. Mainosten, roskapostin tai tapaamisiin
                    epäolennaisesti liittyvän tekstin jakaminen.
                  </p>

                  <p>
                    Voit myös muista riittäväksi näkemistäsi syistä ilmiantaa
                    ilmoituksen. Kun ilmoitus on kerännyt riittävän määrän
                    ilmiantoja, se poistuu järjestelmästä automaattisesti, joten
                    pelkästään sinun äänesi ei välttämättä riitä.
                  </p>
                </div>

                <br />

                <div className="button-container">
                  <button type="button" onClick={() => toggleView("default")}>
                    Peruuta
                  </button>
                  <button onClick={handleReportMeet}>Raportoi</button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {currentView === "unlock" && (
        <div className="fullscreen-container">
          <div className="fullscreen-content">
            <h6>Avaa tapaaminen</h6>
            <p>
              Tapaamisesi on tällä hetkellä lukittuna. Haluatko avata sen
              jälleen uusille ilmoittautumisille?
            </p>
            <br />
            <br />
            <div className="button-container">
              <button type="button" onClick={() => toggleView("default")}>
                Peruuta
              </button>
              <button
                type="button"
                onClick={() => handleUnlockModalConfirm(meet)}
              >
                Kyllä
              </button>
            </div>
          </div>
        </div>
      )}
      {currentView === "lock" && (
        <div className="fullscreen-container">
          <div className="fullscreen-content">
            <h6>Lukitse ilmoitus</h6>
            <p>
              Oletko jo tyytyväinen saamiisi ehdokkaisiin? Lukitsemalla
              tapaamisen, eivät muut käyttäjät enää näe sitä, eivätkä voi enää
              ilmoittautua. Olemassaolevat keskustelut pysyvät ennallaan.
            </p>
            <p>
              Oletko varma, että haluat lukita tapaamisen? Voit milloin tahansa
              perua lukituksen.
            </p>

            <div className="button-container">
              <button type="button" onClick={() => toggleView("default")}>
                Peruuta
              </button>
              <button
                type="button"
                onClick={() => handleLockModalConfirm(meet)}
              >
                Kyllä
              </button>
            </div>
          </div>
        </div>
      )}
      {currentView === "archive" && (
        <div className="fullscreen-container">
          <div className="fullscreen-content">
            <h6>Poista tapaaminen</h6>
            <p>
              Poistamalla tapaamisen, se ei näy enää edes sinulle. Kaikki
              tapaamiseen liittyvät keskustelut katoavat näkyvistä. Myöskään
              tapaamiseen liitetty kuva ei näy enää profiilisivullasi muille
              käyttäjille. Oletko varma, että haluat poistaa tapaamisen?
            </p>

            <div className="button-container">
              <button type="button" onClick={() => toggleView("default")}>
                Peruuta
              </button>
              <button
                type="button"
                onClick={() => handleArchiveModalConfirm(meet)}
              >
                Kyllä
              </button>
            </div>
          </div>
        </div>
      )}
      {currentView === "profile" && (
        <div className="userprofile-modal">
          <div className="userprofile-content">
            <UserPreview uid={meet.owner_user} />

            {/*     <button type="button" onClick={() => toggleView('default')}>
                Peruuta
              </button> */}

            <button
              className="close-button"
              onClick={() => toggleView("default")}
            >
              <IconSquareX /> Sulje
            </button>
          </div>
        </div>
      )}

      {currentView === "disabled" && (
        <div class="meet-card">
          <p>Tapaamisen poistaminen onnistui</p>
        </div>
      )}
      {currentView === "meetpic" && (
        /*  <div className="meet-pic-container"> */
        <div className="meetpic-fullscreen-container">
          <div className="meet-pic-fullscreen-content">
            <img
              src={meet.meet_pic_url}
              alt="Meet Picture"
              className="meet-pic"
            />
            <button
              className="close-button"
              onClick={() => toggleView("default")}
            >
              <IconSquareX /> Sulje
            </button>
          </div>
        </div>
      )}
      {currentView === "default" && (
        <div class="meet-card">
          <div class="thumbnail">
            <img
              src={meet.meet_thumb_url}
              alt="Meet Thumbnail"
              onClick={() => toggleView("meetpic")}
            />
            <div className="meet-date-display-square">
              <strong
                style={{
                  textTransform: "uppercase",
                  margin: "0",
                  padding: "0",
                }}
              >
                {formatWeekday(meet.meet_date)} {formatDate(meet.meet_date)}
              </strong>
              <br />
              <strong
                style={{
                  textTransform: "uppercase",
                  margin: "0",
                  padding: "0",
                }}
              >
                {formatTime(meet.meet_date)}
              </strong>
            </div>
            <div className="security-icon" onClick={() => toggleView("report")}>
              <IconShield
                size={40}
                style={{
                  color: "#0096FF",
                  backgroundColor: "#000000",
                  padding: "7px",
                  borderRadius: "50%",
                }}
              />
            </div>
            {getTags()}
          </div>
          <div class="meetcard-info">
            <hgroup>
              <h6>
                {meet.location.toUpperCase()} | {distanceToDate} KM
              </h6>
              <span> {meet.meet_type}</span>
            </hgroup>
            {meet.description && (
              <div>
                {" "}
                {/* class="description" */}
                {meet.description.length <= 90 ? (
                  <div class="description">
                    <small style={{ whiteSpace: "pre-wrap" }}>
                      "{meet.description}"
                    </small>
                  </div>
                ) : (
                  <details>
                    <summary style={{ color: "#ffd700" }}>
                      <small>Lue lisää</small>
                    </summary>
                    <small style={{ whiteSpace: "pre-wrap" }}>
                      {meet.description}
                    </small>
                  </details>
                )}
              </div>
            )}
            <MeetAvatar
              imageUrl={meet.owner_pic_url}
              ownerUid={meet.owner_user}
              birthday={meet.owner_user_birthday}
              name={meet.owner_user_name}
              onClick={() => toggleView("profile")}
            />
            <br />
            <div className="meetcard-buttonsection">
              {/*      {isOwner && isClosed && (

                  <button type="button" onClick={() => toggleView('unlock')}>
                    Poista lukitus
                  </button>
                )}
                {isOwner && !isClosed && (

                  <button type="button" onClick={() => toggleView('lock')}>
                    Lukitse ilmoitus
                  </button>
                )}
                {isOwner && !isArchived && (
                  <>
                    <button type="button" onClick={() => toggleView('archive')}>
                      Poista ilmoitus
                    </button>
                    {!hasChats && (
                      <button type="button" onClick={() => navigate(`/mymeeteditor/${meet.uid}`)}>
                        Muokkaa ilmoitusta
                      </button>
                    )}
                  </>
                )} */}

              {isOwner && (
                <>
                  {/* "Poista lukitus" button */}
                  {isClosed && (
                    <button type="button" onClick={() => toggleView("unlock")}>
                      Poista lukitus
                    </button>
                  )}

                  {/* "Lukitse ilmoitus" button */}
                  {!isClosed && (
                    <button type="button" onClick={() => toggleView("lock")}>
                      Lukitse ilmoitus
                    </button>
                  )}

                  {/* "Poista ilmoitus" and "Muokkaa ilmoitusta" buttons */}
                  {!isArchived && (
                    <>
                      <button
                        type="button"
                        onClick={() => toggleView("archive")}
                      >
                        Poista ilmoitus
                      </button>
                      <button
                        type="button"
                        onClick={() => navigate(`/mymeeteditor/${meet.uid}`)}
                      >
                        Muokkaa ilmoitusta
                      </button>
                      {/*  <button type="button" onClick={() => copyLink(meet.uid)}>
                        Kopioi linkki
                      </button> */}
                      {/* {!hasChats && (
                          
                        )} */}
                    </>
                  )}
                </>
              )}

              {!isOwner && !isClosed && !isApplicant && (
                <button type="button" onClick={() => toggleView("interested")}>
                  {/*  {`Olen kiinnostunut. isOwner: ${isOwner}, isClosed: ${isClosed}, isApplicant: ${isApplicant}`} */}
                  Olen kiinnostunut
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MeetCard;
